import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import "../../styles/components/BillingQueries.scss";
import "../../styles/components/invoice.scss";
import Sidebar from "../Sidebar/Sidebar";
import { getInitials } from "../../utils/getInitials";
import { UserContext } from "../../services/authentication/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../services/authentication/LoginService";
import SubTaskQueriesCommentService from "../../services/Queries/SubTaskQueriesCommentService";
import InvoiceHistoryDetailsService from "../../services/Invoice/invoiceHistoryDetailsService";
import InvoiceAdjustmentService from "../../services/Adjustment/InvoiceAdjustmentService";
import NavigateBack from "../NavigateBack";
import SideNavbar from "../common/SideNavbar";
import CustomScroll from "../common/CustomScroll";
import PatientAccordian from "./PatientAccordian";
import InvoiceDetailsAccordian from "./InvoiceDetailsAccordian";
import SubTask from "./SubTask";
import SubTaskComment from "./SubTaskComment";

const BillingQueriesOpen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { username } = useContext(UserContext);
  const initials = getInitials(username);
  const InvoiceType = location?.state?.invoiceType;
  const InvoiceStatus = location?.state?.item?.InvoiceStatus;
  const [patientName, setPatientName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [isSidebarqd, setisSidebarqd] = useState(false);
  const debtorID = location?.state?.item?.DebtorPatientId || "";
  const subTaskId = location?.state?.item?.SubTaskID;
  const [text, setText] = useState("");
  const handleRespondToQuery = async (text) => {
    setLoading(true);
    const caseID = location?.state?.item?.CaseID;
    const subTaskID = location?.state?.item?.SubTaskID;
    try {
      // Log the text being sent
      const response = await InvoiceAdjustmentService(
        token
      ).handleAdjustmentInvoice({
        SubTaskComment: text,
        DocumentID: caseID,
        SubTaskID: subTaskID,
      });
      const parmExtra = response?.parm_extra || "";

      if (response.status === 0) {
        navigate("/billingQueriesConfirmation", {
          state: { parmExtra: parmExtra },
        });
      } else {
        // Handle error response
        console.error("Error adding note:", response.message);
      }
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setLoading(false);
    }
  };
  const [subTasks, setSubTasks] = useState({ subtask: null, comments: [] });
  const toggleQueryDetailSidebar = () => {
    setisSidebarqd(!isSidebarqd);
  };

  const closeSidebar = () => {
    setisSidebarqd(false);
  };

  useEffect(() => {
    const apiCall = async (get_reference_number, debtorID, isGoodx) => {
      //call api
      try {
        let isGoodx = false;
        if (InvoiceType === 2 && InvoiceStatus === 1) {
          isGoodx = true;
        }

        const invoiceDetailsResponse = await InvoiceHistoryDetailsService(
          token
        ).fetchInvoiceHistoryDetails(get_reference_number);

        // Check if status is equal to 0
        if (invoiceDetailsResponse.status === 0) {
          const firstInvoice = invoiceDetailsResponse?.xpedientInvoiceDbDtl;

          if (firstInvoice) {
            const patientName = firstInvoice?.dbPatient?.PatientName || "";
            const treatingDocs = firstInvoice?.dbDoctors?.[0]?.DoctorName || "";
            const servCenter = firstInvoice?.dbCenter?.ServiceCenterName || "";
            const serviceDate = firstInvoice?.invoiceHeader?.InvoiceDate || "";
            const submissionDate =
              firstInvoice?.invoiceHeader?.CreateDate || "";
            setPatientName(patientName);

            setInvoiceDetails(firstInvoice?.invoiceLines || []);
            // setModalVisible(true);
          } else {
            console.error(
              "Invalid format for invoice details:",
              invoiceDetailsResponse
            );
          }
        } else {
          console.error("Invalid status:", invoiceDetailsResponse.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    const get_reference_number = location?.state?.invoiceReference || "";
    const goodx = location?.state?.item?.Goodx || "";

    if (!get_reference_number) {
    } else {
      apiCall(get_reference_number, debtorID, goodx);
    }
  }, []);

  useEffect(() => {
    const searchBillingQuery = async (taskID) => {
      setLoading(true);
      try {
        const result = await SubTaskQueriesCommentService(
          token
        ).fetchSubTaskQueriesComment(taskID);
        if (result && result.subtask) {
          setSubTasks({
            subtask: result.subtask,
            comments: result.comments || [],
          });
        } else {
          console.warn("No subtask found or result is not in expected format");
          setSubTasks({ subtask: null, comments: [] }); // Clear previous data if needed
        }
      } catch (error) {
        console.error("Error searching query history:", error);
      } finally {
        setLoading(false);
      }
    };

    if (subTaskId) {
      searchBillingQuery(subTaskId); // Call with SubTaskID
    }
  }, [subTaskId, token]);

  return (
    <div>
      <Sidebar heading={<h1 className="heading">BILLING QUERIES</h1>}>
        <div className="container mt-4">
          <NavigateBack text={"Billing Queries"} />
          <Formik
            enableReinitialize={true}
            initialValues={{
              billingTicketRefNo: location?.state?.item?.CaseID
                ? location?.state?.item?.CaseID
                : "",
              queryNo: subTasks?.subtask?.SubTaskID,
              queryStatus: subTasks?.subtask?.SubtaskStatusDesc || "",
              patientName: patientName ? patientName : "",
              accountNumber: "",
              serviceDate: "",
              submissionDate: "",
              serviceCentre: "",
              billingQueryReceived: "",
              respondToQuery: "",
            }}
          >
            {(formikProps) => (
              <div className="row search-padding">
                <div className="d-flex row">
                  <div className="mt-2 me-2 col-12 col-lg">
                    <small className="d-flex mb-2 text-black normal-txt">
                      Billing Ticket Ref No
                    </small>
                    <input
                      disabled
                      className="form-control bg-white  normal-txt"
                      value={formikProps.values.billingTicketRefNo}
                    />
                  </div>
                  <div className="mt-2 me-2 col-12 col-lg normal-txt">
                    <small className="d-flex mb-2 text-black">Query No</small>
                    <input
                      disabled
                      className="form-control bg-white normal-txt"
                      value={formikProps.values.queryNo}
                    />
                  </div>
                  <div className="mt-2 me-2 col-12 col-lg">
                    <small className="d-flex mb-2 text-black normal-txt">
                      Query Status
                    </small>
                    <input
                      disabled
                      className="form-control bg-white normal-txt"
                      value={formikProps.values.queryStatus}
                    />
                  </div>
                </div>
                {/* table of patient details */}
                <PatientAccordian
                  debtorID={debtorID}
                  patientName={patientName}
                />
                {/* table of codes */}
                <InvoiceDetailsAccordian invoiceDetails={invoiceDetails} />

                {/* query details sidebar */}
                <div
                  className="code-table-bq mt-4 pointer"
                  onClick={toggleQueryDetailSidebar}
                >
                  <div id="accordiontable" className="scrollcontainerparent">
                    <div
                      class="code-header-qd d-flex justify-content-between align-items-center mb-3"
                      id="headingOne"
                    >
                      <span className="generalmarg text-white bold-txt">
                        Query Details
                      </span>
                      <div className="icon-buttons">
                        <FontAwesomeIcon
                          color="white"
                          icon={faChevronRight}
                          className="chevron-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sidebar Query details*/}
                <SideNavbar
                  state={isSidebarqd}
                  proceed={() => handleRespondToQuery(text)}
                  close={closeSidebar}
                  heading={"Query Details"}
                >
                  <div>
                    <div>
                      {formikProps.touched.billingQueryReceived &&
                        formikProps.errors.billingQueryReceived && (
                          <p className="attention">
                            {formikProps.errors.billingQueryReceived}
                          </p>
                        )}
                      {loading ? (
                        <div className="spinner">
                          <span>Loading...</span>
                        </div>
                      ) : (
                        <CustomScroll maxHeight="40vh" smallerScroll={true}>
                          <SubTask subTasks={subTasks} />
                          <SubTaskComment
                            subTasks={subTasks}
                            initials={initials}
                          />
                        </CustomScroll>
                      )}
                    </div>
                    <div>
                      <label className="red-bold-txt text-left">
                        Respond to Query
                      </label>
                      <textarea
                        className="col-12"
                        placeholder=""
                        style={{ maxHeight: "130px" }}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </div>
                  </div>
                </SideNavbar>
              </div>
            )}
          </Formik>
        </div>
      </Sidebar>
    </div>
  );
};
export default BillingQueriesOpen;
