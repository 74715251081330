import React, { useState, useEffect , useCallback } from 'react';
import debounce from 'lodash/debounce';
import TreatingDocService from '../../services/Billing/TreatingDocService';

import { useAuth } from '../../services/authentication/LoginService';
import { useNavigate } from 'react-router-dom';
import useTreatingDoctorStore from '../state/TreatingDoctorStore';
import { useFormikContext } from 'formik';
const TreatingDoctor = () => {
  const {token} = useAuth()
  const {values,setFieldValue} = useFormikContext()
  const navigate = useNavigate()
  const treatingDoctor = TreatingDocService(token);
  const [searchText, setSearchText] = useState('');
  const [isTreatDocFlatListVisible, setIsTreatDocFlatListVisible] = useState(false);
  const [filteredTDoc, setFilteredTDoc] = useState([]);
  const { selectedTreatingDoc, setSelectedTreatingDoc,defaultTreatingDoc,setDefaultTreatingDoc,setDefaultTreatingDocID} = useTreatingDoctorStore()
  const [treatingDoctorSelected, setTreatingDoctorSelected] = useState(false);
  const [isLoading,setIsLoading] = useState(false)
  const [error,setError] = useState("")
  const [tDocData, settDocData] = useState([]);
  
  
  
   const handleSelectTreatingDoc = (item,setFieldValue) => {
    if (selectedTreatingDoc && selectedTreatingDoc.id === item.id) {

      setSelectedTreatingDoc(null);
      setSearchText("");
      setTreatingDoctorSelected(false);
    } else {
      setSelectedTreatingDoc(item);
      setFieldValue("treatingDoctor",item.name)
      setSearchText(item.name);
      navigate({ treatingDoctor: item.name });
      setIsTreatDocFlatListVisible(false);
      setTreatingDoctorSelected(true);
    }
  };

   //---------------TREATING DOCTOR ----------------------------------------------------//
   const debouncedTreatingDocSearch = useCallback(
    debounce(async (searchText) => {
      try {
        setIsLoading(true);
        const treatingDocData = await treatingDoctor.TreatingDoc();

        if (Array.isArray(treatingDocData)) {
          settDocData(treatingDocData);
          // Update default doctor only if exactly one match is found
          if (treatingDocData.length === 1) {
            setDefaultTreatingDoc(treatingDocData[0].name);
            setDefaultTreatingDocID(treatingDocData[0].id);
            setSearchText(treatingDocData[0].name);
          } else {
            setDefaultTreatingDoc('');
            setDefaultTreatingDocID('');
          }

          // Check for exact match
          const checkDirectlyEqual = treatingDocData.some(value => value.name.toUpperCase() === searchText.toUpperCase());
          if (checkDirectlyEqual) {
            setIsTreatDocFlatListVisible(false);
            return;
          }

          // Filter data based on search text
          const filteredData = treatingDocData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );

          setFilteredTDoc(filteredData);
          setIsTreatDocFlatListVisible(filteredData.length > 0);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );
  useEffect(() => {
    debouncedTreatingDocSearch(searchText);
  }, [searchText, debouncedTreatingDocSearch]);

  useEffect(() => {
    if (defaultTreatingDoc) {
      setSearchText(defaultTreatingDoc);
    }
  }, [defaultTreatingDoc]);

  

  return (
    <div className="col-12 col-lg text-font">
      <label htmlFor="treatingDoctor">Treating Doctor<span className="error-text">*</span></label>
      <input
        type="text"
        readOnly={tDocData.length === 1}
        value={values.treatingDoctor}
        onChange={(e) => {
          // setFieldValue("treatingDoctor",e.target.value)
          setSearchText(e.target.value)}}
        className="form-control"
        id="treatingDoctor"
      />
      {isTreatDocFlatListVisible && (
        <ul className="list-group">
          {filteredTDoc.map(doc => (
            <li
              key={doc.id}
              className="list-group-item"
              onClick={() => handleSelectTreatingDoc(doc, setFieldValue)}
            >
              {doc.name}
            </li>
          ))}
        </ul>
      )}
      {error && <div className="error-text">{error}</div>}
    </div>
  );
};

export default TreatingDoctor;
