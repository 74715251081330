import React from "react";
import "../../styles/components/login.scss";
import "../../styles/variables/_mixins.scss";
import LoginHeader from "../common/LoginHeader";
import MainFooter from "../MainFooter";
import { useLocation } from "react-router-dom";
import vectorImage from "../../assets/Vector.png";
import vectorFullBGImage from "../../assets/Vectorfull.png";

function LoginConfirmation() {
  const location = useLocation();
  const confirmationMessage = location?.state?.confirmationMessage;
  return (
    <div className="login-page-wrapper page-bg-img">
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorBig"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImage"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImageLeft"
      />
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorImageRight"
      />
      <div className="container shadow bg-white rounded px-4 authContainer">
        <LoginHeader />
        <div className="loginConfirmMargin">
          <div className="mt-4">
            <img
              className="mb-2"
              height={80}
              style={{ objectFit: "contain" }}
              src={require("../../assets/success-green-check-mark-icon.png")}
              alt="Success check mark"
            />
          </div>

          <span>{confirmationMessage}</span>
        </div>
        <div className="d-flex justify-content-center align-center loginConfirmMargin">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default LoginConfirmation;
