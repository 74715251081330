// ExampleCarouselImage.js
import React from 'react';

const ExampleCarouselImage = () => {
  return (
   <div className='p-1'>
      <div className='d-flex justify-content-start'>    
      <span className='text-white text-start'>Latest News</span>
      </div>
      <div className='newsFlexBox'>
      <img
        width={200}
        height={200}
        style={{objectFit:"contain"}}
          src={require("../../assets/ClientPortal/clientPortalNews.png")}
          alt="First slide"
        />
        <div >
          <div className='d-flex flex-column justify-content-start'>
          <span className='text-white text-header text-start ml-2'>Why Every Medical Practice Needs a Billing Audit</span>
          <small className='text-white text-start ml-2'>2024-08-22</small>

            <small className='text-white text-start ml-2'>Why Every Medical Practice Needs a Billing Audit In today’s complex healthcare environment, maintaining accurate and efficient billing...</small>

           
          </div>
           
        </div>
      </div>
   </div>
  );
};

export default ExampleCarouselImage;
