import React from 'react'

const BillingQueriesToggler = ({activeFilter,toggleFilter}) => {
  return (
    <div className="d-flex align-items-center justify-content-center pointer light-gray-bg mt-4 ">
                    <div 
                    onClick={() => toggleFilter("All")} 
                    className={`${activeFilter === "All" ? "bg-queryFilter text-white all-radius" : "text-white"} me-2 px-2 cursor-pointer d-flex justify-content-center align-items-center`}
                    >
                    <small className='text-center'>All</small>
                    </div>
                    <div 
                      onClick={() => toggleFilter("New Query")} 
                      className={`${activeFilter === "New Query" ? "bg-queryFilter text-white default-radius" : "text-white"} me-2 px-1 cursor-pointer d-flex justify-content-center align-items-center`}
                    >
                      <small className='text-center'>New Query</small>
                    </div>
                    <div 
                      onClick={() => toggleFilter("Responded")} 
                      className={`${activeFilter === "Responded" ? "bg-queryFilter text-white default-radius" : "text-white"} me-2 px-1 cursor-pointer d-flex justify-content-center align-items-center`}
                    >
                      <small className='text-center'>Responded</small>
                    </div>
                    <div 
                      onClick={() => toggleFilter("Response Required")} 
                      className={`${activeFilter === "Response Required" ? "bg-queryFilter text-white default-radius" : "text-white"} px-1 cursor-pointer d-flex justify-content-center align-items-center`}
                    >
                      <small className='text-center'>Response Required</small>
                    </div>
                    <div 
                      onClick={() => toggleFilter("Resolved")} 
                      className={`${activeFilter === "Resolved" ? "bg-queryFilter text-white default-radius" : "text-white"} px-1 cursor-pointer d-flex justify-content-center align-items-center`}
                    >
                      <small className='text-center'>Resolved</small>
                    </div>
                  </div>
  )
}

export default BillingQueriesToggler