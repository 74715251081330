import React,{useEffect, useState} from 'react'
import "../../styles/components/MainHeader.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
function MainHeader({props,clicked}) {
  const navigate = useNavigate(); 
  const [showModal,setShowModal] = useState(false)
  
  return (
    <div className='mainContainer' >
      <div className='headerContainer'>
      {props === "home" ? (

     <i onClick={()=>navigate('/dashboard')} style={{color:'white'}} className="fa fa-home" aria-hidden="true"></i> 
      ) : (
        <div></div>
        
      )} 
       <div className='imageContainer' >
        <img width={200} src={require("../../assets/xpheader.png")}></img>
       </div>

       <div onClick={()=>setShowModal(!showModal)} className='dotContainer'>
        <div className='redDotContainer'></div>
        <div className='whiteDotContainer'></div>
        <div className='grayDotContainer'></div>
       </div>
    </div>

    {/* Modal */}
    {showModal && (
    <div className='modalContainer'>
    
    <div>
      <span>Favourites</span>
      </div>
    <hr style={{borderBottomWidth:1}} />
    <div>
      <span>Help</span>
      </div>
      <hr style={{borderBottomWidth:1}} />
    <div>
      <span>Delete Account</span>
      </div>
      <hr style={{borderBottomWidth:1}} />
    <div onClick={()=>navigate('/')} >
      <span>Logout</span>
      </div>
  
  </div>
    )}

    </div>
  )
}

export default MainHeader