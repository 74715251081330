import "../../styles/components/ClientPortal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const RowItem = ({ heading, subHeading, chevron = true, imgPath, clickFunc, disabled = true }) => {
  return (
    <div className={`d-flex justify-content-between rowItem mt-3 p-3 pointer ${disabled && 'gray-color'}`} onClick={clickFunc}>
      <div className="rowItemImage col-2 ">
        <img style={{ objectFit: "contain" }} width={35} alt="" src={imgPath} />
      </div>
      <div className="d-flex flex-column col-9 ">
        <h5 className="text-start">{heading}</h5>
        <span className="text-start rowItemSubHeading">{subHeading}</span>
      </div>
      <div className="col-1 pointer d-flex justify-content-center align-items-center">
        {chevron && <FontAwesomeIcon color="black" icon={faChevronRight} />}
      </div>
    </div>
  );
};

export default RowItem;
