import React from 'react'
import { useNavigate } from 'react-router-dom'
import MainHeader from '../common/MainHeader'
function AddClinicalNote() {
    const navigate= useNavigate()
  return (
    <div>
    <div className="full-width-header">
        <MainHeader props={"home"} />
      </div>
      <div className='container'>
      <div className="backbtnContainer my-2">
        <button className="btn" onClick={() => navigate(-1)}>
          <i style={{ color: 'black' }} className="fa fa-chevron-left me-1" aria-hidden="true"></i>
          ADD CLINICAL NOTE
        </button>
        </div>
       
        <div className='mt-2'>
            <span>ADD CLINICAL NOTE</span></div>
        <div>
        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>

        <div className='mt-3 '>
                <button
                  type="submit"
                  className="formButton w-50"
                  // disabled={isSubmitting}
                >
                  <div className="text-white d-flex align-items-center justify-content-center">
                    <span>Submit</span>
                  </div>
                </button>
              </div>
      </div>
    </div>
  )
}

export default AddClinicalNote