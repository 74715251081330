import axios from "axios";
import PROD_API from "../baseEndpoint";

const InvoiceHistoryDetailsService = (token) => {
  const fetchInvoiceHistoryDetails = async ( referenceNumber) => {
    try {
      // const apiUrl = `${PROD_API}get_invoice_detail?accessToken=${token}&debtorId=${debtorNumber}&invRef=${referenceNumber}&goodx=${isGoodx}`;
      const apiUrl = `${PROD_API}get_invoice_detail?accessToken=${token}&invRef=${referenceNumber}`;

      const response = await axios.post(apiUrl);      

      if (response.status === 200 && response.data) {

        const xpedientDataa = (response.data);
          return xpedientDataa;
      } else {
        console.error("Failed to retrieve invoice details. Status code:", response.status);
        return [];
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      return [];
    }
  };
  
 
  
  return { fetchInvoiceHistoryDetails };
};
export default InvoiceHistoryDetailsService;
