import React from 'react';

function EditFavouritesItems({
    existing,
    deleteCode,
    data,
    title,
    codeType,
    currentKey,
    dataAdded,
    removeNewCode
}) {

    return (
        <div>
            <div className='d-flex justify-content-start border-bottom border-dark mt-3'>
                <span>{title}</span>
            </div>
            {existing ? (
                Object.keys(data).length > 0 || dataAdded.length>0 ? (
                    <>
                        { Object.keys(data).length > 0 && Object.entries(data).map(([code, description], index) => (
                            <div key={index} className='d-flex justify-content-between mt-2'>
                                <span>Code: {code}</span>
                                <div onClick={() => deleteCode(currentKey, code, codeType)} style={{ cursor: 'pointer' }}>
                                    <img
                                        style={{ objectFit: 'contain' }}
                                        src={require("../../assets/trash.png")}
                                        width={20}
                                        height={20}
                                        className='trashicon'
                                        alt="Delete"
                                    />
                                </div>
                            </div>
                        ))}
                         {dataAdded.length > 0 && dataAdded.map((item, index) => (

                            <div key={index}  className='d-flex justify-content-between mt-2'>
                                <span>Code: {codeType ===1 ? item.icd10Code : item.code}</span>
                                <div onClick={()=>removeNewCode(codeType)} >
                                <img
                                        style={{ objectFit: 'contain' }}
                                        src={require("../../assets/trash.png")}
                                        width={20}
                                        height={20}
                                        className='trashicon'
                                        alt="Delete"
                                    />
                                </div>
                            </div>
                        ))}
                       
                    </>
                ) : (
                    <span>-</span>
                )
            ) : (
                data.length > 0 && data.map((code, index) => (
                    <div key={index} className='d-flex justify-content-between mt-2'>
                        <span>Code: {codeType === 1 ? code.icd10Code : code.code}</span>
                        <div style={{ cursor: 'pointer' }} onClick={() => deleteCode(currentKey, code, codeType)}>
                            <img
                                style={{ objectFit: 'contain' }}
                                src={require("../../assets/trash.png")}
                                width={20}
                                height={20}
                                className='trashicon'
                                alt="Delete"
                            />
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default EditFavouritesItems;
