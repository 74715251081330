import React, { useState, useEffect } from 'react';
import MainHeader from "./common/MainHeader";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../styles/components/UploadDoc.scss";
import { useAuth } from "../services/authentication/LoginService.js";
import { useNavigate } from "react-router-dom";
import GenericButton from "./GenericButton.js";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar/Sidebar.js";
import imageCompression from "browser-image-compression";
import { useLocation } from "react-router-dom";
import NavigateBack from "./NavigateBack.js";
import { useContext } from "react";
import { UserContext } from "../services/authentication/UserContext.js";
import UploadAttachmentService from "../services/Billing/UploadAttachment.js";
import Loader from '../utils/Loader.js';

function UploadDoc() {
  const { token } = useAuth();
  const location = useLocation();

  const navigate = useNavigate();
  const [isDragOver, setIsDragOver] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { entityNumber } = useContext(UserContext);
  const uploadAttachmentService = UploadAttachmentService(token);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientFullName = location?.state?.patientFullName || "";



  const handleFileSelect = async (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const processedFile = await processFile(file);
      const base64File = await convertToBase64(processedFile);
      setFieldValue("fileupload", processedFile);
      setFieldValue("fileuploadData", base64File);
      setPreviewUrl(URL.createObjectURL(processedFile));
    }
  };
  
  const handleDrop = async (event, setFieldValue) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const processedFile = await processFile(file);
    const base64File = await convertToBase64(processedFile);
    setFieldValue("fileupload", processedFile);
    setFieldValue("fileuploadData", base64File);
    setPreviewUrl(URL.createObjectURL(processedFile));
    setIsDragOver(false);
  };

  const compressFile = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error("File compression error:", error);
      return file;
    }
  };

  const processFile = async (file) => {
    if (file.type === "application/pdf") {
      return file;
    } else {
      return await compressFile(file);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const validationSchema = Yup.object().shape({
    fileupload: Yup.mixed().required("Please upload a file"),
    // patientFullName: Yup.string().required("Please Enter Name"),
  });

  return (
    <div>
      <Sidebar heading={<h1 className="heading">NEW PATIENT INFORMATION</h1>}>
        <Formik
          initialValues={{
            fileupload: null,
            fileuploadData: null,
            patientFullName: patientFullName || "",
            addnotes: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setFieldError }) => {
            if (!values.fileupload) {
              setFieldError("fileupload", "Please upload a file");
              return;
            }

            const base64Data = await convertToBase64(values.fileupload);
            const fileData = [
              {
                FileName: values.fileupload.name,
                ContentType: values.fileupload.type || "multipart/form-data",
                AttachmentData: base64Data,
              },
            ];
      
            setIsSubmitting(true);
            try {
              const base64Data = await convertToBase64(values.fileupload);
              const fileData = [
                {
                  FileName: values.fileupload.name,
                  ContentType: values.fileupload.type || "multipart/form-data",
                  AttachmentData: base64Data,
                },
              ];
        
              const response = await uploadAttachmentService.handleAttachmentDetails(fileData);
              if (response) {
                console.log("Attachment successfully sent:", response);
                const parmExtra = response.parm_extra === "None" ? null : response.parm_extra;
                console.log(parmExtra);  // This will print `null` if the value is "None", otherwise the actual value

                navigate("/confirmationpageinvoice", {
                  state: {
                    patientFullName: values.patientFullName,
                    message: response.message,        
                    referenceNumber: response.message, 
                    status: response.status
                  }
                });
              }
            } catch (error) {
              console.error("Error sending file:", error);
              setFieldError("fileupload", "Failed to upload file. Please try again.");
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
          }) => {
            const handleProceedToInvoice = () => {
              const fileData = values.fileupload ? {
                FileName: values.fileupload.name,
                ContentType: values.fileupload.type || "multipart/form-data",
                AttachmentData: values.fileuploadData,
              } : null;

              navigate("/invoicesteptwo", {
                state: {
                  patientFullName: values.patientFullName,
                  selectedFile: fileData ? [fileData] : [],
                  notes: values.addnotes,
                },
              });
            };

            return (
              <div className="container">
                <NavigateBack text={"Back"} />
                <div className="text-center column justify-content-center">
                  <div
                    className="uploaddoccontainer col-12"
                    onDrop={(event) => handleDrop(event, setFieldValue)}
                    onDragOver={(event) => {
                      event.preventDefault();
                      setIsDragOver(true);
                    }}
                    onDragLeave={() => setIsDragOver(false)}
                    onClick={() => document.getElementById("fileInput").click()}
                    style={{
                      backgroundColor: isDragOver ? "#B0B0B0" : "#E0E0E0",
                      cursor: "pointer",
                    }}
                  >
                    <div className="d-flex justify-content-center align-items-center w-100">
                      <img
                        src={require("../assets/icons/Upload Picture or Document Icon.png")}
                        style={{ objectFit: "contain" }}
                        height={25}
                        className="mr-1"
                        alt=""
                      />
                      <span className="bold-color">
                        Upload Picture or Document
                      </span>
                    </div>
                  </div>
                  <input
                    id="fileInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => handleFileSelect(event, setFieldValue)}
                  />

                  {values.fileupload ? (
                    <div>
                      <p
                        className="preview"
                        onClick={() =>
                          previewUrl && window.open(previewUrl, "_blank")
                        }
                      >
                        FILE ATTACHED - Click to Preview
                      </p>
                      <p style={{ fontWeight: "bold", color: "#F3002F" }}>
                        File Name: {values.fileupload.name}
                      </p>
                    </div>
                  ) : (
                    <p className="nofilepos">No file selected</p>
                  )}

                  <ErrorMessage
                    name="fileupload"
                    component="div"
                    style={{ color: "#F3002F" }}
                  />

                  <label>Patient Full Name</label>
                  <input
                    type="text"
                    name="patientFullName"
                    value={values.patientFullName}
                    className="form-control"
                    onChange={handleChange("patientFullName")}
                  />
                  <ErrorMessage
                    name="patientFullName"
                    component="div"
                    style={{ color: "#F3002F" }}
                  />

                  <label>Add Notes</label>
                  <textarea
                    type="text"
                    name="addnotes"
                    value={values.addnotes}
                    onChange={handleChange("addnotes")}
                    className="form-control addnotesStyles"
                    rows={20}
                  />
                   <div className="mt-3 d-flex justify-content-center align-items-center ">
                {entityNumber !== "300" && (
                  <GenericButton
                    func={handleProceedToInvoice}
                    styling="formButton text-white subcolor w-50"
                    text="Proceed to Invoice"
                    imgStyle={{
                      objectFit: "contain",
                      filter: "invert(1)",
                      width: 30,
                    }}
                    img={require("../assets/icons/ManualEntryIcon.png")}
                  />
                )}
                </div>
                {entityNumber === "300" && (
                  <div className="mt-3 d-flex justify-content-center align-items-center gap-3">
                    <GenericButton
                      func={handleProceedToInvoice}
                      styling="formButton text-white subcolorBlue w-50"
                      text="Proceed to Invoice"
                      imgStyle={{
                        objectFit: "contain",
                        filter: "invert(1)",
                        width: 30,
                      }}
                      img={require("../assets/icons/ManualEntryIcon.png")}
                    />

                <GenericButton
                      func={handleSubmit}
                      styling="formButton text-white subcolor w-50"
                      text={isSubmitting ? "Submitting..." : "Submit"}
                      imgStyle={{
                        objectFit: "contain",
                        filter: "invert(1)",
                        width: 30,
                      }}
                      img={require("../assets/icons/ManualEntryIcon.png")}
                      disabled={isSubmitting}
                    />
                    {isSubmitting && (
                      <div >
                        <Loader loading={isSubmitting}/>
                      </div>
                    )}
                  </div>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      </Sidebar>
    </div>
  );
}

export default UploadDoc;