import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../services/authentication/LoginService";
import "../../styles/components/login.scss";
import "../../styles/variables/_mixins.scss";
import LoginHeader from "../common/LoginHeader";
import MainFooter from "../common/MainFooter";
import { Link } from "react-router-dom";
import vectorImage from "../../assets/Vector.png";
import vectorFullBGImage from "../../assets/Vectorfull.png";
import { UserContext } from "../../services/authentication/UserContext";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { handleSignIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setEmail, setPassword } = useContext(UserContext);

  const handleFormSubmit = async (values, actions) => {
    setLoading(true);
    const signInResult = await handleSignIn(values.email, values.password);
    setLoading(false);

    if (signInResult.success) {
      if (signInResult.tempPw) {
        navigate("/resetpassword", {
          state: { email: values.email, tempPw: values.password },
        });
      } else {
        console.log("SIgn IN RESULT", signInResult)
        setEmail(values.email);
        setPassword(values.password);
        navigate("/clientportal");
      }
    } else {
      // Here we set the errors from the API response
      actions.setErrors({
        password: signInResult.error || signInResult.parm_extra, // Show API error in password field
      });
    }
  };

  return (
    <div className="login-page-wrapper page-bg-img">
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorBig"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImage"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImageLeft"
      />
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorImageRight"
      />

      {/* White Container */}
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="container shadow bg-white rounded px-4 authContainer">
            <div className="row">
              <div className="col-12">
                <LoginHeader />
              </div>

              <div className="col-12">
                <div className="mt-4">
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({ isSubmitting, errors }) => (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-text"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="position-relative">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control form-control-outlined"
                              id="password"
                            />
                            <i
                              onClick={() => setShowPassword(!showPassword)} 
                              className={`eyePos pointer fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                            ></i>
                          </div>

                          {/* Show error message if password is incorrect */}
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-text d-flex"
                          />
                        </div>
                        <div className="mb-3">
                          <Link to="/forgotpassword" className="link-text">
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="mb-3">
                          <button
                            type="submit"
                            className="w-100 formButton"
                            disabled={isSubmitting || loading}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {loading && (
                                <i className="login-text fas fa-spinner fa-spin me-2"></i>
                              )}
                              <span className="login-text">
                                {loading ? "" : "Login"}
                              </span>
                            </div>
                          </button>
                        </div>
                        <div className="mb-3">
                          <Link to="/signup" className="w-100">
                            <button
                              type="button"
                              className="formButton w-100"
                              disabled={isSubmitting || loading}
                            >
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="login-text">Register</span>
                              </div>
                            </button>
                          </Link>
                        </div>
                        <ErrorMessage
                          name="auth"
                          component="div"
                          className="error-text mt-3"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <MainFooter />
            </div>
          </div>
        </div>
        <div className="col-0 col-md-6"></div>
      </div>
    </div>
  );
};

export default LoginPage;
