import React from 'react'

const LatestReports = () => {
  return (//bg-green
    <div className='d-flex flex-column gray-color rounded p-4 mt-4'> 
    <span className='text-white text-start'>Latest Report</span>
    <div className='reportPdf'>
    <div>
        <img style={{objectFit:'contain'}} className='mr-2' width={40} alt='' src={require("../../assets/ClientPortal/clientPortalPdf.png")}/>
        <small className='text-success'>Montly Report for Dr J Smith</small>
        <small className='text-success ml-4'> | View </small> 
    </div>
    </div>
    </div>
  )
}

export default LatestReports