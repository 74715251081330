import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './LoginService';
import { useAuth } from './LoginService'; 
import useAccessControlList from '../../screens/state/AccessControlList';

const ProtectedRoute = ({ element: Element, mainRoute = null }) => {
  const { token } = useAuth();
  const { authenticate } = useContext(AuthContext);
  

  if (!mainRoute) {
    return token ? <Element /> : <Navigate to="/" />;
  } 
  else {
      if (token ) {
      return <Element />;
    } 
    else {   
       return  <Navigate to="/"/>
    }
  }
};

export default ProtectedRoute;
