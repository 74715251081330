import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import NavigationButton from '../NavigationButton';

const GeneralConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation()
  
  const { message = "Success", refNo = "N/A" } = location?.state || {};
  return (
    <Sidebar>
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <div className='text-center'>
          <img 
            width={150} 
            height={150} 
            src={require("../../assets/success-green-check-mark-icon.png")} 
            alt="Success check mark" 
          />
            <span className='d-block mt-4'>{message}</span>    
            <span>Ref No: {refNo}</span>
          <div onClick={() => navigate(-1)} className='mt-3'>
            <NavigationButton
            header='Back'
            nonExpand={true}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default GeneralConfirmationPage;
