import React, { useState, useEffect } from 'react';
import PROD_API from "../baseEndpoint";
import axios from 'axios';



const MedicalSchemeOption = (token, setSelectedSchemeCode) => {
  const fetchSchemes = async () => {
    try {
      const response = await axios.post(PROD_API + `GoodX/get_medicalaid_schemes?accessToken=${token}`);
      if (response.status === 200 && response.data && Array.isArray(response.data.data)) {

        const schemeData = response.data.data.map(item => ({
          code: item.scheme_code,
          name: item.scheme_name, 
        }));
        // console.log("MEDICAL AID OPTION", schemeData)
        return schemeData;
      } else {
        console.error('Error fetching schemes:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching schemes:', error.message);
    }
  };

  const fetchOptions = async (schemeCode) => {
    try {
      const response = await axios.post(PROD_API + `GoodX/get_medicalaid_options?accessToken=${token}&schemeCode=${schemeCode}`);
      if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
        const optionData = response.data.data.map(item => ({
          code: item.scheme_code,
          option_name: item.option_name, 
          option_code: item.option_code
        }));
        // console.log("MEDICAL AID OPTION", optionData )

        return optionData;
      } else {
        console.error('Error fetching options:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching options:', error.message);
    }
  };

  useEffect(() => {
    fetchSchemes();
  }, []);

  const handleSchemeSelect = (schemeCode) => {
    setSelectedSchemeCode && setSelectedSchemeCode(schemeCode);
    fetchOptions(schemeCode);
  };

  return {
    // handleSchemeSelect,
    fetchSchemes,
    fetchOptions
  };
};

export default MedicalSchemeOption;