import { date } from "yup";

// Convert date to YYYY-MM-DD
export const formatDate = (date) => {
    if (!date) return null;
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

// current date
export const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Returns format YYYY-MM-DD
};


export const formatDateWithLocale = (dateTimeString) => {
  // If the string is not empty, split by space and return the date part
  if (dateTimeString) {
    return dateTimeString.split(' ')[0]; 
  }
  return ''; 
};