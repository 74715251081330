import axios from "axios";
import PROD_API from "../baseEndpoint";

const SwitchEntityService = (token) => {
  const fetchSwitchEntity = async () => {
    try {
      const apiUrl = PROD_API + `get_user_entities?accessToken=${token}`;
      const response = await axios.post(apiUrl);

      if (response.status === 200) {
        const { itemList, status, message, parm_extra } = response.data;
        
        // Return only itemList with other relevant info
        return {
          itemList: itemList || {},
          status,
          parm_extra,
          message,
        };
      } else {
        console.error("Failed to switch entities. Status code:", response.status);
        return { itemList: {}, parm_extra: "No data" };
      }
    } catch (error) {
      console.error("Error switching entities:", error);
      return { itemList: {}, parm_extra: "Error" }; 
    }
  };
  
  return { fetchSwitchEntity };
};

export default SwitchEntityService;
