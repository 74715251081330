import React, { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import SavedReport from "../../../services/Reports/getSavedReport";
import { useAuth } from "../../../services/authentication/LoginService";
import NavigateBack from "../../NavigateBack";
import GenericButton from "../../GenericButton";
import Loader from "../../../utils/Loader";
const SavedReports = () => {
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState(null);
  const { token } = useAuth();
  const [loading,setLoading] = useState(false)
  const reports = location.state;
  const reportIndex = reports.reportIndex;
  const directoryName =
    reports.reports.SubDirectories[reportIndex].DirectoryName;
   const { getSavedReport } = SavedReport(token);

  const downloadOrviewReport = async (name, action) => {
    try {
      setLoading(true)
      const getReport = await getSavedReport(directoryName, name);

      let type = getReport.ContentType;
      //   let extension = getReport.ContentType.split("/")[1]
      const binaryData = atob(getReport.AttachmentData);
      const byteArray = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      if (action === "view") {
        window.open(url, "_blank");
      } else {
        link.href = url;
        link.download = `${name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading the report:", error);
    }finally {
      setLoading(false)
    }
  };
  return (
    <Sidebar>
      <div className="container mt-4">
        <NavigateBack text={"Saved Reports"} />
        <div className="row border-bottom border-gray mb-3">
          <span className="text-start">{reports.reportType}</span>
        </div>
        <div className="row border-bottom border-black mb-3">
          <span className="text-start col-4">Report Name</span>
          <span className="text-start col-4">Date</span>
          <span className="text-start col-4"></span>
        </div>
        <div>
          <Loader loading={loading}/>
          {reports.reports.SubDirectories[reportIndex].Files.map(
            (item, idx) => (
              <div
                className={`row ${idx % 2 === 0 ? "bg-white" : "bg-light"}`}
                key={idx}
              >
                <div className="d-flex justify-content-start align-items-center col-md-4 col-12">
                  <span className="text-start">
                    {reports.reportType} - {item}
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center col-4">
                  <span className="text-start">Date</span>
                </div>
                <div className="row col-md-4 col-12 justify-content-end align-items-end">
                  {reports.reportType !== "VAT Reports" && (
                  <GenericButton
                  func={() => downloadOrviewReport(item, "view")}
                  text="View"
                  bgColor={"bg-queryFilter"}
                  styling="p-2 col-4 rounded text-center "
                />
                  )}
                  <GenericButton
                    func={() => downloadOrviewReport(item, "download")}
                    text="Download"
                    bgColor={"bg-queryFilter"}
                    styling="p-2 col-4 rounded text-center"
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default SavedReports;
