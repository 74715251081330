// useProcedureCodesStore.js
import {create} from 'zustand';

const useReferringDoctorStore = create((set) => ({
    surnameAndName:"",
    isRefDocAdIconClicked:false,
    practiceNumber:"",
    specialityCode:"",
    isRefDocFlatListVisible:false, 
    setIsRefDocFlatListVisible:(isRefDocFlatListVisible)=>set({isRefDocFlatListVisible}),
    setSurnameAndName:(surnameAndName)=>set({surnameAndName}),
    setIsRefDocAdIconClicked:(isRefDocAdIconClicked)=>set({isRefDocAdIconClicked}),
    setPracticeNumber:(practiceNumber)=>set({practiceNumber}),
    setSpecialityCode:(specialityCode)=>set({specialityCode}),
    clearReferringDoc: () => set({
        surnameAndName:"",
        isRefDocAdIconClicked:false,
        practiceNumber:"",
        specialityCode:"",
    })
}));

export default useReferringDoctorStore;
