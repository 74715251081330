import React from "react";
import CustomScroll from "./common/CustomScroll";
import EnlargeItem from "./EnlargeItem";
const SubTaskComments = ({ selectedSubtask, toggleAdjustmentModal }) => {
  return (
    <>
      {selectedSubtask &&
        selectedSubtask.comments &&
        selectedSubtask.comments.map((item, index) => (
          <div
            key={index}
            className="d-flex justify-content-between px-3 p-2 row"
          >
            <div className="d-flex justify-content-center align-items-center col-3 ">
              <span>{item.commentDate}</span>
            </div>
            <div className="d-flex justify-content-between gray p-2 rounded col-9">
              <CustomScroll smallerScroll={true} maxHeight={"10vh"}>
                <small>
                  {item.commentDesc.length > 50
                    ? item.commentDesc.slice(0, 50) + "..."
                    : item.commentDesc}
                </small>
              </CustomScroll>
              <EnlargeItem
                openFunction={() =>
                  toggleAdjustmentModal(item.commentDesc, item.commentDate)
                }
                width={20}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default SubTaskComments;
