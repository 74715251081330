import React from 'react';

const SearchInput = ({ value, onChange, placeholder }) => {
  // Function to handle clearing the input
  const clearInput = () => {
    onChange('');  // Clear the value by setting it to an empty string
  };

  return (
    <div className="position-relative">
      <input
        className="form-control bg-light border-0 p-2"
        placeholder={placeholder}
        style={{ paddingRight: '30px' }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
        {value && (
        <i
          style={{ position: 'absolute', right: 40, top: 10, cursor: 'pointer' }}
          className="fa fa-times-circle"
          onClick={clearInput} 
        ></i>
      )}
      <i
        style={{ position: 'absolute', right: 10, top: 10 }}
        className="fa fa-search"
      ></i>
      
    </div>
  );
};

export default SearchInput;
