import React, { useState } from "react";
import WeightPickerModal from "./ModalInvoice/WeightPickerModal";
import HeightPickerModal from "./ModalInvoice/HeightPickerModal";
import ModifierCode from "./ModifierCode";
import useModifiersStore from "../state/ModifiersStore";
import { useFormikContext } from "formik";
import TimeFromTo from "./TimeFromTo";
import Assistants from "./Assistants";
function Modifiers({
  exceptionLineItems,
  handleFavouriteQuantityChange,
  handleRemoveItem,
  weightCodes,
  assistantVals,
}) {
  const { errors, setFieldValue, touched, setFieldTouched, handleBlur,values } =
    useFormikContext();
  const [modalVisible, setModalVisible] = useState(true);
  const [searchTermAssistantList, setSearchTermAssistantList] = useState("");
  // const [assistantVals, setAssistantVals] = useState([]);
  const {
    showAssistant,
    setShowAddAssistant,
    setNameSurname,
    nameSurname,
    searchTermSpeciality,
    setSearchTermSpeciality,
    times,
    setTimes,
    setSelectedValues,
    
    // times,
    // setTimes,
  } = useModifiersStore();
  const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const [modalWeightVisible, setModalWeightVisible] = useState(false);
  const [modalHeightVisible, setModalHeightVisible] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [assistantFlatlist, setIsAssistantFlatlist] = useState(false);
  const [activePicker, setActivePicker] = useState("");
  const [selectedAssistantList, setSelectedAssistantList] = useState(false);


  const toggleWeightModal = () => {
    setModalWeightVisible((prev) => !prev);
  };

  const toggleHeightModal = () => {
    setModalHeightVisible((prev) => !prev);
  };
  const handleSelectTimes = (selectedTime, selectedCode,picker,setFieldValue) => {
    setTimes(selectedCode, picker, selectedTime,setFieldValue);
  };
  const handleWeightSelect = (weight, setFieldValue) => {
    setFieldValue("weight", weight, true);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [selectedCode]: {
        ...prevValues[selectedCode],
        weight: weight,
      },
    }));
    toggleWeightModal();
    setFieldTouched("weight", true);
  };

  const handleHeightSelect = (height, setFieldValue) => {
    setFieldValue("height", height, true);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [selectedCode]: {
        ...prevValues[selectedCode],
        height: height,
      },
    }));
    toggleHeightModal();
    setFieldTouched("height", true);
  };
  return (
    <div>
      {exceptionLineItems.map((item, index) => (
        <div key={item.code}>
          <ModifierCode
            key={item.code} // Ideally, use a unique identifier from the item
            item={item}
            index={index}
            handleFavouriteQuantityChange={handleFavouriteQuantityChange}
            handleRemoveItem={handleRemoveItem}
          />
          <div style={{ flex: 1 }}>
            {/* TIME MODIFIERS */}
            <TimeFromTo
            timeFromToCodes={timeFromToCodes}
            item={item}
            handleSelectTimes={handleSelectTimes}
            times={times}
            />
          </div>

          {/* ASSISTANTS MODIFIERS */}
          <Assistants
          assistantCodes={assistantCodes}
          item={item}
          setShowAddAssistant={setShowAddAssistant}
          assistantFlatlist={assistantFlatlist}
          showAssistant={showAssistant}
          setNameSurname={setNameSurname}
          nameSurname={nameSurname}
          searchTermSpeciality={searchTermSpeciality}
          setSearchTermSpeciality={setSearchTermSpeciality}
          assistantVals={assistantVals}
        />

          {/* WEIGHT MODIFIERS */}
          {weightCodes.includes(item.code) && (
            <div>
              {/* {item.code === "0018"
                            ? "Weight and Height"
                            : "Weight"} */}

              <WeightPickerModal
                isVisible={modalWeightVisible}
                onSelect={(weight) => handleWeightSelect(weight, setFieldValue)}
                onCancel={toggleWeightModal}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />

              {/* HEIGHT MODIFIERS */}
              {item.code === "0018" && (
                <HeightPickerModal
                  isVisible={modalHeightVisible}
                  onSelect={(height) =>
                    handleHeightSelect(height, setFieldValue)
                  }
                  onCancel={toggleHeightModal}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Modifiers;
