import React, { useState, useCallback, useEffect } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import ICD10Service from '../../services/Billing/ICD10Service';
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import useProcedureCodesStore from '../state/ProcedureCodeStore';
import useICD10CodesStore from '../state/ICD10CodesStore';
const ICD10 = ({reInvoice = null}) => {
  const {setFieldValue} = useFormikContext()
  const [searchTermICD10, setSearchTermICD10] = useState('');
  const [icd10Data, setIcd10Data] = useState([]);
  const [filteredIcd10Data, setFilteredIcd10Data] = useState([]);
  const icd10Service = ICD10Service();
  const [error,setError] = useState("")
  const {isICD10FlatListVisible, setIsICD10FlatListVisible} = useICD10CodesStore()
  const {selectedICD10Codes,setSelectedICD10Codes} = useProcedureCodesStore()
    // Handle search input changes

    useEffect(() => {
      if (reInvoice) {
        const codes = (
          reInvoice.invoiceLines?.length > 0 &&
          reInvoice.invoiceLines[0]?.Diagnosis?.length > 0
        ) ? reInvoice.invoiceLines[0].Diagnosis.map(icd10Code => ({ icd10Code })) : [];
    
        setSelectedICD10Codes(codes);
      }
    }, []); // Add reInvoice as a dependency
    const handleRemoveICD10 = (code) => {
      const updatedCodes = selectedICD10Codes.filter((item) => item.icd10Code !== code);
      setSelectedICD10Codes(updatedCodes);
      setFieldValue("selectedICD10Codes",updatedCodes)
    };
    const debouncedSearchICD10 = useCallback(
      _.debounce((text) => {
        setSearchTermICD10(text);
      }, 250),
      []
    );
    const handleSearchICD10Input = (text) => {
      setSearchTermICD10(text);
  
      // Hide dropdown and clear data if input is empty
      if (text.trim() === "") {
        setFilteredIcd10Data([]);
        setIsICD10FlatListVisible(false);
      } else {
        debouncedSearchICD10(text);
        setIsICD10FlatListVisible(true);
      }
    };
      //-------------------------------------------ICD10 CODE -------------------------------------------------------//
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await icd10Service.ICD10Data();
        if (data && data.length > 0) {
          setIcd10Data(data);
        } else {
          setIcd10Data([]);
          setError("Failed to fetch ICD10 data");
        }
      } catch (err) {
        setIcd10Data([]);
        setError(err.message);
      }
    };
    fetchData();
  }, []);
      // Filter ICD-10 data based on search term
  useEffect(() => {
    const lowerCasedSearchTerm = searchTermICD10.toLowerCase().trim();
    const trimmedArray = lowerCasedSearchTerm.split(" ");
    const valuesAfterTrim = trimmedArray.slice(1);
    const filteredData = icd10Data.filter((item) => {
      const icd10CodeMatch = item.icd10Code && item.icd10Code.toLowerCase().includes(lowerCasedSearchTerm);
      const descriptionMatch = item.whoFullDesc && item.whoFullDesc.toLowerCase().includes(lowerCasedSearchTerm);
      if (valuesAfterTrim.length > 0) {
        return valuesAfterTrim.some(value =>
          (item.icd10Code && item.icd10Code.toLowerCase().includes(value)) ||
          (item.whoFullDesc && item.whoFullDesc.toLowerCase().includes(value))
        );
      }

      return icd10CodeMatch || descriptionMatch;
    }).slice(0, 20);

    setFilteredIcd10Data(filteredData);
  }, [searchTermICD10, icd10Data]);
    const handleSelectICD10 = (item, setFieldValue) => {
      const isCodeSelected = selectedICD10Codes.some((code) => code.icd10Code === item.icd10Code);
      let newSelectedICDCodes;
  
      if (isCodeSelected) {
        newSelectedICDCodes = selectedICD10Codes.filter((code) => code.icd10Code !== item.icd10Code);
      } else {
        newSelectedICDCodes = [...selectedICD10Codes, item];
      }
      setSelectedICD10Codes(newSelectedICDCodes);
      setFieldValue("selectedICD10Codes", newSelectedICDCodes);
      setSearchTermICD10("");
      setIsICD10FlatListVisible(false);
    };
  return (
    <div className='row align-details'>
      <div className='mb-3 text-font'>
        <label htmlFor="icd10Codes">ICD10 Codes<span className="error-text">*</span></label>
        <div className='position-relative'>
          <input
            type="text"
            value={searchTermICD10}
            onChange={(e) => handleSearchICD10Input(e.target.value)}
            className="form-control"
            id="icd10Codes"
            onClick={(e)=> e.stopPropagation()}
            placeholder='Enter Primary ICD10 Code First'
          />
           <i className="fas fa-search searchIcon"></i>
          {/* Show the dropdown when there are filtered results and the input is not empty */}
          {isICD10FlatListVisible && filteredIcd10Data.length > 0 && (
            <div className="dropdown-list">
              <ul className="suggestions-list">
                {filteredIcd10Data.map((item) => (
                  <li key={item.icd10Code} onClick={() => handleSelectICD10(item, setFieldValue)}>
                    {item.icd10Code} - {item.whoFullDesc}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="selected-icd10codes">
          {selectedICD10Codes.map((item) => (
            <div key={item.icd10Code} className="selected-code">
              {item.icd10Code}
              <span className="remove-icon" onClick={() => handleRemoveICD10(item.icd10Code)}>✕</span>
            </div>
          ))}
        </div>

        <ErrorMessage name="selectedICD10Codes" component="div" className="error-text d-flex" />
      </div>
    </div>
  );
};

export default ICD10;
