// useProcedureCodesStore.js
import { create } from "zustand";

const useICD10CodesStore = create((set) => ({
    isICD10FlatListVisible: false,
    setIsICD10FlatListVisible: (isICD10FlatListVisible) => set({ isICD10FlatListVisible }),


}));

export default useICD10CodesStore;
