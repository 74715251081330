import React,{useState, useRef} from 'react'
import { useEffect } from 'react'
import { Formik } from 'formik'
import * as XLSX from 'xlsx';

import "../../styles/components/FinancialDashboard.scss"

import Sidebar from '../Sidebar/Sidebar'
import { formatDate } from '../../utils/dateUtils'
import GenericFinanceTab from './GenericFinanceTab'
import AgeAnalysisTables from './AgeAnalysisTables'
import MainFooter from '../common/MainFooter'
import GenericMonthly from './GenericMonthly'
import SideNavbar from '../common/SideNavbar'
import Chart from 'react-google-charts'
import { useAuth } from '../../services/authentication/LoginService'
import Cookie from 'universal-cookie'
import { getRandomColor } from '../../utils/pieChartColors'
import { getCurrentDate } from '../../utils/dateUtils'

import AgeAnalysisService from '../../services/FinancialDashboard/AgeAnalysisService'
import dailyStatisticService from '../../services/FinancialDashboard/dailyStatisticService'
import monthlyStatisticService from '../../services/FinancialDashboard/monthlyStatisticService'
import Loader from '../../utils/Loader';
import EntriesDropdown from '../../utils/showEntriesUtils';  
import Pagination from '../../utils/paginationUtils';  
import { exportToExcel } from '../../utils/excelUtils';     
import SearchInput from '../../utils/searchUtils'   
import ValueChange from '../../utils/financialValueChange';
import useClearStores from '../../utils/clearStores';

function FinancialDashboard() {
    var cookie = new Cookie();
    // const token = cookie.get("jwt_authentication");
    const { token } = useAuth();
    const clearStores = useClearStores()
    const chartRef = useRef(null);
    const ageAnalysiToken = AgeAnalysisService(token);
    const { fetchAgeAnalysis } = monthlyStatisticService(token);
    const [monthlyStats, setMonthlyStats] = useState(null);
    const [monthlyBreakdownSummary, setMonthlyBreakdownSummary] = useState({});
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
    const [months, setMonths] = useState([]);
    const [activeTab,setActiveTab] = useState("SD") ;
    const [hospitalVisitsOpen,setHospitalVisitsOpen] = useState(false);
    const [selectedDaily,setSelectedDaily] = useState(0);
    const [statusAnalysisData, setStatusAnalysisData] = useState([]);
    const [medicalAidAnalysisData, setMedicalAidAnalysisData] = useState([]);
    const [medicalAidChartData, setMedicalAidChartData] = useState([]);
    const [dailyStats, setDailyStats] = useState(null);
    const [currentDailyAnalysisData, setCurrentDailyAnalysisData] = useState([]);
    const [topReferringDocs, setTopReferringDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataReady, setDataReady] = useState(false);
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [selectedFromDate, setSelectedFromDate] = useState("");
    const [selectedToDate, setSelectedToDate] = useState("");
    const [dailyAnalysisData, setDailyAnalysisData] = useState([]);
    const [error, setError] = useState(null); 
    const [entriesPerPage, setEntriesPerPage] = useState(40);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [invoicesCreated, setInvoicesCreated] = useState(null);
    const [moniesReceipted, setMoniesReceipted] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [chartColors, setChartColors] = useState([]);
    const [monthlyJournals, setMonthlyJournals] = useState(null);
    const [selectedHeading, setSelectedHeading] = useState("");
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [dailyBreakdown, setDailyBreakdown] = useState({
      invoices_created: '',
      monies_receipted: '',
      journals: ''
    });    
    const [dailyBreakdownAvgs, setDailyBreakdownAvgs] = useState({
      invoices_created: '',
      monies_receipted: '',
      journals: ''
    });
    const [monthlyDiff, setMonthlyDiff] = useState({
      invoices_diff: '',
      monies_diff: '',
      journals_diff: ''
    });

    useEffect(()=>{
      clearStores()
    },[])
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handleEntriesChange = (entries) => {
      setEntriesPerPage(entries);
      setCurrentPage(1); 
    };
  const handleSidebarOpen = (heading) => {
    setSelectedHeading(heading);  
    let facilitiesData = [];
    if (heading === "Room Visits") {
      facilitiesData = monthlyStats.room_visits.facilities_visited;  
    } else if (heading === "Hospital Visits") {
      facilitiesData = monthlyStats.hospital_visits.facilities_visited;  
    } else if (heading === "Day Clinic Visits") {
      facilitiesData = monthlyStats.day_clinic_visits.facilities_visited;  
    }
    setFacilitiesData(facilitiesData); 
    setHospitalVisitsOpen(true);
  };
  
  

  const filteredDocs = topReferringDocs.filter((doc) =>
    doc.referring_doctor.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const totalPages = Math.ceil(filteredDocs.length / entriesPerPage);
  const currentDocs = filteredDocs.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

      
    const handleDateChange = (e) => {
      const date = e.target.value;
      setSelectedDate(date);
    };

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        setError(null); 
        try {
          const { fetchDailyStatistics } = dailyStatisticService(token);
          const statsData = await fetchDailyStatistics(); 
          console.log("DAILY STAT", statsData)
          if (statsData) {
            if (statsData.age_analysis) {
              const mappedAnalysis = [
                { heading: "Total", amount: statsData.age_analysis.total },
                { heading: "Current", amount: statsData.age_analysis.current },
                { heading: "30 Days", amount: statsData.age_analysis.days_30 },
                { heading: "60 Days", amount: statsData.age_analysis.days_60 },
                { heading: "90 Days", amount: statsData.age_analysis.days_90 },
                { heading: "120 Days", amount: statsData.age_analysis.days_120 },
                { heading: "150 Days", amount: statsData.age_analysis.days_150 },
                { heading: "180+ Days", amount: statsData.age_analysis.days_180_plus },
              ];
              setCurrentDailyAnalysisData(mappedAnalysis);
            } else {
              setCurrentDailyAnalysisData([]); 
            }
            setDailyBreakdown({
              invoices_created: statsData.daily_breakdown.invoices_created,
              monies_receipted: statsData.daily_breakdown.monies_receipted,
              journals: statsData.daily_breakdown.journals
            });
  
            setDailyBreakdownAvgs({
              invoices_created: statsData.daily_breakdown_avgs.invoices_created,
              monies_receipted: statsData.daily_breakdown_avgs.monies_receipted,
              journals: statsData.daily_breakdown_avgs.journals
            });
            setDailyStats(statsData);
          } else {
            setDailyStats(null);
            setCurrentDailyAnalysisData([]);
          }
        } catch (err) {
          setError("Failed to fetch data.");
          setDailyStats(null);
          setCurrentDailyAnalysisData([]); 
        } finally {
          setLoading(false); 
        }
      };

      fetchData();
    }, [token, selectedDate]);

    const handleDateFromChange = (e) => {
      const date = e.target.value;
      setSelectedFromDate(date);
    };
    const extractYearAndMonth = (date) => {
        if (!date) return { year: null, month: null };
        const [year, month] = date.split('-'); 
        return { year, month };
        };
    
        const handleFetchStats = async () => {
            setLoading(true);  
            setError(null);
          
            try {
              const { year: formattedYear, month: formattedMonth } = extractYearAndMonth(selectedFromDate);
              const data = await fetchAgeAnalysis(formattedYear, formattedMonth);
              const breakdown = data;
              if (data && data.monthly_breakdown_summary && data.monthly_breakdown_summary.month_breakdown) {
                const { invoices_created, monies_receipted, journals } = data.monthly_breakdown_summary.month_breakdown;
          
                const invoicesCreatedNumber = parseFloat(invoices_created) || 0;
                const moniesReceiptedNumber = parseFloat(monies_receipted) || 0;
                const journalsNumber = parseFloat(journals) || 0;
                setInvoicesCreated(invoicesCreatedNumber);
                setMoniesReceipted(moniesReceiptedNumber);
                setMonthlyJournals(journalsNumber);
                setMonthlyDiff({
                  invoices_diff: data.monthly_breakdown_summary.invoices_diff,
                  monies_diff: data.monthly_breakdown_summary.monies_diff,
                  journals_diff: data.monthly_breakdown_summary.journals_diff
                })
              } else {
                // If the expected structure is missing, log an error
                console.error("Missing data structure for month_breakdown:", data);
              }
             
              const roomVisits = data?.room_visits || {};
              const breakdownByMonthByType = data?.breakdown_by_month_by_type || [];  
              const topReferringDocs = data?.top_referring_docs || [];
          
              const sampleMonths = breakdownByMonthByType.length > 0 ? Object.keys(breakdownByMonthByType[0].month_list) : [];
              setMonths(sampleMonths);
              setMonthlyBreakdown(breakdownByMonthByType);
          
              setMonthlyStats({
                room_visits: roomVisits,
                hospital_visits: data?.hospital_visits || [],
                day_clinic_visits: data?.day_clinic_visits || []
              });
              setMonthlyBreakdownSummary(data.monthly_breakdown_summary || {});
              setTopReferringDocs(topReferringDocs);
            } catch (err) {
              setError("Failed to fetch data.");
              console.error(err);
            } finally {
              setLoading(false);  
            }
          };
          
    const getCurrentMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); 
        return `${year}-${month}`;
      };

      useEffect(() => {
        const currentMonth = getCurrentMonth();
        const currentDate = new Date();
        const current12Month = currentDate.getMonth(); 
        const currentYear = currentDate.getFullYear();
        const past12Months = new Date(currentYear, current12Month - 12, 1);
        const past12MonthsYear = past12Months.getFullYear();
        const past12MonthsMonth = past12Months.getMonth(); 
        const formattedCurrentDate = `${currentYear}-${String(current12Month + 1).padStart(2, '0')}`;
        const formattedPast12Months = `${past12MonthsYear}-${String(past12MonthsMonth + 1).padStart(2, '0')}`;    
        const minDate = formattedPast12Months; 
        const maxDate = formattedCurrentDate; 
        setMinDate(minDate);
        setMaxDate(maxDate);
        setSelectedFromDate(currentMonth);  
      }, []);  
    
      useEffect(() => {
        if (selectedFromDate) {
          handleFetchStats();  
        }
      }, [selectedFromDate]);
      const handleExportAgeAnalysis = () => {
        const headers = [
          "Account Status",
          ...Object.keys(statusAnalysisData[Object.keys(statusAnalysisData)[0]]).map(key =>
            key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
          )
        ];
    
        const data = Object.keys(statusAnalysisData).map(row => {
          const currentRowData = statusAnalysisData[row];
          return [
            row,
            ...Object.keys(currentRowData).map(col => currentRowData[col] !== undefined ? currentRowData[col] : "0.00")
          ];
        });
    
        const sheetData = {
          data: [headers, ...data],
          sheetName: "Age Analysis by Status"
        };
    
        exportToExcel([sheetData], "age_analysis_by_status.xlsx");
      };
      const handleExportBreakdownByMonth = () => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];  
        const filename = `Monthly_Breakdown_By_Month_${formattedDate}.xlsx`;  
        const dataSheets = [
          {
            data: monthlyBreakdown.map((breakdown) => ({
              Breakdown_Type: breakdown.breakdown_type,
              ...breakdown.month_list,
            })),
            sheetName: "Monthly Breakdown by Month",
          },
          {
            data: currentDocs.map((doc) => ({
              Treating_Doctor: doc.treating_doctor,
              Amount: doc.amount,
              Referring_Doctor: doc.referring_doctor,
            })),
            sheetName: "Top Referring Doctors",
          },
        ];  
        exportToExcel(dataSheets, filename);  
      };
      const handleExportTopRefDoc = () => {
        const topDoctorsData = currentDocs.map((doc) => ({
          Treating_Doctor: doc.treating_doctor || 'N/A',
          Amount: doc.amount || 'N/A',
          Referring_Doctor: doc.referring_doctor || 'N/A',
        }));
      
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];  
        const filename = `Top_Referring_Doctors_${formattedDate}.xlsx`;  
      
        const dataSheets = [
          {
            data: topDoctorsData,
            sheetName: "Top Referring Doctors",
          },
        ];      
        exportToExcel(dataSheets, filename);
      };
      

    useEffect(() => {
      handleViewAgeAnalysisClick();
    }, []);

      const handleViewAgeAnalysisClick = async () => {
        const formattedDate = formatDate(selectedDate);
      
        if (formattedDate) {
          setLoading(true);
          setDataReady(false);
          try {
            const fetchAge = await ageAnalysiToken.fetchAgeAnalysis(formattedDate);
            if (fetchAge) {
              
              // 1. General Age Analysis
              if (fetchAge.age_analysis) {
                const mappedAnalysis = [
                  { heading: "Total", amount: fetchAge.age_analysis.total },
                  { heading: "Current", amount: fetchAge.age_analysis.current },
                  { heading: "30 Days", amount: fetchAge.age_analysis.days_30 },
                  { heading: "60 Days", amount: fetchAge.age_analysis.days_60 },
                  { heading: "90 Days", amount: fetchAge.age_analysis.days_90 },
                  { heading: "120 Days", amount: fetchAge.age_analysis.days_120 },
                  { heading: "150 Days", amount: fetchAge.age_analysis.days_150 },
                  { heading: "180+ Days", amount: fetchAge.age_analysis.days_180_plus },
                ];
                setDailyAnalysisData(mappedAnalysis);
              }
      
              // 2. Age Analysis by Status
              if (fetchAge.age_analysis_status && Array.isArray(fetchAge.age_analysis_status)) {
                const mappedStatusAnalysis = {};
                fetchAge.age_analysis_status.forEach((status) => {
                  mappedStatusAnalysis[status.account_status] = {
                    total: status.age_analysis.total,
                    current: status.age_analysis.current,
                    days_30: status.age_analysis.days_30,
                    days_60: status.age_analysis.days_60,
                    days_90: status.age_analysis.days_90,
                    days_120: status.age_analysis.days_120,
                    days_150: status.age_analysis.days_150,
                    days_180_plus: status.age_analysis.days_180_plus,
                  };
                });
                setStatusAnalysisData(mappedStatusAnalysis);
              } else {
                console.error("Invalid or missing age analysis by status data.");
              }
      
              // 3. Age Analysis by Medical Aid
              if (fetchAge.age_analysis_medical_aid && Array.isArray(fetchAge.age_analysis_medical_aid)) {
                const grandTotal = fetchAge.age_analysis.total;
      
                const medicalAidData = fetchAge.age_analysis_medical_aid.map((medicalAid) => {
                  if (!medicalAid || !medicalAid.age_analysis) {
                    console.error("Medical aid or age analysis data is missing:", medicalAid);
                    return null;
                  }
      
                  const totalAmount = parseFloat(medicalAid.age_analysis.total) || 0;
                  const percentage = (totalAmount / grandTotal) * 100;
      
                  const color = medicalAid.color || getRandomColor();
      
                  return {
                    name: medicalAid.medical_aid,
                    total: totalAmount,
                    percentage: percentage.toFixed(2), 
                    color: color,
                  };
                }).filter(Boolean); 
      
                setMedicalAidChartData(medicalAidData);
      
                const chartData = [
                  ['Medical Aid', 'Percentage'],
                  ...medicalAidData.map((item) => [item.name, parseFloat(item.percentage)]),
                ];
      
                const chartColors = medicalAidData.map((item) => item.color);
                if (Array.isArray(fetchAge.age_analysis_medical_aid)) {
                        const mappedMedicalAidAnalysis = fetchAge.age_analysis_medical_aid.map((medicalAid) => {
                            if (!medicalAid || !medicalAid.age_analysis) {
                                console.error("Medical aid or age analysis data is missing:", medicalAid);
                                return null; 
                            }

                            return {
                                heading: medicalAid.medical_aid,
                                ...medicalAid.age_analysis 
                            };
                        }).filter(Boolean); 
                        setMedicalAidAnalysisData(mappedMedicalAidAnalysis);
                    } else {
                        console.error("age_analysis_medical_aid is not an array:", fetchAge.age_analysis_medical_aid);
                    }
      
                setChartData(chartData);
                setChartColors(chartColors);
              } else {
                console.error("age_analysis_medical_aid is not an array:", fetchAge.age_analysis_medical_aid);
              }
            }
            setDataReady(true);
          } catch (error) {
            console.error("Error fetching age analysis data:", error);
          } finally {
            setLoading(false);
          }
        }
      };
      const handleExportWithChart = () => {

      };
      
    return (
            <Sidebar heading={<h1 className="heading">FINANCIAL DASHBOARD</h1>}>
              <Formik
              initialValues={{
                fromDateMonthly:"",
                toDateMonthly:""
              }}
              >
              {({
              errors,
              touched,
            }) => (
              
          <div className='mt-4'>
            <div className='d-flex justify-content-start pointer position-relative'>
              <span onClick={() => setActiveTab("SD")} className={`text-start ml-4`}>
                Statistics - Daily
              </span>
              <span onClick={() => setActiveTab("SM")} className={`text-start ml-4`}>
                Statistics - Monthly
              </span>
              <span onClick={() => setActiveTab("AA")} className={`text-start ml-4`}>
                Age Analysis
              </span>
              <div className={
                activeTab === "SD" 
                  ? 'bottomBorderContainer1' 
                  : activeTab === "SM" 
                  ? 'bottomBorderContainer2' 
                  : 'bottomBorderContainer3'
              }>
                <div className='bottomBorderPurple'></div>
              </div>
            </div>
     
           <div className='w-100 border-bottom mt-3'></div>       
          <div className='container '>
          {activeTab === "SD" ? (
              <>
              <div className="d-flex flex-column align-items-start">
                    <GenericFinanceTab
                      averages={false}
                      invoicesCreated = {dailyBreakdown.invoices_created}
                      moniesReceipted =  {dailyBreakdown.monies_receipted}
                      journals= {dailyBreakdown.journals}
                      heading="Daily Breakdown"
                    />
                    <GenericFinanceTab
                      averages={true}
                      invoicesCreated = {dailyBreakdownAvgs.invoices_created}
                      moniesReceipted = {dailyBreakdownAvgs.monies_receipted}
                      journals = {dailyBreakdownAvgs.journals}
                      heading="Daily Breakdown - Averages"
                      subHeading="Averages are based on data of the previous 12 months"
                    />
              </div>  
                <div className='bg-light mt-4'>
                    <div className='p-4'>
                      <h4 className='d-flex bold-color '>Age Analysis</h4>
                    <div className="row">
                      {currentDailyAnalysisData.length > 0 ? (
                        currentDailyAnalysisData.map((item, idx) => (
                          <div
                            onClick={() => setSelectedDaily(idx)}
                            className={`${
                              selectedDaily === idx
                                ? "col-lg col-12 daily-item"
                                : "col-lg col-12 white-custom"
                            } bottom-tabs rounded pointer m-1 border-0`}
                            key={idx}
                          >
                            <div>
                              <span
                                className={`${
                                  selectedDaily === idx ? "text-white" : "text-dark"
                                }`}
                              >
                                {item.heading}
                              </span>
                            </div>
                            <span
                              className={`${
                                selectedDaily === idx ? "text-white" : "text-darkblue bold-color"
                              }`}
                            >
                              {item.amount}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p>No data available.</p> 
                      )}
                  </div>
                  </div>
              </div>
         </>
         )  : activeTab === 'SM' ? (
                    <>
          <div>
              <div className='d-flex mt-4 mb-2 align-items-center'>
                  <div className='position-relative'>
                      <input 
                        onChange={handleDateFromChange} 
                        className='absolute-element' 
                        type='month'
                        min={minDate} 
                        max={maxDate}
                      />
                  </div>
                  <img src={require("../../assets/DateTo.png")} style={{ objectFit: "contain"}} height={80}/>   
                  <div className='d-flex flex-column'>
                      <span>Select Month:</span>
                      <small>{selectedFromDate}</small>
                  </div> 
                  {/* <div className='daily-item text-white p-3 rounded ml-2' onClick={handleFetchStats}>
                      <span className='text-align-center'>View</span>
                  </div>  */}
            </div>
            <div className="my-4">
                {loading ? (
                    <Loader loading={loading}/>
                ) : (
                    <div>
                    <span className='d-flex ml-2 mt-4'>
                        NOTE: Month to Date figures will show from the 3rd of every month, due to month-end processes.
                    </span>
                    
                    {dataReady && !loading && (
                        <>
                        <div className='d-flex flex-column flex-lg-row justify-content-around'>
                            {monthlyStats && (
                            <>
                        <GenericMonthly
                            invoiced={monthlyStats.room_visits.total_invoiced}
                            imagePath={require("../../assets/Room_Financial.png")}
                            heading={"Room Visits"}
                            visits={monthlyStats.room_visits.total_visits_count}
                            onClick={() => handleSidebarOpen("Room Visits")}  
                          />
                          <GenericMonthly
                            invoiced={monthlyStats.hospital_visits.total_invoiced}
                            imagePath={require("../../assets/Hospital_Financial.png")}
                            heading={"Hospital Visits"}
                            visits={monthlyStats.hospital_visits.total_visits_count}
                            onClick={() => handleSidebarOpen("Hospital Visits")}
                          />
                          <GenericMonthly
                            invoiced={monthlyStats.day_clinic_visits.total_invoiced}
                            imagePath={require("../../assets/DayClinic_Financial.png")}
                            heading={"Day Clinic Visits"}
                            visits={monthlyStats.day_clinic_visits.total_visits_count}
                            onClick={() => handleSidebarOpen("Day Clinic Visits")}
                          />
                            </>
                            )}
                        </div>

                        {/* Monthly Breakdown Section */}
                        <div className='bg-light mt-4'>
                            <div className='p-3'>
                            <h2 className='d-flex ml-4 bold-color'>Monthly Breakdown</h2>
                            <div className='d-flex flex-column flex-lg-row justify-content-around align-items-center'>
                            {monthlyBreakdownSummary && (
                                <>
                                    <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                        <span className="d-flex">Invoice Value</span>
                                        <h1 className="d-flex text-darkblue">R {invoicesCreated}</h1> 
                                    </div>
                                    <span> <ValueChange value={monthlyDiff.invoices_diff} /></span>
                                    <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                        <span className="d-flex">Receipting Value</span>
                                        <h1 className="d-flex text-darkblue">R {moniesReceipted}</h1>
                                    </div>
                                    <span><ValueChange value={monthlyDiff.monies_diff} /></span>
                                    <div className="d-flex flex-column bg-white p-4 m-4 w-50 border-rounded">
                                        <span className="d-flex">Journals</span>
                                        <h1 className="d-flex text-darkblue">R {monthlyJournals}</h1>
                                    </div>
                                    <span><ValueChange value={monthlyDiff.journals_diff} /></span>
                                </>
                            )}
                            </div>
                            </div>
                        </div>

                        {/* Hospital Visits Sidebar */}
                        <SideNavbar
                          heading={selectedHeading}
                          state={hospitalVisitsOpen}
                          close={() => setHospitalVisitsOpen(false)}
                        >
                          <div className="container">
                            <div className="ml-4 mt-4">
                              {facilitiesData.length > 0 ? (
                                facilitiesData.map((facilityItem, index) => (
                                  <div key={index} className="d-flex flex-column align-items-start mb-3">
                                    <h5 className="d-flex bottomBorderBlack w-90">{facilityItem.facility}</h5> 
                                    <div className="d-flex flex-column align-items-start">
                                      <h2 className="text-start text-red">{facilityItem.visits_count}</h2> 
                                      <small className="text-start">Invoice:</small>
                                      <span className="text-start bold-color">R {facilityItem.invoiced}</span> 
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div>No facilities available</div> 
                              )}
                            </div>
                          </div>
                        </SideNavbar>

                        {/* Monthly Breakdown by Month Table */}
                        <div className="d-flex align-items-center mt-4">
                            <h4 className="bold-color">Monthly Breakdown by Month</h4>
                            <button onClick={handleExportBreakdownByMonth} className="download-btn">
                            <i className="fas fa-share-square"></i> <span>Export</span>
                            </button>
                        </div>

                        <div className='table-responsive'>
                            <table className="table table-bordered table-striped mt-4">
                            <thead className="thead-light">
                                <tr>
                                <th></th>
                                {/* {months.map((month, index) => (
                                    <th key={index}>{month.charAt(0).toUpperCase() + month.slice(1)}</th>
                                ))} */}
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(monthlyBreakdown) && monthlyBreakdown.length > 0 ? (
                                monthlyBreakdown.map((breakdown, idx) => (
                                    <tr key={idx}>
                                    <td>{breakdown.breakdown_type}</td>
                                    {months.map((month) => (
                                        <td key={month}>
                                        {breakdown.month_list[month] || 'N/A'}
                                        </td>
                                    ))}
                                    </tr>
                                ))
                                ) : (
                                <tr>
                                    <td colSpan={months.length + 1}>No data available</td>
                                </tr>
                                )}
                            </tbody>
                            </table>
                        </div>

                        {/* Top Monthly Referring Doctors */}
                        <div>
                        <div className="d-flex align-items-center mt-4">
                            <h4 className="bold-color">Top Monthly Referring Doctors</h4>
                            <button onClick={handleExportTopRefDoc} className="download-btn">
                            <i className="fas fa-share-square"></i> <span>Export</span>
                            </button>
                        </div>
                            <div>
                            <div className='d-flex flex-column flex-md-row justify-content-between w-100 mt-4'>
                                <EntriesDropdown entriesPerPage={entriesPerPage} onChange={handleEntriesChange} />
                                <div className="container">
                                <div className="row justify-content-end">
                                    <div className="col-12 col-md-6">
                                    <SearchInput
                                        value={searchTerm}
                                        onChange={setSearchTerm}
                                        placeholder="Search Referring Doctor"
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div>
                                <table className="table mt-3">
                                <thead>
                                    <tr>
                                    <th scope="col">Treating Doctor</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">
                                        <i className="fa fa-arrow-up position-relative pointer"></i> Referring Doctor
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentDocs.length > 0 ? (
                                    currentDocs.map((doc, idx) => (
                                        <tr key={idx}>
                                        <td>{doc.treating_doctor || 'N/A'}</td>
                                        <td>{doc.amount || 'N/A'}</td>
                                        <td>{doc.referring_doctor || 'N/A'}</td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="3">No data available</td>
                                    </tr>
                                    )}
                                </tbody>
                                </table>

                                {/* Pagination */}
                                <div className="d-flex justify-content-between align-start mt-4">
                                <div className="align-start">
                                    <span className="bold-color">
                                    Show {1 + (currentPage - 1) * entriesPerPage} -{' '}
                                    {Math.min(currentPage * entriesPerPage, filteredDocs.length)} of {filteredDocs.length} Entries
                                    </span>
                                </div>
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                </div>
                            </div>
                            </div>
                        </div>
                        </>
                    )}
                    </div>
                )}
                </div>

         </div>       
                </>
                 ) : (
                  <div>
                  <div className="d-flex mt-4 mb-2">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="position-relative">
                        <input
                          onChange={handleDateChange}
                          value={selectedDate}
                          className="absolute-element"
                          type="date"
                        />
                      </div>
                      <img
                        src={require("../../assets/DateTo.png")}
                        style={{ objectFit: "contain" }}
                        height={80}
                      />
                      <div className="d-flex flex-column justify-content-center">
                        <span>End Date:</span>
                        <span>{selectedDate}</span>
                      </div>
              
                      <div
                        className="daily-item text-white p-3 rounded ml-4"
                        onClick={handleViewAgeAnalysisClick}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="text-center">View</span>
                      </div>
                    </div>
                  </div>
              
                  {/* <Loader loading={loading} size="3x" color="#3E4E63" additionalClasses="my-4" /> */}
                  <Loader loading={loading} />
              
                  {dataReady && !loading && (
                    <>
                      <div className="bg-light mt-4">
                        <div className="p-4">
                          <h4 className="d-flex">Age Analysis</h4>
                          <span className="d-flex">
                            Age Analysis as at 08h30 for the current business date
                          </span>
                          <div className="row">
                            {dailyAnalysisData.map((item, idx) => (
                              <div
                                onClick={() => setSelectedDaily(idx)}
                                className={`${
                                  selectedDaily === idx
                                    ? "col-lg col-12 daily-item"
                                    : "col-lg col-12 white-custom"
                                } bottom-tabs rounded pointer m-1 border-0`}
                                key={idx}
                              >
                                <div>
                                  <span
                                    className={`${
                                      selectedDaily === idx ? "text-white" : "text-dark"
                                    }`}
                                  >
                                    {item.heading}
                                  </span>
                                </div>
                                <span
                                  className={`${
                                    selectedDaily === idx ? "text-white" : "text-darkblue bold-color"
                                  }`}
                                >
                                  {item.amount}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
              
                      <div className="mt-4">
                      <div className="d-flex align-items-center mt-4">
                            <h4 className="bold-color">Age Analysis By Status</h4>
                            <button onClick={handleExportAgeAnalysis} className="download-btn">
                            <i className="fas fa-share-square"></i> <span>Export</span>
                            </button>
                        </div>
                        <span className="d-flex">
                          Age Analysis by status as at 17h30 for the prior business date
                        </span>
                        {Object.keys(statusAnalysisData).length === 0 ? (
                          <div className="text-center">No data available</div>
                        ) : (
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Account Status</th>
                                {Object.keys(
                                  statusAnalysisData[Object.keys(statusAnalysisData)[0]]
                                ).map((key, index) => (
                                  <th key={index}>
                                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(statusAnalysisData).map((row, rowIndex) => {
                                const currentRowData = statusAnalysisData[row];
                                return (
                                  <tr key={rowIndex}>
                                    <td>{row}</td>
                                    {Object.keys(currentRowData).map((col, colIndex) => (
                                      <td key={colIndex}>
                                        {currentRowData[col] !== undefined
                                          ? currentRowData[col]
                                          : "0.00"}
                                      </td>
                                    ))}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                      </div>
              
                      <div className="mt-4">
                        <div className="d-flex justify-content-between bottomBorderGrayLight">
                          <div>
                            <div className="d-flex align-items-center mt-4">
                            <h4 className="bold-color">Age Analysis by Medical Aid</h4>
                            <button onClick={handleExportWithChart} className="download-btn">
                            <i className="fas fa-share-square"></i> <span>Export</span>
                            </button>
                        </div>
                            <span className="d-flex">
                              Age Analysis by status as at 17h30 for the prior business date
                            </span>
                          </div>
                          <div>
                          </div>
                        </div>
                        <Chart
                          chartType="PieChart"
                          data={chartData}
                          colors={chartColors}
                          width={"100%"}
                          height={"400px"}
                          ref={chartRef}
                        />
                        {Object.keys(medicalAidAnalysisData).length === 0 ? (
                          <div className="text-center">No data available</div>
                        ) : (
                          <table className="table">
                            <thead>
                              <tr>
                                {Object.keys(medicalAidAnalysisData[Object.keys(medicalAidAnalysisData)[0]]).map((key, index) => (
                                  <th key={index}>{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(medicalAidAnalysisData).map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                  {Object.keys(rowData).map((col, colIndex) => (
                                    <td key={colIndex}>
                                      {rowData[col] !== undefined ? rowData[col] : "0.00"} 
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </>
                  )}
                </div>
                 )}       
              </div>
                 {/* <MainFooter/> */}
            </div>
             )}             
    </Formik>
    </Sidebar>
  )
}

export default FinancialDashboard

