import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavigateBack = ({
    text
}) => {
const navigate = useNavigate()
    return (
    <div className="backbtnContainer bold-txt my-2">
    <button type='button' className="btn " onClick={() => navigate(-1)}>
      <i style={{ color: 'black' }} className="fa fa-arrow-left" aria-hidden="true"></i>
      {" "} {text}
    </button>
  </div>
  )
}

export default NavigateBack