import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import PropTypes from 'prop-types';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const MonthlyReports = ({ reports, ageAnalysis, ageAnalysisStatus, ledger }) => {
  console.log('Reports:', reports);
  console.log('Age Analysis:', ageAnalysis);
  console.log('Age Analysis Status:', ageAnalysisStatus);
  console.log('Ledger:', ledger);

  // Filter out reports that include 'Average' or 'Total' in month_list
  const filteredReports = reports.map(report => ({
    ...report,
    month_list: Object.fromEntries(
      Object.entries(report.month_list).filter(([key, value]) => !isNaN(value) && key !== 'Average' && key !== 'Total')
    )
  }));

  const months = filteredReports.length ? Object.keys(filteredReports[0].month_list) : [];

  const turnover = [];
  const receipting = [];

  reports.forEach(report => {
    if (report.breakdown_type === "Turnover") {
      turnover.push(Object.values(report.month_list).map(value => Math.abs(parseFloat(value)) || 0));
    }
    if (report.breakdown_type === "Receipting") {
      receipting.push(Object.values(report.month_list).map(value => Math.abs(parseFloat(value)) || 0));
    }
  });

  const flatTurnover = turnover.length ? turnover[0] : [];
  const flatReceipting = receipting.length ? receipting[0] : [];
  const minTurnover = Math.min(...flatTurnover);
  const interval = Math.floor(minTurnover / 100000) * 100000;

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Turnover vs Receipting (Absolute Values)',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Display original value in tooltip
            const originalValue = reports.find(r => 
              r.breakdown_type === tooltipItem.dataset.label
            )?.month_list[tooltipItem.label];
            return `${tooltipItem.dataset.label}: ${originalValue}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 2000000, 
          callback: function (value) {
            return `${value}`;  
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false, 
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  // Chart data setup with absolute values
  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Turnover',
        data: flatTurnover,
        backgroundColor: '#7999CC',
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 0.8,
      },
      {
        label: 'Receipting',
        data: flatReceipting,
        backgroundColor: '#7660A9',
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 0.8,
      },
    ],
  };

  return (
    <div className="table-responsive">
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Performance</h4>
       </div>
      <div className='table-responsive'>
          <table className="table table-bordered table-striped mt-4">
          <thead>
          <tr>
            <th>Type</th>
            {months.map((month) => (
              <th key={month}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => (
            <tr key={index}>
              <td>{report.breakdown_type}</td>
              {months.map((month) => (
                <td key={month}>{report.month_list[month]}</td>
              ))}
            </tr>
          ))}
        </tbody>
          </table>
      </div>
      <Bar data={chartData} options={chartOptions} />
      
      {/* Age Analysis Table */}
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Age Analysis</h4>
       </div>
       <div className='table-responsive'>
       <table className="table table-striped">
        <thead>
          <tr>
            <th>Age Range</th>
            <th>Total</th>
            <th>Current</th>
            <th>30 Days</th>
            <th>60 Days</th>
            <th>90 Days</th>
            <th>120 Days</th>
            <th>150 Days</th>
            <th>180+ Days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total</td>
            <td>{ageAnalysis.total}</td>
            <td>{ageAnalysis.current}</td>
            <td>{ageAnalysis.days_30}</td>
            <td>{ageAnalysis.days_60}</td>
            <td>{ageAnalysis.days_90}</td>
            <td>{ageAnalysis.days_120}</td>
            <td>{ageAnalysis.days_150}</td>
            <td>{ageAnalysis.days_180_plus}</td>
          </tr>
        </tbody>
      </table>

      {/* Age Analysis by Status Table */}
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Age Analysis by Status</h4>
       </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Status</th>
            <th>Total</th>
            <th>Current</th>
            <th>30 Days</th>
            <th>60 Days</th>
            <th>90 Days</th>
            <th>120 Days</th>
            <th>150 Days</th>
            <th>180+ Days</th>
          </tr>
        </thead>
        <tbody>
          {ageAnalysisStatus.map((status, index) => (
            <tr key={index}>
              <td>{status.account_status}</td>
              <td>{status.age_analysis.total}</td>
              <td>{status.age_analysis.current}</td>
              <td>{status.age_analysis.days_30}</td>
              <td>{status.age_analysis.days_60}</td>
              <td>{status.age_analysis.days_90}</td>
              <td>{status.age_analysis.days_120}</td>
              <td>{status.age_analysis.days_150}</td>
              <td>{status.age_analysis.days_180_plus}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
     

      {/* Ledger Table */}
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Ledger for the Month</h4>
       </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Total</th>
            <th>Running Total</th>
          </tr>
        </thead>
        <tbody>
          {ledger.map((entry, index) => (
            <tr key={index}>
              <td>{entry.date}</td>
              <td>{entry.description}</td>
              <td>{entry.debit}</td>
              <td>{entry.credit}</td>
              <td>{entry.total}</td>
              <td>{entry.running_total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

MonthlyReports.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      breakdown_type: PropTypes.string.isRequired,
      month_list: PropTypes.objectOf(PropTypes.number).isRequired, 
      turnover: PropTypes.number.isRequired, 
      receipting: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default MonthlyReports;
