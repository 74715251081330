import React from 'react'
import CustomScroll from '../../common/CustomScroll';
const SearchedCategories = ({searchedData,handleCategoryClick}) => {
  return (
    <CustomScroll smallerScroll={true} maxHeight={"50vh"}>
    <div>
      {searchedData.map((item, index) => {
        return (
          <div
            onClick={() =>
              handleCategoryClick([
                item.icd103Code_Desc,
                item.icd10Code,
                item.chapterDesc,
                item.groupDesc,
              ])
            }
            className={`d-flex justify-content-between rounded pointer ${
              index % 2 !== 0 ? "lightgray" : ""
            }`}
            key={index}
          >
            <span className="p-3">{item.whoFullDesc}</span>
            <span className="p-3">{item.icd10Code}</span>
          </div>
        );
      })}
    </div>
  </CustomScroll>
  )
}

export default SearchedCategories