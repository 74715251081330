import axios from "axios";
import PROD_API from "../baseEndpoint";
import JWT from "expo-jwt";
import { SHA256 } from "crypto-js";


const SignUpService = (email) => {
  let token = ''; // Initialize token
  let tokenAccountCreation = '';

  const generateToken = async () => {
    const userCredentials = { username: email };
    const secretKey = "xpedient_web_data4_secret_key_2023!@?";
  
    try {
      const newToken = await JWT.encode(userCredentials, secretKey, "HS256");
      // console.log("NEW TOKEN CREATED:", newToken);
      token = newToken; // Update token value
      return newToken;
    } catch (error) {
      // Handle error
      console.error("Error in generating token:", error);
      return null;
    }
  };

  const generateTokenAccountCreation = async (password) => {
    const userCredentials = { username: email, password: SHA256(password).toString() };
    const secretKey = "xpedient_web_data4_secret_key_2023!@?";
  
    try {
      const newToken = await JWT.encode(userCredentials, secretKey, "HS256");
      tokenAccountCreation = newToken; // Update token value for account creation
      return tokenAccountCreation;
    } catch (error) {
      // Handle error
      console.error("Error in generating token for account creation:", error);
      return null;
    }
  };

  const registerService = async (registerDetails) => {
    if (!token) {
      await generateToken();
    }

    const apiUrl = PROD_API + `register?accessToken=${token}`;

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(apiUrl, registerDetails, { headers });

      if (response.status === 200) {
        const { data, parm_extra, message, status } = response.data;
        
        return {
          data,
          parm_extra,
          message,
          status
        };
      } else {
        const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error during registration API call:', error);
      throw error;
    }
  };

  const accountCreationService = async (username, password, userDetails) => {
    if (!tokenAccountCreation) {
      await generateTokenAccountCreation(password); // Generate token for account creation
    }

    const apiUrl = PROD_API + `register?accessToken=${tokenAccountCreation}`;

    const headers = {
      'Content-Type': 'application/json',
    };

    const registerDetails = { username, password };
    try {
      const response = await axios.post(apiUrl, userDetails, { headers });
      if (response.status === 200) {
        const { data, parm_extra, message, status } = response.data;
        
        return {
          data,
          parm_extra,
          message,
          status
        };
      } else {
        const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error during account creation API call:', error);
      throw error;
    }
  };


  //###########--- GENERATE NEW TOKEN FOR RESET PASSWORD ------####//
  
 const generateTokenResetPassword = async (username, password, TempPassword = null) => {
  let userCredentials = { 
    username: username, 
    password: SHA256(password).toString(),
    TempPassword: TempPassword ? SHA256(TempPassword).toString() : null,
  };

  if (TempPassword) {
    userCredentials.TempPassword = SHA256(TempPassword).toString();
  }

  const secretKey = "xpedient_web_data4_secret_key_2023!@?";
  
  try {
    const newToken = await JWT.encode(userCredentials, secretKey, "HS256");
    tokenAccountCreation = newToken;
    return tokenAccountCreation;
  } catch (error) {
    console.error("Error in generating token for account creation:", error);
    return null;
  }
};
const handleResetPassword = async (username, password, TempPassword = null) => {
  await generateTokenResetPassword (username, password, TempPassword);
  const tokenPasswordReset = tokenAccountCreation;

  const apiUrl = `${PROD_API}password_reset?accessToken=${tokenPasswordReset}`;
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(apiUrl, {}, { headers });
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
      return { success: false, error: errorMessage };
    }
  } catch (error) {
    console.error('Error during API call:', error);
    return { success: false, error: error.message };
  }
};

  generateToken();

  return { registerService, accountCreationService, handleResetPassword  };
};

export default SignUpService;
