import React from 'react';
import '../../styles/components/MobileMessage.scss';


const MobileMessage = () => {
  return (
    <div className="mobile-message">
      <h2>Download Our Mobile App!</h2>
      <p>For a better experience, download the mobile version of our app!</p>
      <div className="button-container">
        <a 
          href="https://apps.apple.com/app/apple-store/6479686896" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="btn btn-primary"
        >
          Download on the App Store
        </a>
        <a 
          href="https://play.google.com/store/apps/details?id=co.za.xpedient" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="btn btn-success"
        >
          Get it on Google Play
        </a>
      </div>
    </div>
  );
};

export default MobileMessage;
