import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/InvoiceHistory.scss";
import Loader from "../../utils/Loader";

const InvoiceHistoryTable = ({
  dataGroupedByCaseID,
  loading,
  data,
  isQueryHistory,
  filterCombine,
  selectedOption,
  patientsearch = false,
}) => {
  const navigate = useNavigate();
  const [rotate, setRotate] = useState(null);
  const [rotated, setRotated] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);

  const renderAccordionDetails = (items) => {
    return (
      <div>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={`d-flex flex-column flex-md-row justify-content-between col-12 mt-3 pointer w-100 ${
                rotate === index && rotated && "bg-light"
              }`}
            >
              <div className="col-12 col-md-4 text-start">
                {item.InvoiceStatus === "Pending" && (
                  <img
                    width={20}
                    style={{ objectFit: "contain" }}
                    src={require("../../assets/icons/Pending Line-list Icon.png")}
                    alt="Pending"
                  />
                )}
                {item.InvoiceStatus === "Completed" && (
                  <img
                    width={20}
                    style={{ objectFit: "contain" }}
                    src={require("../../assets/icons/Green Tick.png")}
                    alt="Completed"
                  />
                )}
                <span>{item.PatientName}</span>
                <span className="mx-2"> | </span>
                <span className="fw-bold">{item.CaseID}</span>
              </div>

              <div className="col-12 col-md-4">
                {item.InvoiceStatus === "Pending" ? (
                  <span className="bold-txt red-txt">Pending</span>
                ) : (
                  <span className="bold-txt green-txt">Completed</span>
                )}
                <span className="mx-1"> | </span>
                <span>{item.InvoiceDate}</span>
              </div>

              <div className="col-12 col-md-1">
                {item.Adjustment && (
                  <img
                    style={{ objectFit: "contain" }}
                    width={20}
                    src={require("../../assets/icons/Edit Line Listed Icon.png")}
                    alt="Edit"
                  />
                )}
              </div>

              <div className="col-12 col-md-3">
                <button
                  onClick={() =>
                    navigate("/invoicehistoryview", {
                      state: { item: items },
                    })
                  }
                  className="btn btn-secondary w-100"
                >
                  View
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-4 border-bottom border-dark">
        <span className="bold">History</span>
        {patientsearch && <span className="bold">Service Date</span>}
        {patientsearch && (
          <div className="d-flex togglers pointer">
            <div
              onClick={() => filterCombine("A")}
              className={`${selectedOption === "A" ? "bubble" : "bubble-alt"}`}
            >
              <span className="text-white">A</span>
            </div>
            <div
              onClick={() => filterCombine("P")}
              className={`${selectedOption === "P" ? "bubble" : "bubble-alt"}`}
            >
              <span className="text-white">P</span>
            </div>
            <div
              onClick={() => filterCombine("C")}
              className={`${selectedOption === "C" ? "bubble" : "bubble-alt"}`}
            >
              <span className="text-white">C</span>
            </div>
          </div>
        )}
      </div>
      {loading ? (
          <Loader loading={loading}/>
      ) : data.length === 0 ? (
        <div>No invoice found</div>
      ) : (
        <div className="custom-scroll">
          {Object.keys(dataGroupedByCaseID).reverse().map((caseID, index) => {
            const items = dataGroupedByCaseID[caseID];
            return (
              <div key={index}>
                <div
                  className={`d-flex flex-column flex-md-row justify-content-between col-12 mt-3 pointer ${
                    rotate === index && rotated && "bg-light"
                  }`}
                >
                  <div className="d-flex col-12 col-md-4">
                    {items.some((item) => item.InvoiceStatus === "Pending") && (
                      <img
                        width={20}
                        className="mx-1"
                        style={{ objectFit: "contain" }}
                        src={require("../../assets/icons/Pending Line-list Icon.png")}
                        alt="Pending"
                      />
                    )}
                    {items.every(
                      (item) => item.InvoiceStatus === "Completed"
                    ) && (
                      <img
                        width={20}
                        className="mx-1"
                        style={{ objectFit: "contain" }}
                        src={require("../../assets/icons/Green Tick.png")}
                        alt="Completed"
                      />
                    )}
                    {/* Patient Name and Case ID */}
                    <span>
                      {isQueryHistory ? "Ticket Ref No" : items[0].PatientName}
                    </span>
                    <span className="mx-2"> | </span>
                    <span className="fw-bold"> {caseID}</span>
                  </div>

                  {/* Middle Section: Case Status and Date */}
                  <div className="d-flex col-12 col-md-4 justify-content-center">
                    {items.some((item) => item.InvoiceStatus === "Pending") ? (
                      <>
                        <span className="bold-txt red-txt">Pending</span>
                        <span className="mx-2"> | </span>
                      </>
                    ) : (
                      <>
                        <span className="bold-txt green-txt">Completed</span>
                        <span className="mx-2"> | </span>
                      </>
                    )}
                    <span>{items[0].InvoiceDate}</span>
                  </div>

                  {/* Right Section: Icon */}
                  <div className="col-0 col-md-1"></div>
                  <div
                    onClick={() => {
                      if (index !== rotate) {
                        setRotate(index);
                        setRotated(true);
                        setExpandedItem(
                          expandedItem === caseID ? null : caseID
                        );
                      } else {
                        setRotated(!rotated);
                      }
                    }}
                    className="d-flex col-12 col-md-3 justify-content-center align-items-center"
                  >
                    <img
                      width={30}
                      className={`arrow pointer ${
                        rotate === index
                          ? rotated
                            ? ""
                            : "arrow-rotate"
                          : "arrow-rotate "
                      }`}
                      src={require("../../assets/icons/Down Arrow Icon.png")}
                      alt="Toggle"
                    />
                  </div>
                </div>

                {expandedItem === caseID && rotated && (
                  <div className="w-100">{renderAccordionDetails(items)}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InvoiceHistoryTable;
