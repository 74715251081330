import axios from 'axios';
import PROD_API from "../baseEndpoint";

const ForgotPasswordService = (token) => {
  const handleForgotPassword = async (useremail) => {
    const apiUrl = `${PROD_API}forgot_password?useremail=${useremail}`;

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(apiUrl, {}, { headers });
      if (response.status === 200) {
        return { success: true, data: response.data };
      } else {
        const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
        return { success: false, error: errorMessage };
      }
    } catch (error) {
      console.error('Error during API call:', error);
      return { success: false, error: error.message };
    }
  };

  

  return { handleForgotPassword};
};

export default ForgotPasswordService;
