import axios from "axios";
import PROD_API from "./baseEndpoint.js";


const PatientIDService = (token) => {
  const fetchPatientData = async (search) => {
    try {
        const apiUrl =
        PROD_API + `GoodX/get_debtor?accessToken=${token}&search=${search}`;
        const response = await axios.post(apiUrl);
      
      
      if (response.status !== 200) {
        console.error("API error with non-200 status:", response.statusText);
        return { success: false, error: response.statusText };
      }

      const data = response.data;

      if (data.data && data.data.length > 0) {
        let allPatients = [];

        data.data.forEach((item, index) => {
          item.patients.forEach((patient, patientIndex) => {
            const patientData = {
              id: patient.patient_idnumber,
              patient_email: patient.patient_email,
              name: patient.patient_name,
              surname: patient.patient_surname,
              dob: patient.patient_dateofbirth,
              address: patient.patient_address1,
              contact_number: patient.patient_cellnr,
              nextofkin: item.debtor.nextofkin_name,
              nextofkin_tel: item.debtor.nextofkin_telnr,
              medical_aid_number: item.debtor.debtor_ma_number,
              medical_aid_plan: item.debtor.debtor_ma_optionname,
              medical_aid_option: item.debtor.debtor_ma_optioncode,
              debtor_dbid: patient.debtor_dbid,
              patient_dbid: patient.patient_dbid,
              debtor_ma_schemename: item.debtor.debtor_ma_schemename,
              debtor_number: item.debtor.debtor_number
            };
            allPatients.push(patientData);
          });
        });


        return { success: true, patients: allPatients, debtor: data.data.map(item => item.debtor) };
      } else {
        return { success: false, error: data.parm_extra };
      }
    } catch (error) {
      console.error("Error in fetching patient data:", error);
      return { success: false, error: error.message };
    }
  };

  return { fetchPatientData };
};

export default PatientIDService;