import React from 'react'
import "../../styles/components/FinancialDashboard.scss"

function GenericFinanceTab({invoicesCreated, moniesReceipted, journals,  heading, subHeading, averages}) {
  
  return (
            <div className='bg-light w-100 mt-4 rounded border-0'>
            <div className='m-4'>
            <h4 className='text-start bold-color'>{heading}</h4>     
            {subHeading && (
            <small className='d-flex'>{subHeading}</small>
        )}
        <div className='row col-12 col-lg ' >
        <div className='col-12 col-lg bg-white m-2 p-4'>
        <span>Invoice Value</span>
        <h3 className={ averages ? 'text-red' :  'text-darkblue'}>R {invoicesCreated}</h3>
        </div>

        <div className=' col-12 col-lg bg-white m-2 p-4'>
        <span>Receipting Value</span>
        <h3 className={ averages ? 'text-red' :  'text-darkblue'}>R {moniesReceipted}</h3>
        </div>

        <div className=' col-12 col-lg bg-white m-2 p-4'>
        <span>Journals</span>
        <h3 className={ averages ? 'text-red' :  'text-darkblue'}>R {journals}</h3>
        </div>
        </div>
            </div>
        </div>
  )
}

export default GenericFinanceTab