import React, { useState } from "react";
import ContentModal from "./ContentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
const KeyAccountManagerModal = ({ visible,changeVisible }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  return (
    <ContentModal
      header={"Your Key Account Manager:"}
      visible={visible}
      buttonText="Submit"
      closeFunc={()=>changeVisible(false)}
    >
      <div>
        <div className="d-flex flex-column">
          <span>Morne Marais</span>
          <span className="bold">066 123 4567</span>
          <div className="d-flex justify-content-center align-items-center">
            <img
              width={100}
              style={{ borderRadius: "50%" }}
              alt=""
              src={require("../../assets/ClientPortal/clientPortalKeyAccountManager.png")}
            />
          </div>
          <div className="d-flex flex-column">
            <span className="bold">More Info</span>
            <small className="mb-4">
              Please provide a brief description of the assistance you require.
            </small>
            <textarea style={{ minHeight: 150 }} className="border" />
          </div>
          <div className="mt-3">
            <span className="bold">Select a Suitable Date & Time</span>

            <div className="d-flex justify-content-around mt-4">
              <div className=" d-flex">
                <div className=" position-relative">
                  <input
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="calender-container"
                    //   value={values.fromDate}
                    style={{ width: 26 }}
                    type="date"
                  />
                </div>
                <img
                  src={require("../../assets/DateTo.png")}
                  style={{ objectFit: "contain" }}
                  height={50}
                  alt=""
                />
                <div className="flex-col">
                  <span className="mg">Select Date:</span>
                  <span>{selectedDate}</span>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center p-2 rounded position-relative profileColor pointer">
                <span>{selectedTime ? selectedTime : "8:00am"}</span>
                <div className="d-flex flex-column ml-2">
                  <FontAwesomeIcon color="white" icon={faChevronUp} />
                  <FontAwesomeIcon color="white" icon={faChevronDown} />
                </div>
                <input
                  onChange={(e) => setSelectedTime(e.target.value)}
                  className="position-absolute"
                  style={{ opacity: 0 }}
                  type="time"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentModal>
  );
};

export default KeyAccountManagerModal;
