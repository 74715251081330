import React from 'react'

const EnlargeItem = ({openFunction,width}) => {
  return (
    <div className="col-2 d-flex justify-content-center align-items-center">
    <img
      className="pointer"
      onClick={openFunction}
      style={{
        objectFit: "contain",
        width,
      }}
      src={require("../assets/notesimg.png")}
      alt="Notes"
    />
  </div>
  )
}

export default EnlargeItem