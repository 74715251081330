import React from "react";
import CustomScroll from "./common/CustomScroll";
import EnlargeItem from "./EnlargeItem";
const SubTask = ({ selectedSubtask }) => {
  return (
    <>
      {selectedSubtask.instructions.length > 0 && (
        <div className="d-flex justify-content-between gray p-2 rounded col-9">
          <CustomScroll maxHeight="10vh" smallerScroll={true}>
            <small>
              {selectedSubtask.instructions.length > 50
                ? `${selectedSubtask.instructions.slice(0, 50)}...`
                : selectedSubtask.instructions}
            </small>
          </CustomScroll>
          <EnlargeItem openFunction={() => {}} width={20} />
        </div>
      )}
    </>
  );
};

export default SubTask;
