import axios from "axios";
import PROD_API from "../baseEndpoint";
import { InternalSymbolName } from "typescript";

const SubTaskQueriesCommentService = (token) => {
  const fetchSubTaskQueriesComment = async (taskId) => {
    // TASKTYPE= 0 => Give everything
    // TASKTYPE = 1 => Internal, Irrelevant for the app
    // TASKTYPE = 2 => All external task, Relevant for the app, Open queries. External subtask not resolved
    // TASKTYPE = 3 => Queries Resolved or completed
    try {
      const apiUrl = `${PROD_API}get_sub_task_comments?accessToken=${token}&taskId=${taskId}&tasktype=2`;  


      const response = await axios.post(apiUrl); 
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        console.error("Failed to retrieve queries details. Status code:", response.status);
        return [];
      }
    } catch (error) {
      console.error("Error fetching queries details:", error);
      return [];
    }
  };
  
  return { fetchSubTaskQueriesComment };
};

export default SubTaskQueriesCommentService;
