import React from 'react'
import { Field } from 'formik'
import useServiceCenterCodesStore from '../state/ServiceCenterCodeStore';
const AuthorizationNumber = () => {
    const {
        setAuthorizationNumber,
        authorizationNumber,
      } = useServiceCenterCodesStore();
  return (
    <div className="col-12 col-lg text-font">
    <label htmlFor="authorizationNumber">Authorization Number</label>
    <Field
      type="text"
      name="authorizationNumber"
      className="form-control"
      value={authorizationNumber}
      onChange={(e) => setAuthorizationNumber(e.target.value)}
      id="authorizationNumber"
    />
  </div>
  )
}

export default AuthorizationNumber