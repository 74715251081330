import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import Sidebar from "../../Sidebar/Sidebar";
import MonthlyReports from "./MonthlyReports";
import "jspdf-autotable";
import "../../../styles/components/BatchBilling.scss";
import NavigateBack from "../../NavigateBack";

const ReportDetailsPage = () => {
  
    const location = useLocation();
    const { reports, reportType, month, year, ageAnalysis, ageAnalysisStatus, ledger } = location.state;
  
  return (
    <div>
      <Sidebar heading={<h1 className="heading">{reportType}</h1>}>
        <div className='container mt-4'>
      <NavigateBack text={"Monthly Report"}/>
      <div className="row border-bottom border-black mb-3">
             <div>
            <MonthlyReports
                reports={reports}
                ageAnalysis={ageAnalysis}
                ageAnalysisStatus={ageAnalysisStatus}
                ledger={ledger}
            />
            </div>
      </div>

        </div>
      </Sidebar>
    </div>
  );
};

export default ReportDetailsPage;