import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../services/authentication/LoginService";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useCallback } from "react";
import ICD10Service from "../../services/Billing/ICD10Service";
import NavigateBack from "../NavigateBack";
function CodesPageFromChapters() {
  const { token } = useAuth();
  const [filteredICD10DataGroups, setFilteredICD10DataGroups] = useState([]);
  const icd10Service = ICD10Service(token);
  const [allICD10Data, setAllICD10Data] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [copiedFilteredData, setCopiedFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [SearchTermICD10Groups, setSearchTermICD10Groups] = useState("");
  const [combinedFilteredData, setCombinedFilteredData] = useState([]);
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleCodeClick = (item) => {
    navigate("/morecategories", { state: { item } });
  };

  const searchCodes = () => {
    const codeFilter = filteredICD10DataGroups.filter((each) =>
      each.icd10Code.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredData =
      codeFilter.length > 0
        ? codeFilter
        : filteredICD10DataGroups.filter((item) =>
            item.icd103Code_Desc
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
    if (filteredData.length === 0 || searchText === "") {
      setSearchTermICD10Groups("");
      setFilteredICD10DataGroups(copiedFilteredData);

      return;
    }
    setFilteredICD10DataGroups(filteredData);
  };

  useEffect(() => {
    setCombinedFilteredData(filteredICD10DataGroups);
  }, [filteredICD10DataGroups]);

  const handleSearch = useCallback(
    debounce(() => {
      searchCodes();
    }, 10),
    [searchText]
  ); // Adjust the delay as neededq
  useEffect(() => {
    handleSearch();
  }, [searchText]);
  const items = location?.state?.items;
  function generateValues(inputStr) {
    const prefix = inputStr.slice(0, -1);
    const numStr = inputStr.slice(-1);

    const num = parseInt(numStr, 10);

    const result = Array.from({ length: 10 }, (_, i) =>
      i === 0 ? `${prefix}${numStr}` : `${prefix}${numStr}.${i - 1}`
    );
    return result;
  }

  useEffect(() => {
    const fetchICD10Codes = async () => {
      setLoading(true);
      try {
        const data = await icd10Service.ICD10Data();

        if (data) {
          setAllICD10Data(data);
          if (SearchTermICD10Groups === "") {
            let result = generateValues(items[0]);

            const filteredDataGroups = data.filter((item) => {
              return result.includes(item.icd10Code);
            });

            setFilteredICD10DataGroups(filteredDataGroups);
            setCopiedFilteredData(filteredDataGroups);
          }
        } else {
          setAllICD10Data([]);
          //   setFilteredICD10Data([]);
          setErrorMessage("Failed to fetch data");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch ICD-10 codes");
      } finally {
        setLoading(false);
      }
    };

    fetchICD10Codes();
  }, []);

  useEffect(() => {
    setCombinedFilteredData(filteredICD10DataGroups);
  }, [filteredICD10DataGroups]);

  return (
    <Sidebar
      heading={<h1 className="heading">ICD10 & PROCEDURE CODE SEARCH</h1>}
    >
      <div className="container mt-4">
        <NavigateBack text={"CODES"} />
        <div className="mt-4">
          <div className="lightColor centered-items mt-2">
            <div className="position-relative">
              <input
                onChange={(event) => {
  
                  const text = event.target.value;
                  setSearchText(text);
                }}
                type="text"
                className="border border-light lightColor input-custom"
                placeholder="Search"
                aria-label="Username"
              />
              <i
                onClick={() => searchCodes()}
                //    onClick={() => searchPatientInfo(values.patientID)}
                className="fa fa-search absolute-pos pointer"
              />
            </div>
          </div>
        </div>
        {!loading && combinedFilteredData.length > 0 && (
          <div>
            {combinedFilteredData.map((item, index) => {
              return (
                <div
                  className={`d-flex justify-content-between align-left rounded col-12 pointer ${
                    index % 2 !== 0 ? "lightgray" : ""
                  }`}
                  key={index}
                  onClick={() =>
                    handleCodeClick([
                      item.icd103Code_Desc,
                      item.icd10Code,
                      item.chapterDesc,
                      item.groupDesc,
                    ])
                  }
                >
                  <span className="p-3 col-4 ">{item.whoFullDesc}</span>
                  <span className="p-3 col-4 "> {item.icd10Code}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default CodesPageFromChapters;
