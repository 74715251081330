import axios from "axios";
import PROD_API from "../baseEndpoint";

const dailyStatisticService = (token) => {
  const fetchDailyStatistics = async () => { 
    try {
      const apiUrl = `${PROD_API}OPS/reports/get_stats_daily?accessToken=${token}`;

      const response = await axios.post(apiUrl);

      if (response.status === 200 && response.data) {
        return response.data; 
      } else {
        console.error("Failed to retrieve daily statistics. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching daily statistics:", error);
    }
  };
  
  return { 
    fetchDailyStatistics 
  };
};

export default dailyStatisticService;
