import React from 'react'

const FromToDate = ({
     changeDateTo,
        values,
        changeDateFrom
}) => {
  return (
    <div className='d-flex justify-content-center align-items-center '>
    <div className=' d-flex'>
      <div className=' position-relative'>
        <input
          onChange={changeDateFrom}
          className='calender-container'
          value={values.fromDate}
          style={{ width: 26 }}
          type='date'
        />
      </div>
      <img src={require("../assets/DateTo.png")} style={{ objectFit: "contain" }} height={50} />
      <div className='flex-col'>
        <span className='mg'>From Date:</span>
        <span>{values.fromDate}</span>
      </div>
    </div>
    {/* Vertical Line */}
    <div className='vertical-line-date' ></div>
    <div className='d-flex'>
      <div className='position-relative'>
        <input
          onChange={changeDateTo}
          className='calender-container '
          value={values.toDate}
          style={{ width: 26 }} type='date'
        />
      </div>
      <img src={require("../assets/DateTo.png")} className='marginright' style={{ objectFit: "contain" }} height={50} />
      <div className='flex-col'>
        <span className='mg'>To Date:</span>
        <span>{values.toDate}</span>
      </div>
    </div>
  </div>
  )
}

export default FromToDate