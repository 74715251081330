import React, { useState, useEffect, useContext } from "react";
import "../../styles/components/Sidebar.scss";
import { useNavigate } from "react-router-dom";
import LogoutContainer from "./LogoutContainer";
import { useAuth } from "../../services/authentication/LoginService";
import MainFooter from "../common/MainFooter";
import { UserContext } from "../../services/authentication/UserContext";
import SidebarContainer from "./SidebarContainer";


function Sidebar({
  children,
  changeBackground = false,
  heading = "",
  subTaskQueryCount,
}) {
  const { subTaskCount } = useAuth();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [showModal, setShowModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const { username, entityDoctorName } = useContext(UserContext);
  const { username, entityDoctorName } = useContext(UserContext); 

  const getTrimmedUsername = (username) => {
    if (!username) return "";

    const nameParts = username.split(" ");
    if (nameParts.length < 2) return username;

    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  };
  
  const trimmedUsername = getTrimmedUsername(username);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div className="d-flex full-width">
        <div
          className={
            expanded
              ? windowDimensions.width > 900
                ? "expand"
                : "random"
              : "wrapper"
          }
        >
          <div className={expanded ? "main-expanded" : "main"}>
            <div className="flexrow">
              <div>
                <div
                  className="mt-2 pointer"
                  onClick={() => setExpanded(!expanded)}
                >
                  <div className="bg-danger bars">
                    <span className="dot">.</span>
                  </div>
                  <div className="bg-white bars">
                    <span className="dot">.</span>
                  </div>
                  <div className="bg-secondary bars">
                    <span className="dot">.</span>
                  </div>
                </div>
              </div>
              {expanded && (
                <img
                  className="xpimg"
                  src={require("../../assets/XPLOGO.png")}
                  width={100}
                  style={{ objectFit: "contain" }}
                  alt="Logo"
                />
              )}
            </div>
            <SidebarContainer
            expanded={expanded}
            subTaskCount={subTaskCount}
            />
          </div>
        </div>

        <div className={`full-width ${changeBackground && "modal-overlay"}`}>
          <div className="top-bar">
            <div
              className="pointer mt-3 show-on-small"
              onClick={() => setExpanded(!expanded)}
            >
              <div className="bg-danger bars">
                <span className="dot">.</span>
              </div>
              <div className="bg-dark bars">
                <span className="dot">.</span>
              </div>
              <div className="bg-secondary bars">
                <span className="dot">.</span>
              </div>
            </div>

            {heading && (
              <div className="d-flex align-items-center ml-1 w-100">
                <small className="text-center font-weight-bold">{heading}</small>
              </div>
            )}

            {showModal && <LogoutContainer />}

            <div className="initials">
              <div className="d-flex align-items-center">
                <div className="initials-text">
                  <small className="t-text bold-color">{username}</small>
                  <small className="t-text">-{entityDoctorName}</small>
                </div>
                <div className="profile pointer">
                  <small className="text-white t-text">{trimmedUsername}</small>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => setShowModal(!showModal)}
                  className="pointer d-flex flex-column align-items-center dropdown-menu-bg"
                >
                  <span className="dropdown-ellipses"></span>
                  <span className="dropdown-ellipses"></span>
                  <span className="dropdown-ellipses"></span>
                </div>
              </div>
            </div>
          </div>

          {children}
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
