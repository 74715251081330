import React from 'react'

function FavouritesContent({
expandedItems,
itemKey,
codes,
title,
dataAdded

}) {
  return (
    <div className='d-flex flex-column justify-content-start align-items-start '>
    <span className='bold-txt'>{title}</span>
    {expandedItems[itemKey] && codes[itemKey] && Object.keys(codes[itemKey]).length > 0 ? (
        Object.keys(codes[itemKey]).map((item, index) => (         
            <div  key={index}>
                <span>{item}</span>
           
           
            </div>
        ))
    )  : (
        <span>-</span>
    )}
</div>
  )
}

export default FavouritesContent
