import React, { useState } from "react";
import { ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "../../styles/components/invoice.scss";
import SideNavbar from "../common/SideNavbar";
import useServiceDateStore from "../state/ServiceDateStore";

const ServiceDateField = ({
  values,
  setFieldValue,
  setIsSidebarOpenAddFavourites = () => {},
}) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { setDateToDisplay, dateToDisplay } = useServiceDateStore();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setCalendarOpen(true);
    setIsSidebarOpenAddFavourites(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpenAddFavourites(false);
    setIsSidebarOpen(false);
    setCalendarOpen(false);
  };

  const saveDate = (setFieldValue) => {
    closeSidebar();
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${String(
          selectedDate.getMonth() + 1
        ).padStart(2, "0")}-${String(selectedDate.getDate()).padStart(2, "0")}`
      : "";

    setDateToDisplay(formattedDate);
    setFieldValue("serviceDate", formattedDate);
  };
  return (
    <div className="col-12 col-lg text-font">
      <label htmlFor="serviceDate">
        Service Date<span className="error-text">*</span>
      </label>

      <div className="position-relative">
        <input
          type="text"
          name="serviceDate"
          className="form-control bg-field text-font"
          id="serviceDate"
          onClick={toggleSidebar}
          value={dateToDisplay}
          readOnly
          placeholder="Select a Service Date"
        />

        <img
          src={require("../../assets/icons/Calendar Icon.png")}
          alt="Calendar Icon"
          className="calendar-icon"
        />
      </div>

      <ErrorMessage
        name="serviceDate"
        component="div"
        className="error-text d-flex"
      />

      <SideNavbar
        proceed={() => saveDate(setFieldValue)}
        close={closeSidebar}
        state={isSidebarOpen}
        heading={"Service Date"}
        headerContent={<DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          open={calendarOpen}
          showDisabledMonthNavigation={true}
          maxDate={new Date()} // Disables future dates
          onInputClick={() => setCalendarOpen(true)}
        />}
      >
        
      </SideNavbar>
    </div>
  );
};

export default ServiceDateField;
