import React, { useState } from "react";
import RowItem from "./RowItem";
import ContentModal from "./ContentModal";
import { Formik } from "formik";
import GenericInput from "../GenericInput";
import ContactDetailsModal from "./ContactDetailsModal";
import KeyAccountManagerModal from "./KeyAccountManagerModal";
const Contact = () => {
  const [contactDetailsModal, setContactDetailsModal] = useState(false);
  const [selectedDepartmnet, setSelectedDepartmnet] = useState("");
  const [keyAccountCall, setKeyAccountCall] = useState(false);
  const [submitDocument, setSubmitDocument] = useState(false);
  const [confirmContactDetails, setConfirmContactDetails] = useState(false);
  const [keyAccountManager, setKeyAccountManager] = useState(false);
  const closeModal = () => {
    setContactDetailsModal(false);
    setConfirmContactDetails(true);
  };
  const closeModalKeyAccount = () => {
    setKeyAccountCall(false);
    setKeyAccountManager(true);
  };
  return (
    <div className="col-md-6">
      <RowItem
        imgPath={require("../../assets/ClientPortal/clientPortalChat.png")}
        heading={"Submit Support Ticket:"}
        subHeading={"Detail your support needs"}
        // clickFunc={() => setContactDetailsModal(true)}
      />
      <RowItem
        imgPath={require("../../assets/ClientPortal/clientPortalPhoneIncoming.png")}
        heading={"Arrange a Call:"}
        subHeading={"Schedule a call with your Key Account Manager"}
        // clickFunc={() => setKeyAccountCall(true)}
      />
      <RowItem
        imgPath={require("../../assets/ClientPortal/clientPortalDownload.png")}
        heading={"Submit Document"}
        subHeading={"Send a document to Xpedient"}
        // clickFunc={() => setSubmitDocument(true)}
      />
      <RowItem
        imgPath={require("../../assets/ClientPortal/clientPortalPhoneOutgoing.png")}
        heading={"Contact Us: 0861 973 343"}
        subHeading={"Weekdays: Monday-Friday | 8:00 - 16:30pm"}
        chevron={false}
        disabled = {false}
      />

      <ContactDetailsModal
        closeModal={closeModal}
        visibleValue={contactDetailsModal}
      />
      <ContactDetailsModal
        closeModal={closeModalKeyAccount}
        visibleValue={keyAccountCall}
      />
      <KeyAccountManagerModal
        changeVisible={setKeyAccountManager}
        visible={keyAccountManager}
      />
      <ContentModal
        header={"What is the support ticket related to?"}
        visible={confirmContactDetails}
        buttonText="Submit"
        closeFunc={() => setConfirmContactDetails(false)}
      >
        <label>Select Department</label>
        <select
          onChange={(e) => setSelectedDepartmnet(e.target.value)}
          className="mb-4 border-0 bg-light p-2 rounded"
        >
          <option>Coding</option>
          <option>Accounts</option>
          <option>Sales</option>
          <option>IT Department</option>
          <option>Training</option>
          <option>Customer Service</option>
          <option>Marketing</option>
        </select>
        {selectedDepartmnet === "Accounts" && (
          <Formik>
            <div className="mt-1 mb-4">
              <GenericInput
                description={"Please specify Account Numbers"}
                type="text"
                name={"accountNumbers"}
              />
            </div>
          </Formik>
        )}
        <div>
          <span className="bold">More Info</span>
          <div className="d-flex flex-column">
            <small className="mb-2">
              Please provide a brief description of the assistance you require:
            </small>
            <textarea style={{ minHeight: 150 }} className="rounded" />
          </div>
        </div>
      </ContentModal>

      <ContentModal
        header={"Submit Document"}
        visible={submitDocument}
        buttonText="Submit"
        closeFunc={() => setSubmitDocument(false)}
      >
        <select
          // onChange={(e) => setSelectedDepartmnet(e.target.value)}
          className="mb-4 border-0 bg-light p-2 rounded"
        >
          <option>Sign Up/Setup</option>
          <option>Bank Viewing Rights</option>
          <option>BHF</option>
          <option>Payment Arrangement/s</option>
          <option>Finance/Accounting</option>
          <option>Training</option>
          <option className="text-danger">Supporting Documents ???</option>
        </select>
        <div className="d-flex flex-column">
        <span className="bold">More Info</span>
        <textarea style={{ minHeight: 150 }} />
        </div>
      </ContentModal>
    </div>
  );
};

export default Contact;
