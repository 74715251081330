import axios from "axios";
import PROD_API from "../baseEndpoint";

const InvoiceHistoryService = (token) => {
    const fetchInvoiceHistory = async (startDate, endDate, searchparm = "") => {
        try {
            const taskType = 0;
            const apiUrl = PROD_API + `get_invoice_history?accessToken=${token}&startDate=${startDate}&endDate=${endDate}&searchparm=${searchparm}`;
            const response = await axios.post(apiUrl);
            if (response.status === 200 && response.data) {
                const sortedInvoices = response.data.Invoices.sort((a, b) => {
                    return parseInt(b.CaseID) - parseInt(a.CaseID);
                });

                return {
                    invoices: sortedInvoices,
                    parm_extra: response.data.parm_extra || ""
                };
            } else {
                console.error("Failed to retrieve invoice details. Status code:", response.status);
                return { invoices: [], parm_extra: [] };
            }
        } catch (error) {
            console.error("Error fetching invoice details:", error);
            return { invoices: [], parm_extra: [] };
        }
    };

    return {
        fetchInvoiceHistory
    };
};

export default InvoiceHistoryService;
