import axios from 'axios';
import PROD_API from "../baseEndpoint";

const ProcessBatchService = (token) => {
  const ProcessBatchData = async (batchDetails) => {
    const apiUrl = PROD_API + `BatchBilling/process_batches?accessToken=${token}`;
    // const apiUrl = PROD_API + `GoodX/process_goodx_billing_request?accessToken=${token}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(apiUrl, batchDetails, { headers });
      if (response.status === 200) {
      const { data, parm_extra, status, message } = response.data;
      return {
        data,
        status,
        parm_extra,
        message,
      };
    } else {
      const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
      throw new Error(errorMessage);
    }

    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  };

  return {ProcessBatchData};
};

export default ProcessBatchService;
