// useProcedureCodesStore.js
import { create } from "zustand";

const useNappiCodeStore = create((set) => ({
    nappiCodeFlatListVisible: false,
    setNappiCodeFlatListVisible: (nappiCodeFlatListVisible) => set({ nappiCodeFlatListVisible }),


}));

export default useNappiCodeStore;
