import { Formik } from "formik";
import React from "react";
import { Form } from "react-router-dom";
import GenericInput from "../GenericInput";

const RateSetup = () => {
  const rateSetup = [
    {
      description: "In-Rooms Medical Aid Patients",
      name: "irMedicalAidPatients",
    },
    {
      description: "In-Hospital Medical Aid Patients",
      name: "ihMedicalAidPatients",
    },
    {
      description: "In-Rooms Private Patients",
      name: "irPrivatePatients",
    },
    {
      description: "In-Hospital Private Patients",
      name: "ihPrivatePatients",
    },
    {
      description: "In-Room Fixed Rates & Combos",
      name: "irRatesandCombos",
    },
    {
      description: "In-Hospital Fixed Rates & Combos",
      name: "ihFixedRatesCombos",
    },
    {
      description: "International Patients Rooms Notes",
      name: "ipatientsRoomsNotes",
    },
    {
      description: "International Patients Hospital Notes",
      name: "ipatientsHospitalNotes",
    },
    {
      description: "In-Rooms Billing Policy",
      name: "irBillingPolicy",
    },
    {
      description: "In-Hospital Billing Policy",
      name: "ihBillingPolicy",
    },
    {
      description: "In-Rooms Notes",
      name: "irNotes",
    },
  ];
  return (
    <Formik>
      <div className="container">
        <div className="row">
          {rateSetup.map((policy, index) => (
            policy?.other ? (
              // If `other` is true, display a full-width `textarea` and start a new row after it
              <React.Fragment key={index}>
                <div className="col-md-8 mb-3">
                  <label className="form-label ">{policy.description}</label>
                  <textarea  className="form-control "  name={policy.name} />
                </div>
              </React.Fragment>
            ) : (
              // Otherwise, display the GenericInput in a 4-column layout
              <div key={index} className="col-md-4 mb-3">
                <GenericInput
                  description={policy.description}
                  onChange={() => {}}
                  type="text"
                  name={policy.name}
                />
              </div>
            )
          ))}
        </div>
      </div>
    </Formik>
  );
};

export default RateSetup;
