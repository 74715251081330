import axios from "axios";
import PROD_API from "../baseEndpoint";


const SavedReportsService = (token) => {
  const getSavedReports = async () => { 
    try {

      const apiUrl = PROD_API + `OPS/reports/get_saved_reports?accessToken=${token}`;

      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {

        return response.data
      } else {
        console.error("Failed to retrieve details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  
  return { 
    getSavedReports
   };
};

export default SavedReportsService;
