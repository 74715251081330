import React from "react";
import { Field } from "formik";
const GenericInput = ({
  description,
  onChange,
  type,
  name,
  readOnlyInput = false,
  required = true,
}) => {
  return (
    <div>
      <label htmlFor={description}>
        {description} {required && <small className="text-danger">*</small>}
      </label>
      <Field
        disabled={readOnlyInput}
        type={type}
        name={name}
        className="form-control bg-field py-4"
        id={name}
        onChange={onChange}
      />
    </div>
  );
};

export default GenericInput;
