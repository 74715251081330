import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import TabSwitch from "./TabSwitch";
import RatesAndPoliciesPage from "./RatesAndPoliciesPage";
import Reports from "./Reports/Reports";
import NewsFeed from "./NewsFeed";
import GuidanceAndResource from "./GuidanceAndResource";

const RatesAndPolicies = () => {
  const [activeTab, setActiveTab] = useState("rp");

  return (
    <Sidebar>
      <div>
        {/* Pass the activeTab and setActiveTab props to TabSwitch */}
        <TabSwitch activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className=" mt-4">
          {/* Conditionally render components based on the active tab */}
          {activeTab === "rp" ? (
            <RatesAndPoliciesPage />
          ) : activeTab === "r" ? (
            <Reports />
          ) : activeTab === "nf" ? (
           <NewsFeed/>
          ) : (
            <GuidanceAndResource/>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default RatesAndPolicies;
