import React from 'react';

const TabSwitch = ({ activeTab, setActiveTab }) => {
  return (
    <div className="d-flex flex-wrap justify-content-start w-100 border-bottom px-4 pointer mt-2">
      <div 
        onClick={() => setActiveTab("rp")} 
        className={`col-12 col-md-auto ${activeTab === "rp" && `ratesTabsUnderline`} px-4 py-2 text-center`}
      >
        Rates & Practice Policies
      </div>
      <div 
        onClick={() => setActiveTab("r")} 
        className={`col-12 col-md-auto ${activeTab === "r" && `ratesTabsUnderline`} px-4 py-2 text-center`}
      >
        Reports
      </div>
      <div 
        onClick={() => setActiveTab("nf")} 
        className={`col-12 col-md-auto ${activeTab === "nf" && `ratesTabsUnderline`} px-4 py-2 text-center`}
      >
        News Feed
      </div>
      <div 
        onClick={() => setActiveTab("grc")} 
        className={`col-12 col-md-auto ${activeTab === "grc" && `ratesTabsUnderline`} px-4 py-2 text-center`}
      >
        Guidance & Resource Centre
      </div>
    </div>
  );
};

export default TabSwitch;
