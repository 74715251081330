import React, { useState, useEffect } from 'react';
import '../../../styles/components/WeightHeightPickerModal.scss';
import { useFormikContext } from 'formik';

const WeightPickerModal = ({ isVisible, onSelect, onCancel }) => {
  const [selectedKg, setSelectedKg] = useState(0);
  const [selectedGrams, setSelectedGrams] = useState(0);

  const calculateWeight = (kgValue, gramsValue) => {
    const totalWeightInKg = kgValue + (gramsValue / 100);
    onSelect(totalWeightInKg.toFixed(2)); 
  };

  const handleKgChange = (e) => {
    const kgValue = Number(e.target.value);
    setSelectedKg(kgValue);
    calculateWeight(kgValue, selectedGrams); 
  };

  const handleGramsChange = (e) => {
    const gramsValue = Number(e.target.value);
    setSelectedGrams(gramsValue);
    calculateWeight(selectedKg, gramsValue); 
  };

  const { touched, errors } = useFormikContext();

  return (
    <div className="modal-container" onClick={(e) => e.stopPropagation()}>
      <h2 className="modal-title height">Select Weight<span className="error-text">*</span></h2>
      <div className="picker-container">
        <select
          className="picker"
          value={selectedKg}
          onChange={handleKgChange}
        >
          {[...Array(301).keys()].map((kg) => (
            <option key={kg} value={kg}>{kg} kg</option>
          ))}
        </select>
        <select
          className="picker"
          value={selectedGrams}
          onChange={handleGramsChange}
        >
          {[...Array(100).keys()].map((grams) => (
            <option key={grams} value={grams}>{grams} g</option>
          ))}
        </select>
      </div>
      {touched.weight && errors.weight && <div className='error-text'>{errors.weight}</div>}
    </div>
  );
};

export default WeightPickerModal;
