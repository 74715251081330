import React from 'react';

const ModifierCode = React.memo(({ item, index, handleFavouriteQuantityChange, handleRemoveItem }) => {
  return (
    <div className='row'>
      <div className="col-12 col-md-6 mb-md-0 p-0">
        <label className="mb-1">Code:</label>
        <input
          type="text"
          disabled
          value={item.code}
          className="form-control bg-light"
        />
      </div>

      <div className="col-12 col-md-6 mb-md-0 position-relative">
        <label className="mb-1">Quantity:</label>
        <input
          type="number"
          value={item.quantity}
          onChange={(e) => handleFavouriteQuantityChange(e.target.value, index, 'favourite')}
          className="form-control"
        />
        <div className="col-12 text-end">
          <img 
            onClick={() => handleRemoveItem(index)} 
            className='trashcan pointer' 
            style={{ objectFit: 'contain' }} 
            src={require("../../assets/icons/Bin Icon.png")} 
            width={25} 
            alt="Remove"
          />
        </div>
      </div>
    </div>
  );
});

export default ModifierCode;
