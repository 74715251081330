import React, { useState, useEffect,useCallback,useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from '../common/MainHeader';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../services/authentication/LoginService.js';
import MedicalSchemeOption from '../../services/Billing/MedicalSchemeOption.js';
import ScrollView from '../common/ScrollView';
import debounce from "lodash.debounce";
import   "../../styles/components/PatientDetailsStyle.scss"
import { UserContext } from '../../services/authentication/UserContext.js';
import { useContext } from 'react';
import usePatientDetailsStore from '../state/PatientDetails.js';

function PatientDetailsTwo() {

      //------------------------STATE VARIABLES-------------------------------//
  const { username } = useContext(UserContext);
  const { token } = useAuth();
  const navigate = useNavigate();
  const [allSchemes, setAllSchemes] = useState([]);
  const [searchScheme, setSearchScheme] = useState("");
  const medicalSchemeOption = MedicalSchemeOption(token);
  const [isSchemeFlatListVisible, setIsSchemeFlatListVisible] = useState(false);
  const [flatListSelectPlan,setflatListSelectPlan] = useState(false)
  const [initialTreatingDocData,setInitialTreatingDocData] = useState([])
  const [medicalSchemeData, setMedicalSchemeData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading,setIsLoading] = useState(false)
  const [selectedScheme, setselectedScheme] = useState([]);
  const [copiedPlans,setCopiedPlans] = useState([])
  const optionsRef = useRef([])
  const [fieldModified, setFieldModified] = useState({
    patientEmail: false,
    patientNextOfKinNumber: false,
    patientNextOfKinName:false,
    patientMedicalAid: false,
    patientMedicalAidPlan: false,
    patientMedicalAidNumber:false,
  });
  const location = useLocation()
  const patientData = location.state?.patientData

  const modifiedData  =patientData?.modifiedFields
  //------------------------STATE VARIABLES-------------------------------//
  const {
    storePatientId,
    storePatientName,
    storePatientSurname,
    storePatientDob,
    storePhysicalAddress,
    setPatientId,
    setStorePatientName,
    setPatientSurname,
    setPatientDob,
    setPhysicalAddress,
    storeNextOfKinContact,
    storePatientType,
    setStorePatientType,
    setNextOfKinContact,
    storeContactNumber,
    setContactNumber
  } = usePatientDetailsStore((state) => ({
    storePatientId: state.storePatientId,
    storePatientName: state.storePatientName,
    storePatientSurname: state.storePatientSurname,
    storePatientDob: state.storePatientDob,
    storeNextOfKinContact:state.storeNextOfKinContact,
    storePhysicalAddress: state.storePhysicalAddress,
    storeContactNumber:state.storeContactNumber,
    storePatientType:state.storePatientType,
    setPatientId: state.setPatientId,
    setStorePatientName: state.setStorePatientName,
    setPatientSurname: state.setPatientSurname,
    setPatientDob: state.setPatientDob,
    setNextOfKinContact:state.setNextOfKinContact,
    setPhysicalAddress: state.setPhysicalAddress,
    setStorePatientType:state.setStorePatientType,
    setContactNumber:state.setContactNumber
  }));

//------------------------VALIDATION SCHEMA-------------------------------//
const validationSchema = Yup.object({
  medicalAidOption: 
  patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' && 
      Yup.string()
      .trim()
      .required("Medical Aid is selected, please select a Medical Aid from list")
      .matches(/^\S/, "Field must not start with a whitespace character") ,
  
      medical_aid_plan: patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' &&  Yup.string()
  .trim()
  .required("Medical Aid is selected, please select a plan")
  .matches(/^\S/, "Field must not start with a whitespace character"),
  medical_aid_number: patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' &&  Yup.string()
  .trim()
  .required("Medical Aid is selected, please fill in Medical Aid number")
  .matches(/^\S/, "Field must not start with a whitespace character"),

});
  


//Debounced search for medical schemes
  const debouncedSetSchemes = useCallback(  
    debounce(async (text, setFieldValue) => {  
      try {
        
        const schemeData = await medicalSchemeOption.fetchSchemes();
        if (Array.isArray(schemeData)) {
          const filteredSchemes = schemeData.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
          );        
          setMedicalSchemeData(filteredSchemes);       
        }
      } catch (error) {
        setError(error.message);
      }
    }, 500),
    []
  );

  const handleSelectPlan = (item, setFieldValue) => {
    setFieldValue('medical_aid_plan',item.option_name)
    setflatListSelectPlan(false)
  };

  //handle the selection of the scheme
  const handleSelectScheme = (item, setFieldValue) => { 
    if (selectedScheme.length>0 && selectedScheme.id === item.id) { 
      setselectedScheme(null);
      setSearchScheme("");
    } else {
      // setFieldValue("selectPlan","")
      setFieldValue("medicalAidOption", item.name); 
      // setselectedScheme(item);   
      // setSearchScheme(item.name);
      setIsSchemeFlatListVisible(false);
      fetchPlans(item.code)
    }
  };
  const fetchPlans = async (code) =>{
    try {
      const data = await medicalSchemeOption.fetchOptions(code);
      optionsRef.current = []
      optionsRef.current.push(data)
      setCopiedPlans(data)
      setflatListSelectPlan(true)
    } catch (err) {
      setError(err.message);
    }
  }
  const debouncedSetPlans = useCallback(
    debounce(async (text, setFieldValue) => {   
      try {
    
        const planData = optionsRef.current[0]
       

        if (text.length <2){
          setCopiedPlans(planData)
          return
        }
        else if (planData) {
          const filteredSchemes = planData.filter((item) =>item.option_name.toLowerCase().includes(text.toLowerCase())
            );
          setCopiedPlans(filteredSchemes)
        
        }
      } catch (error) {
        setError(error.message);
      }
    }, 500),
    []
  );

  //Handle the medical scheme click
  const handleSearchSchemes = (text,setFieldValue) => {
    setSearchScheme(text);
    if (text.length >= 2) {
      debouncedSetSchemes(text);
      setIsSchemeFlatListVisible(true);
      setFieldValue("selectPlan","")
      setflatListSelectPlan(false)
    } else {
      setMedicalSchemeData([]);
      setIsSchemeFlatListVisible(false);
    }
  };


  useEffect(()=>{
    const fetchData = async () => {
      try {
        const data = await  medicalSchemeOption.fetchSchemes();
        if (data && data.length > 0) {
          setAllSchemes(data);
        } 
      
      }catch(err){
        console.log(err)
      }
    }  
    fetchData() 
  },[])

  const handleRedirect = () => {
    navigate('/invoicestepone');
  };




  return (
    <div className="full-width-header">
      <MainHeader props={"home"} />
      <div className="container">
      <div className="backbtnContainer my-2">
        <button className="btn" onClick={() => navigate(-1)}>
          <i style={{ color: 'black' }} className="fa fa-arrow-left" aria-hidden="true"></i>
          {" "}PATIENT DETAILS
        </button>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="p-3 mb-2 text-black">
                  <span  className="font-bold">
                    Step 2 of 2
                    </span>
                </div>
                </div>
      <div>
        <Formik
          initialValues={{ 
            email: patientData?.patient_email|| "", 
            nextofKinTel: storeNextOfKinContact ? storeNextOfKinContact : patientData?.nextofkin_tel|| "",
            nextofkin: patientData?.nextofkin|| "",
             medicalAidOption:  patientData?.medical_aid_option|| "",
             medical_aid_plan:patientData?.medical_aid_plan|| "",
             medical_aid_number:patientData?.medical_aid_number|| ""
            }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm, setFieldError }) => {
            const modifiedFields = {
              patientEmail: fieldModified.patientEmail|| values.patientEmail !==  patientData?.patient_email,
              patientNextOfKinNumber: fieldModified.patientSurname || values.patientNextOfKinNumber !== patientData?.nextofkin_tel,
              patientNextOfKinName: fieldModified.physicalAddress || values.patientNextOfKinName !== patientData?.nextofkin,
              patientMedicalAid: fieldModified.patientMedicalAid || values.medicalAidOption !==  patientData?.medical_aid_option,
              patientMedicalAidPlan: fieldModified.patientContactNumber || values.medicalAidPlan !== patientData?.medical_aid_plan,
              patientMedicalAidNumber: fieldModified.patientContactNumber || values.medicalAidNumber !== patientData?.medical_aid_number,
            };
          
            // Check if any field was modified
            const isAnyFieldModified = Object.values(modifiedFields).some(field => field);
          
            // Update the goodx_modified flag
            const goodx_modified = isAnyFieldModified ? true : false;
          
            // Create updated patient data object
            const updatedPatientData = {
              ...patientData,
              email: modifiedFields.patientEmail ? values.patientEmail : patientData?.patient_email,
              nextOfKinNumber: modifiedFields.patientNextOfKinNumber ? values.patientNextOfKinNumber : patientData?.nextofkin_tel,
              nextOfKinName: modifiedFields.patientNextOfKinName ? values.patientNextOfKinName : patientData?.nextofkin,
              medicalAid: modifiedFields.patientMedicalAid ? values.medicalAidOption  : patientData?.medical_aid_option,
              medicalAidPlan: modifiedFields.patientMedicalAidPlan ? values.medicalAidPlan : patientData?.medical_aid_plan,
              medicalAidNumber: modifiedFields.patientMedicalAidNumber ?  values.medicalAidNumber : patientData?.medical_aid_number,
              modifiedFields: modifiedFields,
            };
            const newModifiedData = {};
                  if (modifiedFields.patientEmail) newModifiedData.patientEmail = values.patientEmail;
                  if (modifiedFields.patientNextOfKinNumber) newModifiedData.patientNextOfKinNumber = values.patientNextOfKinNumber;
                  if (modifiedFields.patientNextOfKinName) newModifiedData.patientNextOfKinName = values.patientNextOfKinName;
                  if (modifiedFields.patientMedicalAid) newModifiedData.patientMedicalAid = values.medicalAidOption;
                  if (modifiedFields.patientMedicalAidPlan) newModifiedData.patientMedicalAidPlan = values.medicalAidPlan;
                  if (modifiedFields.patientMedicalAidNumber) newModifiedData.patientMedicalAidNumber = values.medicalAidNumber;
      
        
            const mergedPatientData = { ...patientData, ...newModifiedData };
      
            const { medicalAidOption, medical_aid_plan, medical_aid_number } = values;
            if (
              patientData?.debtor_ma_schemename !== 'PRIVATE PATIENT' &&
              medicalAidOption.trim() &&
              medical_aid_plan.trim() &&
              medical_aid_number.trim()
            ) {
      
      
              navigate("/invoicestepone", { patientData: mergedPatientData, username, goodx_modified, modifiedData, initialTreatingDocData  });
              // navigateToNextPage(); 
              resetForm();
            } else if (patientData?.debtor_ma_schemename === 'PRIVATE PATIENT') {
              navigate("invoice", { patientData: mergedPatientData, username, goodx_modified, modifiedData, initialTreatingDocData });
              // navigateToNextPage(); 
              resetForm();
            }

          }} 
        >
          {({ isSubmitting,setFieldValue,handleChange }) => (
            <Form>
                <div className='mb-3'>
                  <label htmlFor="Email">Email Address</label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    id="email"
                    onChange={(text)=>{
                      handleChange("email")(text);
                      setFieldModified((prev) => ({
                        ...prev,
                        patientEmail: true,
                      }));
                    }} 
                  />
                  <ErrorMessage name="email" component="div" className="error-text" />
                </div>
                
              <div className='mb-3'>
                <label htmlFor="Next of Kin Tel">Next of Kin Contact Number</label>
                <Field
                  type="tel"
                  name="nextofKinTel"
                  className="form-control"
                  id="nextofKinTel"
                  onChange={(e)=>{
                    const text = e.target.value;
                    setNextOfKinContact(text)
                    handleChange("nextofKinTel")(text);
                    setFieldModified((prev) => ({
                      ...prev,
                      patientNextOfKinNumber: true,
                    }));
                  }} 
                />
                <ErrorMessage name="nextofKinTel" component="div" className="error-text" />
              </div>
              <div className='mb-3'>
                <label htmlFor="Next of Kin">Next of Kin</label>
                <Field
                  type="text"
                  name="nextofkin"
                  className="form-control"
                  id="nextofkin"
                  onChange={(text)=>{
                    handleChange("nextofkin")(text);
                    setFieldModified((prev) => ({
                      ...prev,
                      patientNextOfKinName: true,
                    }));
                  }} 
                />
              </div>

   
              <div style={{ position: 'relative' }} className='mb-3'>

                <label htmlFor="Medical Aid Option">Medical Aid Select From List</label>
                <Field
                  type="text"
                  name="medicalAidOption"
                  className="form-control"
                  id="medicalAidOption"
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldValue("medicalAidOption", value);
                    handleSearchSchemes(value, setFieldValue);
                
                  }}
                />
                    <ErrorMessage name="medicalAidOption" component="div" className="error-text d-flex" />
              {medicalSchemeData && isSchemeFlatListVisible && medicalSchemeData.length > 0 && (
            <div className="dropdown-list">
            {/* {isLoading && <div>Loading...</div>} */}
            <ul>
            {medicalSchemeData.map(item => (
                <li key={item.code} onClick={()=>handleSelectScheme(item, setFieldValue)}>
                {item.name}
                </li>
            ))}
            </ul>
          </div>
              )}       
              </div>              
              <div style={{ position: 'relative' }}  className='mb-3'>
                <label htmlFor="Medical Aid Plan">Select Plan</label>
                <Field
                  type="text"
                  name="medical_aid_plan"
                  className="form-control"
                  id="medical_aid_plan"
                  onChange={(text)=>{
                    handleChange("medical_aid_plan")(text);
                   
                  }} 
                />
                    <ErrorMessage name="medical_aid_plan" component="div" className="error-text" />
              {flatListSelectPlan &&  
                  <div className="dropdown-list">
                    <ul>
                    {copiedPlans.map((item, index) => (
                      <li  onClick={()=>handleSelectPlan(item,setFieldValue)} className="pointer" key={index}>
                        {item.option_name}
                      </li>
                    ))}
                    </ul>
                  </div>
                }
              </div>
              
              <div className='mb-3'>
                <label htmlFor="Medical Aid Number">Medical Aid Number</label>
                <Field
                  type="text"
                  name="medical_aid_number"
                  className="form-control"
                  id="medical_aid_number"
                  onChange={(text)=>{
                    handleChange("medical_aid_number")(text);
                    setFieldModified((prev) => ({
                      ...prev,
                      patientMedicalAidNumber: true,
                    }));
                  }} 
                />
                        <ErrorMessage name="medical_aid_number" component="div" className="error-text" />
              </div>

              <div className='mb-3'>
                  <button 
                      type='submit'
                      className="w-100 formButton"      
                  >
                      <div className="text-white d-flex align-items-center justify-content-center">
                          <span>Continue</span>
                      </div>
                  </button>
              </div>

              <ErrorMessage name="auth" component="div" className="error-text" />
            </Form>
          )}
        </Formik>
      </div>
      </div>
    </div>
  );
}

export default PatientDetailsTwo;
