import React from 'react'

function AddButton({
    isSubmitting,
    func,
    src,
    text
}) {
  return (
    <div className='mt-3'>
    <button
      type="button"
      className="addcnote"
      disabled={isSubmitting}
      onClick={func}
    >
      <div className="text-white d-flex align-items-center justify-content-center">
        <img
        alt=''
          width={22}
          height={22}
          style={{ objectFit: "contain", filter: "invert(1)" }}
          src={src}
        />
        <span className='padleftt'>{text}</span>
      </div>
    </button>
  </div>
  )
}

export default AddButton