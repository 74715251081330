import axios from "axios";
import PROD_API from "../baseEndpoint";

const FavouriteHistoryService = (token) => {
  const fetchFavouriteHistory = async (searchparm = "") => {
    try {
      const apiUrl = `${PROD_API}search_billing_templates?accessToken=${token}&searchTerm=${searchparm}`;

      const response = await axios.post(apiUrl);

      if (response.data && response.data.billingTemplates) {
        return {
          itemList: response.data.billingTemplates.map(template => ({
            description: template.BillingTemplateDesc,
            id: template.BillingTemplateID,
            createdBy: template.CreatedBy,
            customerId: template.CustomerID,
            modifiedDate: template.ModifiedDate,
          })),
          parm_extra: response.data.EntityNo || "",
          status: 0,
        };
      } else {
        console.log("Failed to retrieve favourites. Error message:", response.data.message || "No billing templates found.");
        return { itemList: [], parm_extra: response.data.message || "No templates found." };
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      return { itemList: [], parm_extra: "Error fetching data." };
    }
  };

  return { fetchFavouriteHistory };
};

export default FavouriteHistoryService;
