import React from 'react';
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // Generate page numbers
  const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        {/* Previous button */}
        <li
          className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            if (currentPage > 1) onPageChange(currentPage - 1);
          }}
        >
          <a className="page-link" href="#!">
            Previous
          </a>
        </li>

        {/* Page number buttons */}
        {generatePageNumbers().map((page) => (
          <li
            key={page}
            className={`page-item ${page === currentPage ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              if (page !== currentPage) onPageChange(page);  
            }}
          >
            <a className="page-link" href="#!">
              {page}
            </a>
          </li>
        ))}

        {/* Next button */}
        <li
          className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            if (currentPage < totalPages) onPageChange(currentPage + 1);
          }}
        >
          <a className="page-link" href="#!">
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

  
  export default Pagination;