import React from 'react';
import { useNavigate } from 'react-router-dom';
import BillingButtons from '../common/BillingButtons';
import Sidebar from '../Sidebar/Sidebar';
import PatientSearch from './patientSearch';
function BillingSearch() {
  const navigate = useNavigate();

  const handlePatientSelect = (patientData) => {
    navigate('/patientdetailsone', { state: { patientData } });
  };

  return (
    <div>
      <Sidebar heading={<h1 className="heading">BILLING</h1>}>
        <div className='container mt-4'>
          <BillingButtons />
          <PatientSearch onPatientSelect={handlePatientSelect} retrieveMultiple={true} />
        </div>
      </Sidebar>
    </div>
    
  );
}

export default BillingSearch;
