import React, { useState, useEffect, useCallback } from "react";
import { ErrorMessage } from "formik";
import ProcedureCodeService from "../../services/Billing/ProcedureCodeService";
import ModifiersService from "../../services/Billing/ModifiersService";
import FavouriteHistoryService from "../../services/Favourites/FavouriteHistoryService";
import AssistantService from "../../services/Billing/AssistantService";
import _ from "lodash";
import { useAuth } from "../../services/authentication/LoginService";
import FavouriteDetailsService from "../../services/Favourites/FavouriteDetailsService";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Modifiers from "./Modifiers";
import useModifiersStore from "../state/ModifiersStore";
const ProcedureCode = ({
  reInvoice = null,
  setFieldValue,
  sidebar,
}) => {
  //STATE VARIABLES
  const { token } = useAuth();
  const procedurecodeService = ProcedureCodeService();
  const modifierService = ModifiersService();
  const favouriteService = FavouriteHistoryService(token);
  const assistantLists = AssistantService(token);
  const favouriteDetailsService = FavouriteDetailsService(token);
  const currentDate = new Date().toISOString();
  //---------------------------------------------- MODIFIERS --------------------------------------------------------//
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];
  const weightCodes = ["0019", "0016", "0018"];
  const exceptionCodes = [
    "0008",
    "0009",
    "0076",
    "0029",
    "0011",
    "0011A",
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0018",
    "0019",
  ];
  const [exceptionLineItems, setExceptionLineItems] = useState([]);
  const [filteredUpdatedLineItems, setFilteredUpdatedLineItems] = useState([]);
  const [searchTermProcedureCode, setSearchTermProcedureCode] = useState("");
  const [favouriteUpdatesLinesItems, setFavouriteUpdatesLinesItems] = useState(
    []
  );
  const [error,setError] = useState("")
  const [updatedLineItems, setUpdatedLineItems] = useState([]);
  const [allProcedureData, setAllProcedureData] = useState([]);
  const [filteredProcedureData, setFilteredProcedureData] = useState([]);
  const [modifierData, setModifierData] = useState([]);
  const [assistantVals, setAssistantVals] = useState([]);
  const [favouriteData, setFavouriteData] = useState([]);
  const { setFilteredAssistant } = useModifiersStore();
  const {
    selectedProcedureCodes,
    setSelectedProcedureCodes,
    selectedICD10Codes,
    setSelectedICD10Codes,
    selectedNappiCodes,
    setselectedNappiCodes,
    setModifier,procedureCodeFlatListVisible, setProcedureCodeFlatListVisible
  } = useProcedureCodesStore();
  const handleQuantityChange = (text, index) => {
    const quantity = parseInt(text, 10);
    if (!isNaN(quantity) && quantity >= 0) {
      const updatedProcedureCodes = [...selectedProcedureCodes];
      updatedProcedureCodes[index].quantity = quantity;
      setSelectedProcedureCodes(updatedProcedureCodes);
    }
  };
  const [searchTermProc, setSearchTermProc] = useState("");
  const handleFavouriteQuantityChange = (text, index, type) => {
    let quantity;
    if (text === "") {
      // Handle empty input
      quantity = ""; // or any default value you prefer
    } else {
      quantity = parseInt(text);
    }

    if (!isNaN(quantity) && quantity >= 0) {
      if (type === "favourite") {
        const updatedFavouriteItems = [...favouriteUpdatesLinesItems];
        updatedFavouriteItems[index].quantity = quantity;
        setFavouriteUpdatesLinesItems(updatedFavouriteItems);
      } else if (type === "updated") {
        const updatedUpdatedItems = [...updatedLineItems];
        updatedUpdatedItems[index].quantity = quantity;
        setUpdatedLineItems(updatedUpdatedItems);
      }
    } else {
      console.log("Invalid quantity input");
    }
  };
  const handleSelectProcedures = async (item, setFieldValue) => {
    const newItem = {
      ...item,
      quantity: 1,
      modifierCode: item.modifierCode || "",
      modifierDescription: item.modifierDescription || "",
    };

    try {
      // Fetch favorite details based on selected code
      const [favouriteICD10Details, favouriteDetails, favouriteNappiDetails] =
        await Promise.all([
          favouriteDetailsService.fetchFavouriteDetails(
            newItem.codeFavourite,
            1
          ),
          favouriteDetailsService.fetchFavouriteDetails(
            newItem.codeFavourite,
            2
          ),
          favouriteDetailsService.fetchFavouriteDetails(
            newItem.codeFavourite,
            3
          ),
        ]);
      // Handle ICD10 details
      if (favouriteICD10Details && favouriteICD10Details.itemList) {
        const detailICD10Codes = Object.keys(
          favouriteICD10Details.itemList
        ).map((icd10Code) => ({
          icd10Code: icd10Code,
          isFavorite: true,
        }));
        const updatedICD10Codes = [...selectedICD10Codes, ...detailICD10Codes];

        setFieldValue("selectedICD10Codes", updatedICD10Codes);
        setSelectedICD10Codes(updatedICD10Codes);
      }

      // Handle Nappi details
      if (favouriteNappiDetails && favouriteNappiDetails.itemList) {
        const detailNappiCodes = Object.keys(
          favouriteNappiDetails.itemList
        ).map((code) => ({
          code: code,
          quantity: 1,
          isFavorite: true,
        }));
        const updatedNappiCodes = [...selectedNappiCodes, ...detailNappiCodes];
        setselectedNappiCodes(updatedNappiCodes);
        setFieldValue("selectedNappiCodes", updatedNappiCodes);
      }

      // Handle favorite details
      if (favouriteDetails && favouriteDetails.itemList) {
        const detailCodes = Object.entries(favouriteDetails.itemList).map(
          ([code, description]) => ({
            code: code,
            description: description || "",
            isFavorite: true,
          })
        );
        const updatedCodes = [...selectedProcedureCodes, ...detailCodes];
        setSelectedProcedureCodes(updatedCodes);
        setFieldValue("selectedProcedureCodes", updatedCodes);
      } else {
        console.error(
          "Error fetching favourite details: favouriteDetails is undefined"
        );
        return; // Handle errors gracefully
      }
    } catch (error) {
      console.error("Error fetching favourite details:", error);
    }
    // Update selected procedure codes
    const isCodeSelected = selectedProcedureCodes.some(
      (code) => code.code === item.code && code.description === item.description
    );
    let newSelectedCodes;

    if (isCodeSelected) {
      newSelectedCodes = selectedProcedureCodes.filter(
        (code) =>
          !(code.code === item.code && code.description === item.description)
      );
    } else {
      newSelectedCodes = [...selectedProcedureCodes, newItem];
      setSearchTermProc(""); // Clear search term if a new code is selected
    }
    setFieldValue("selectedProcedureCodes", newSelectedCodes);



  };
  const handleRemoveItem = (indexToRemove) => {
    // Remove the item from the state without triggering the API
    const updatedFavouriteLinesItems = [...favouriteUpdatesLinesItems];
    updatedFavouriteLinesItems.splice(indexToRemove, 1);
    setFavouriteUpdatesLinesItems(updatedFavouriteLinesItems);
    const updatedSelectedProcedureCodes = selectedProcedureCodes.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedProcedureCodes(updatedSelectedProcedureCodes);
  };

  const handleSelectProcedureCode = (item) => {
    const isCodeSelected = selectedProcedureCodes.some(
      (code) => code.code === item.code
    );
    let newSelectedCodes;

    if (isCodeSelected) {
      newSelectedCodes = selectedProcedureCodes.filter(
        (code) => code.code !== item.code
      );
    } else {
      const newItem = {
        code: item.code,
        quantity: 1,
        description: item.description || "",
      };
      newSelectedCodes = [...selectedProcedureCodes, newItem];
      if (item.isFavorite) {
        handleSelectProcedures(item, setFieldValue);
      }
    }

    setSelectedProcedureCodes(newSelectedCodes);
    setFieldValue("selectedProcedureCodes", newSelectedCodes);
    setProcedureCodeFlatListVisible(false); // Hide the procedure code list
    setSearchTermProcedureCode("");
  };

  useEffect(() => {
    const updateLineItems = () => {
      try {
        const values = selectedProcedureCodes
          .map((code, index) => ({
            line_nr: index + 1 + (reInvoice?.invoiceLines?.length || 0),
            code: code.code,
            quantity: code.quantity,
            diagnosis: selectedICD10Codes
              .map((icd10) => icd10.icd10Code)
              .join(","),
            administered_timestamp: currentDate?.toString() || "",
            description: code.description,
          }))
          .filter((code) => code.code !== undefined);
        // Update updatedLineItems
        setUpdatedLineItems((prevItems) => [...prevItems, ...values]);

        // Combine line items
        const combinedLineItems = [
          ...(favouriteUpdatesLinesItems || []),
          ...values, // Use the newly created values instead of updatedLineItems
        ];

        // Filter out exceptions
        const filteredExceptions = combinedLineItems.filter((item) =>
          exceptionCodes.includes(item.code)
        );
        setExceptionLineItems(filteredExceptions);

        // Filter out favourite updates and updated items that are not exceptions
        const filteredFaves = (favouriteUpdatesLinesItems || []).filter(
          (item) => !exceptionCodes.includes(item.code)
        );
        const filteredUpdatedLines = values.filter(
          (item) => !exceptionCodes.includes(item.code)
        );
        setFilteredUpdatedLineItems(filteredUpdatedLines);
      } catch (error) {
        console.error(
          "An error occurred while filtering out undefined codes:",
          error
        );
      }
    };
    // Call the function to update line items
    updateLineItems();
  }, [selectedProcedureCodes, reInvoice, favouriteUpdatesLinesItems]); // Added dependencies for comprehensive updates

  const checkModifier = (codes) => {
    if (!codes || !Array.isArray(codes)) {
      return false; // Ensure `codes` is an array
    }

    return (
      codes.some((code) => assistantCodes.includes(code)) ||
      codes.some((code) => timeFromToCodes.includes(code)) ||
      codes.some((code) => weightCodes.includes(code))
    );
  };
  useEffect(() => {
    const codes = selectedProcedureCodes.map((codeObj) => codeObj.code);
    const newModifier = checkModifier(codes);
    setModifier(newModifier);
  }, [selectedProcedureCodes]);

  const handleSearchCodeInputChange = (text) => {
    setSearchTermProcedureCode(text);
    if (text === "") {
      setSearchTermProcedureCode("");
    } else {
      debouncedSearchProcedureCode(text);
    }
    setProcedureCodeFlatListVisible(true);
  };
  const debouncedSearchProcedureCode = useCallback(
    _.debounce((text) => {
      handleSearchProcedureCodeInput(text);
    }, 250),
    [allProcedureData]
  );
  useEffect(() => {
    return () => {
      debouncedSearchProcedureCode.cancel();
    };
  }, [debouncedSearchProcedureCode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [procedureData, modifierData, favouriteData, assistantsData] =
          await Promise.all([
            procedurecodeService.ProcedureCodeData(),
            modifierService.ModifiersData(),
            favouriteService.fetchFavouriteHistory(searchTermProc),
            assistantLists.AssistantData(),
          ]);

        if (favouriteData && favouriteData.itemList) {
          const favoriteList = Object.keys(favouriteData.itemList).map(
            (key) => ({
              codeFavourite: key,
              description: favouriteData.itemList[key],
              isFavorite: true, // Ensure this is set
            })
          );

          const allProcedureData = [
            ...procedureData.map((proc) => ({
              ...proc,
              isFavorite: false, // Set default value for non-favorites
            })),
            ...favoriteList,
          ];

          setAllProcedureData(allProcedureData);
          setFilteredProcedureData(allProcedureData);
          setModifierData(modifierData);
          setFavouriteData(favoriteList);
          if (assistantsData) {
            setAssistantVals(assistantsData);
            setFilteredAssistant(assistantsData);
          }
        } else {
          setAllProcedureData([]);
          setFilteredProcedureData([]);
          setModifierData([]);
          setFavouriteData([]);
          setError("Failed to fetch or empty favourite data");
        }
      } catch (err) {
        setAllProcedureData([]);
        setModifierData([]);
        setFavouriteData([]);
        setError(err.message);
      }
    };

    fetchData();
    if (reInvoice) {
      const reInvoiceValue =
        reInvoice.invoiceLines?.length > 0
          ? reInvoice.invoiceLines.map((line) => ({
              code: line.Code || line.codeFavourite,
              description: line.Description,
              quantity: line.Quantity,
              administered_timestamp: currentDate.toString() || "",
            }))
          : []; // Return an empty array if no lines are present

      setSelectedProcedureCodes(reInvoiceValue);
    }
  }, []);

  const handleSearchProcedureCodeInput = (text) => {
    const combinedData = [...allProcedureData, ...modifierData];
    const favoritesList = favouriteData.itemList;

    const filteredProcedureData = combinedData
      .filter((item) => {
        const lowerCasedSearchTerm = text.toLowerCase();

        // Ensure `description` is a string before calling `.toLowerCase()`
        const itemDescription =
          typeof item.description === "string"
            ? item.description.toLowerCase()
            : "";

        return (
          (item.code && item.code === text) ||
          (item.code &&
            item.code.toLowerCase().includes(lowerCasedSearchTerm)) ||
          (item.modifierCode &&
            item.modifierCode.toLowerCase().includes(lowerCasedSearchTerm)) ||
          // Checking if description is a string before calling `.toLowerCase()`
          (itemDescription && itemDescription.includes(lowerCasedSearchTerm)) ||
          (favoritesList &&
            (Object.keys(favoritesList).some((key) =>
              key.toLowerCase().includes(lowerCasedSearchTerm)
            ) ||
              Object.values(favoritesList).some((value) =>
                value.toLowerCase().includes(lowerCasedSearchTerm)
              )))
        );
      })
      .sort((a, b) => {
        if (a.code === text) return -1;
        if (b.code === text) return 1;
        return 0;
      })
      .map(
        ({
          code,
          codeFavourite,
          description,
          modifierCode,
          modifierDescription,
        }) => ({
          code,
          description,
          codeFavourite,
          modifierCode,
          modifierDescription,
          isFavorite: favoritesList && code in favoritesList,
        })
      )
      .slice(0, 20);

    setFilteredProcedureData(filteredProcedureData);
  };
  return (
    <div>
      <div className="row align-details">
        <div className={`${!sidebar && "col-md-4"} mt-3 text-font`}>
          <label htmlFor="selectedProcedureCodes">
            Procedure Codes<span className="error-text">*</span>
          </label>
          <div style={{ position: "relative" }}>
            <input
            placeholder="Type Here to Search"
              type="text"
              value={searchTermProcedureCode}
              onChange={(e) => handleSearchCodeInputChange(e.target.value)}
              className="form-control"
              id="selectedProcedureCodes"
            />
             <i className="fas fa-search searchIcon"></i>
            {procedureCodeFlatListVisible &&
              filteredProcedureData.length > 0 && (
                <div className="dropdown-list">
                  <ul className="suggestions-list">
                    {filteredProcedureData.map((item, index) => (
                      <li
                        key={`${item.code || item.modifierCode}_${
                          item.description
                        }_${index}`}
                        onClick={() => handleSelectProcedureCode(item)}
                      >
                        {item.code
                          ? typeof item.code === "object"
                            ? JSON.stringify(item.code)
                            : item.code
                          : "No Code"}{" "}
                        -
                        {item.description
                          ? typeof item.description === "object"
                            ? JSON.stringify(item.description)
                            : item.description
                          : "No Description"}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
          </div>
          <ErrorMessage
            name="selectedProcedureCodes"
            component="div"
            className="error-text d-flex"
          />
        </div>
        <div className="col-md-8 mt-2">
          {filteredUpdatedLineItems.map((code, index) => (
        <div key={index} className="row">
          <div className="col-12 col-md-6 mb-md-0  ">
            <label >
              Code<span className="error-text">*</span>
            </label>
            <input
              type="text"
              disabled
              value={code.code}
              className="form-control bg-light"
            />
          </div>
          <div className="col-12 col-md-6 mb-md-0 position-relative">
            <label>
              Quantity<span className="error-text">*</span>
            </label>
            <input
              type="number"
              value={code.quantity || 1}
              onChange={(e) =>
                handleQuantityChange(e.target.value, index)
              }
              className="form-control"
            />
            <div className="col-12 text-end">
              <img
                onClick={() => handleRemoveItem(index)}
                className="trashcan pointer"
                style={{ objectFit: "contain" }}
                src={require("../../assets/icons/Bin Icon.png")}
                width={25}
                alt="Remove"
              />
            </div>
       

        </div>
      </div>
          ))}
          <Modifiers
            exceptionLineItems={exceptionLineItems}
            handleFavouriteQuantityChange={handleFavouriteQuantityChange}
            handleRemoveItem={handleRemoveItem}
            weightCodes={weightCodes}
            assistantVals={assistantVals}
          />
        </div>
      </div>
    </div>
  );
};

export default ProcedureCode;
