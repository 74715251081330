import React, {useState} from 'react'

function NotesModal({
    selectedNote,
    selectedDate,
    closeModal
}) {
  return (
    <div>
          <div className="backdrop" onClick={closeModal}></div>
          <div className="card">
            <div className='d-flex flex-column' >
            <div className='d-flex justify-content-start'>
            <span className='bold'>{new Date(selectedDate).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}</span> 
            </div>
            <div className='selected-note'>
              <span>{selectedNote}</span> 
            </div>
            <div className=' d-flex justify-content-center'>
            <button type='button' onClick={closeModal} className='align-items-end favourite-close' >Close</button>
            </div>
            </div>
          </div>
    </div>
  )
}

export default NotesModal