import { create } from 'zustand';

const usePatientDetailsStore = create((set) => ({
  storePatientId: '',
  storePatientName: '',
  storePatientSurname: '',
  storePatientDob: '',
  storePhysicalAddress: '',
  storePatientType: '',
  storeContactNumber: '',
  storeNextOfKinContact: '',
  storeMedicalAidSelectFromList: '',
  storeSelectPlan: '',
  storeMedicalAidNumber: '',
  storeEmailAddress:'',
  storeNextOfKin:'',

  setPatientId: (storePatientId) => set({ storePatientId }),
  setStorePatientName: (storePatientName) => set({ storePatientName }),
  setPatientSurname: (storePatientSurname) => set({ storePatientSurname }),
  setPatientDob: (storePatientDob) => set({ storePatientDob }),
  setPhysicalAddress: (storePhysicalAddress) => set({ storePhysicalAddress }),
  setStorePatientType: (storePatientType) => set({ storePatientType }),
  setEmailAddress: (storeEmailAddress)=>set({storeEmailAddress}),
  setContactNumber: (storeContactNumber) => set({ storeContactNumber }),
  setNextOfKinContact: (storeNextOfKinContact) => set({ storeNextOfKinContact }),
  setMedicalAidSelectFromList: (storeMedicalAidSelectFromList) => set({ storeMedicalAidSelectFromList }),
  setSelectPlan: (storeSelectPlan) => set({ storeSelectPlan }),
  setNextOfKin:(storeNextOfKin)=>set({storeNextOfKin}),
  setMedicalAidNumber: (storeMedicalAidNumber) => set({ storeMedicalAidNumber }),

  resetPatientDetails: () => set({ 
    storePatientId: '', 
    storePatientName: '', 
    storePatientSurname: '', 
    storePatientDob: '', 
    storePhysicalAddress: '', 
    storePatientType: '',
    storeContactNumber: '',
    storeNextOfKinContact: '',
    storeMedicalAidSelectFromList: '',
    storeSelectPlan: '',
    storeMedicalAidNumber: '',
    storeEmailAddress:''
  }),
}));

export default usePatientDetailsStore;
