
import axios from "axios";
import PROD_API from "../baseEndpoint";
const NappiCodeService = (token) => {

  const NappiCodeData= async (codeType, searchParam) => {
    if (!token) {
      console.error("Token is not valid or missing.");
      return [];
    }

    try {
      const apiUrl = PROD_API +`search_codes_list?accessToken=${token}&codeType=${codeType}&searchTerm=${searchParam}`;

      const response = await axios.post(apiUrl);

        if (response.status === 200 && response.data) {

        return response.data;
        
      } else {
        console.error("Failed to fetch data. Unexpected status code:", response.status);
        return [];
      }
    } catch (error) {
      console.error("Failed to fetch nappi code data. An error occurred:", error);
      return [];
    }
  };

  return {
    NappiCodeData,
  };
};

export default NappiCodeService;
