import axios from 'axios';
import PROD_API from "../baseEndpoint";

const AddFavouriteService = (token) => {
  const handleAddFavourite = async (favouriteDetails) => {
    const apiUrl = PROD_API + `add_billing_template?accessToken=${token}`;

    const headers = {
      'Content-Type': 'application/json',
    };

    try {

      const response = await axios.post(apiUrl, favouriteDetails, { headers });

      if (response.status === 200) {
      const { data, parm_extra, status, message } = response.data;
      return {
        data,
        status,
        parm_extra,
        message,
      };
    } else {
      const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
      throw new Error(errorMessage);
    }

    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  };

  return { handleAddFavourite };
};

export default AddFavouriteService;
