import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import PatientSearch from "./patientSearch";
import "../../styles/components/BillingHistory.scss"
import NavigationButton from "../NavigationButton";
function BillingHistory() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Invoice History");
  const [isPatientSelected, setIsPatientSelected] = useState(false);
  const handlePatientSelect = (patientData) => {
    setSelectedPatient(patientData);
    setIsPatientSelected(true);
  };
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };
  const navigate = useNavigate()
  return (
    <div>
      <Sidebar heading={<h1 className="heading">CLINICAL NOTES</h1>}>
        <div className="container mt-4">
          {/* Pass isBillingHistory prop as true */}
          {/* <PatientSearch
            onPatientSelect={handlePatientSelect}
            retrieveMultiple={false}
            isBillingHistory={true}  
            selectedTab={handleTabSelect}
          /> */}
          <PatientSearch
            onPatientSelect={handlePatientSelect}
            retrieveMultiple={true}
            isBillingHistory={true}
          >
            {isPatientSelected && (
              <>
                <div className="d-flex  flex-column align-items-start p-3 bg-light mt-2">
                  <div>
                  <div className="d-flex flex-column text-start">
                    <span className="billinghistoryHeader">Patient Name & Surname:</span>
                      <span className="billinghistoryText">{selectedPatient.name} {selectedPatient.surname}</span>
                  </div>
                  <div className="d-flex flex-column text-start">
                    <span className="billinghistoryHeader">Patient ID Number:</span>
                    <span className="billinghistoryText">{selectedPatient.id}</span>
                  </div>
                  <div className="d-flex flex-column text-start">
                    <span className="billinghistoryHeader">Account Number:</span>
                    <span className="billinghistoryText">{selectedPatient.debtor_number}</span>
                  </div>
                  </div>
                </div>

                <div className="responsive-flex mt-4">
                  <NavigationButton
                    header={"Submit CLOM"}
                    color={"white"}
                    img={require("../../assets/home3.png")} //questionmarkwithcricle
                    // route="queryhistory"
                  />
                  <NavigationButton
                    header={"Clinical Notes History"}
                    color={"white"}
                    img={require("../../assets/write.png")} //note with pen
                    handleClick={()=>
                      navigate("/clinicalnoteshistory",{state:{
                      patientID:selectedPatient.id}})}
                  />
                  <NavigationButton
                    header={"Templates"}
                    color={"white"}
                    img={require("../../assets/billing2.png")} // clock ticking
                    route="invoicehistory"
                  />
                </div>
              </>
            )}
          </PatientSearch>
        </div>
      </Sidebar>
    </div>
  );
}

export default BillingHistory;
