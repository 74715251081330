import React, { useEffect, useState } from "react";
import SideNavbar from "./common/SideNavbar";
import NotesModal from "./ClinicalNote/ClinicalNoteModal";
import InvoiceAdjustmentService from "../services/Adjustment/AddInvoiceAdjustmentService";
import { useNavigate } from "react-router-dom";
import { formatDateWithLocale } from "../utils/dateUtils";
import SubTaskQueriesCommentService from "../services/Queries/SubTaskQueriesCommentService";
import { useAuth } from "../services/authentication/LoginService";
import SubTask from "./SubTask";
import SubTaskComments from "./SubTaskComments";
const AddInvoiceAdjustment = ({
  caseIDreferenceNumber = null,
  referenceNumber = null,
  invoiceAdjustmentClicked,
  closeFunc,
  taskID = null,
  adjustmentHistory = true,
}) => {
  const { token } = useAuth();
  const [arrowClicked, setArrowClicked] = useState(false);
  const [noteText, setNoteText] = useState("");
  const navigate = useNavigate();
  const doctorNote = InvoiceAdjustmentService(token);
  const [adjustmentNoteModal, setAdjustmentNoteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedSubtask, setSelectedSubtask] = useState(null);

  const toggleAdjustmentModal = (text, date) => {
    setAdjustmentNoteModal(true);
    setSelectedNote(text, date);
    setSelectedDate(date);
  };
  let documentType = 1; // Default to 1 if caseIDreferenceNumber is not empty
  if (
    (caseIDreferenceNumber && caseIDreferenceNumber.trim()) === "" &&
    (referenceNumber && referenceNumber.trim()) !== ""
  ) {
    documentType = 2; // Set to 2 if caseIDreferenceNumber is empty but InvoiceReference has a value
    //invoice adjustment on invoice itself 1 on the ticket.
    
  }
  const handlePress = async () => {
    try {
      setLoading(true);
      if (!noteText.trim()) {
        setIsEmpty(true);
        return;
      }
      setIsEmpty(false);
      const response = await doctorNote.handleAdjustmentInvoice({
        SubTaskActionID: 7,
        SubTaskInstruction: noteText,
        DocumentID: caseIDreferenceNumber || referenceNumber,
        DocumentType: documentType,
      });
      navigate("/confirmationpage", {
        state: {
          message: response.message,
          refNo: response?.parm_extra,
        },
      });
      const parmExtra = response?.parm_extra || "";
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (adjustmentHistory) {
      fetchComments(taskID);
    }
  }, []);

  const fetchComments = async (taskID) => {
    try {
      const service = SubTaskQueriesCommentService(token);

      const commentsData = await service.fetchSubTaskQueriesComment(taskID);
      const subTaskInstruction = commentsData.subtask.SubTaskInstruction || "";
      // Update state to include SubInstructionCreatedDate and comments
      setSelectedSubtask({
        subTaskCreatedDate: commentsData.subtask.SubTaskCreatedDate,
        instructions: subTaskInstruction,
        comments: commentsData.comments.map((comment) => ({
          commentDesc: comment.CommentDesc,
          commentDate: formatDateWithLocale(comment.CommentCreatedDate),
        })),
      });
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  return (
    <>
      <SideNavbar
        heading={<h1 className="heading">ADD INVOICE ADJUSTMENT NOTE</h1>}
        notice={
          "Record and save patient encounter details on your device, securely stored in your clinical notes history for easy access and reference."
        }
        state={invoiceAdjustmentClicked}
        close={closeFunc}
        buttonText={
          loading ? (
            <i className="fas fa-spinner fa-spin mr-2"></i>
          ) : (
            "Save Note"
          )
        }
        proceed={handlePress}
      >
        {adjustmentHistory && (
          <div
            onClick={() => setArrowClicked(!arrowClicked)}
            className="d-flex light-gray-alt justify-content-between p-3 m-2 justify-content-center rounded pointer "
          >
            <div>
              <small className="bold">PREVIOUS ADJUSTMENT NOTES</small>
            </div>
            <div>
              <i
                className={` ${
                  arrowClicked ? "fa fa-chevron-down" : "fa fa-chevron-right"
                }`}
              ></i>
            </div>
          </div>
        )}
        {arrowClicked && (
          <div>
            <div
              onClick={() =>
                toggleAdjustmentModal(
                  selectedSubtask.instructions,
                  formatDateWithLocale(selectedSubtask.subTaskCreatedDate)
                )
              }
              className="d-flex justify-content-between px-3 p-2 row "
            >
              <div className="d-flex justify-content-center align-items-center col-3 ">
                <span>
                  {formatDateWithLocale(selectedSubtask.subTaskCreatedDate)}
                </span>
              </div>

              <SubTask selectedSubtask={selectedSubtask} />
            </div>

            <SubTaskComments
              toggleAdjustmentModal={toggleAdjustmentModal}
              selectedSubtask={selectedSubtask}
            />
          </div>
        )}

        <div className="p-3">
          <small className="d-flex bold">ADD INVOICE AJUSTMENT NOTE</small>
          <textarea
            onChange={(e) => setNoteText(e.target.value)}
            placeholder=""
            className="clinical-input"
          />
        </div>
      </SideNavbar>
      {adjustmentNoteModal && (
        <NotesModal
          selectedDate={selectedDate}
          selectedNote={selectedNote}
          closeModal={() => setAdjustmentNoteModal(false)}
        />
      )}
    </>
  );
};

export default AddInvoiceAdjustment;
