import React, { useEffect, useState } from "react";
//import { useAuth } from '../../services/Authentication/AuthServices';
import Sidebar from "../Sidebar/Sidebar.js"
import '../../styles/components/login.scss';
import NavigationButton from '../NavigationButton.js';
import { useLocation } from 'react-router-dom';
const BillingQueriesConfirmation = () => {
    const location = useLocation();
    const parm_extra = location?.state?.parmExtra || "";
 
    return (
        <Sidebar>
            <div className="container">
                <div className="margintopsuccess">
                    <div className="text-white d-flex align-items-center justify-content-center">
                        <img
                            width={100}
                            height={100}
                            style={{ objectFit: "contain" }}
                            src={require("../../assets/success-green-check-mark-icon.png")}
                        />
                    </div>

                    <div>
                        <p className='passwordText'>Billing Queries  Submitted</p>
                        <span className='loginText'>Ref No: {parm_extra}</span> 
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};

export default BillingQueriesConfirmation;

