import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../styles/components/login.scss";
import "../../styles/variables/_mixins.scss";
import LoginHeader from "../common/LoginHeader";
import MainFooter from "../common/MainFooter";
import vectorImage from "../../assets/Vector.png";
import vectorFullBGImage from "../../assets/Vectorfull.png";
import ForgotPasswordService from "../../services/authentication/ForgotPasswordService";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { handleForgotPassword } = ForgotPasswordService();

  const handleFormSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const forgotPasswordResult = await handleForgotPassword(values.email);
      if (forgotPasswordResult.success) {
        navigate("/passwordconfirmationpage");
      } else {
        actions.setErrors({ auth: forgotPasswordResult.error });
      }
    } catch (error) {
      actions.setErrors({ auth: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleLoginRedirect = () => {
    navigate("/");
  };

  return (
    <div className="login-page-wrapper page-bg-img">
      <img src={vectorImage} alt="Vector" className="vectorBig" />
      <img
        src={vectorFullBGImage}
        alt="Vector Full Background"
        className="vectorFullBGImage"
      />
      <img
        src={vectorFullBGImage}
        alt="Vector Full Background Left"
        className="vectorFullBGImageLeft"
      />
      <img src={vectorImage} alt="Vector Right" className="vectorImageRight" />

      <div className="row">
        <div className="col-md-6 col-12">
          {/* White Container */}
          <div className="container shadow bg-white rounded px-4 authContainer">
            <div className="row">
              <div className="col-12">
                <LoginHeader />
              </div>
              <div className="col-12">
                <div className="mt-4">
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="col-12">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-text"
                          />
                        </div>
                        {/* Include password field if necessary */}
                        <div className="col-12 mt-3">
                          <button
                            type="submit"
                            className="w-100 formButton"
                            disabled={isSubmitting || loading}
                          >
                            <div className="text-white d-flex align-items-center justify-content-center">
                              {loading && (
                                <i className="fas fa-spinner fa-spin mr-2"></i>
                              )}
                              {loading ? "Loading..." : "Send Reset Passcode"}
                            </div>
                          </button>
                        </div>
                        <div className="col-12">
                          <button
                            type="button"
                            className="w-100 formButton"
                            disabled={isSubmitting || loading}
                            onClick={handleLoginRedirect}
                          >
                            <div className="text-white d-flex align-items-center justify-content-center">
                              <span>Login</span>
                            </div>
                          </button>
                        </div>
                        <ErrorMessage
                          name="auth"
                          component="div"
                          className="error-text"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <MainFooter />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-0"></div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
