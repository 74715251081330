import axios from "axios";
import PROD_API from "../baseEndpoint";


const vatReportService = (token) => {
  const fetchVatReport = async (year, month) => { 
    try {

      const apiUrl = PROD_API + `OPS/reports/get_vat_report?accessToken=${token}&year=${year}&month=${month}`;
      console.log(apiUrl,"VatReport")

      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {
        console.log("Vat Report", response.data )

        return response.data
      } else {
        console.error("Failed to retrieve details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  
  return { 
    fetchVatReport
   };
};

export default vatReportService;
