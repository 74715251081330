import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import vatReportService from "../../../services/Reports/vatReportService";
import monthlyService from "../../../services/Reports/monthlyReportService";
import Sidebar from "../../Sidebar/Sidebar";
import { useAuth } from "../../../services/authentication/LoginService";
import { exportToBase64, exportToExcel } from "../../../utils/excelUtils";
import { generateReportFilename } from "../../../utils/reportFileName";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import "../../../styles/components/BatchBilling.scss";
import GenericButton from "../../GenericButton";
import NavigateBack from "../../NavigateBack";
import SaveReportService from "../../../services/Reports/SaveReportService";
import Loader from "../../../utils/Loader";
const ReportDetails = () => {
  const [error, setError] = useState("");
  const [reports, setReports] = useState([]);
  const [chartReport, setChartReports] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();
  const { token } = useAuth();
  const location = useLocation();
  const { reportType, month, year } = location.state || {};
  const [loading,setLoading] = useState(false)
  const { saveReport } = SaveReportService(token);
  const [ageAnalysis, setAgeAnalysis] = useState({});
  const [ageAnalysisStatus, setAgeAnalysisStatus] = useState([]);
  const [ledger, setLedger] = useState([]);
  const serviceMap = {
    "VAT Report": vatReportService(token),
    "Monthly": monthlyService(token),
    
    // "QR Report": qrCodeService(token),
  };
  const handleExport = (initial = false) => {
    const dataSheets = [
      {
        sheetName: "VAT Report",
        data: [],
      },
    ];

    let currentTaxFlag = "";
    let groupData = [];
    let groupTotal = {
      amountExcl: 0,
      amountVat: 0,
      amountIncl: 0,
    };

    reports.forEach((report) => {
      if (report.tax_flag_desc !== currentTaxFlag) {
        if (currentTaxFlag !== "") {
          groupData.push({
            "Entity No": "",
            "Entity Name": "",
            "Transaction Description": "",
            "Ledger Description": "",
            "VAT Percentage": "",
            "VAT Description": "",
            "Amount (Excl.)": groupTotal.amountExcl.toFixed(2),
            "Amount (VAT)": groupTotal.amountVat.toFixed(2),
            "Total (Incl. VAT)": groupTotal.amountIncl.toFixed(2),
          });
        }
        groupData.push({
          "Entity No": "",
          "Tax Description": report.tax_flag_desc,
          "Entity Name": "",
          "Transaction Description": "",
          "Ledger Description": "",
          "VAT Percentage": "",
          "VAT Description": "",
          "Amount (Excl.)": "",
          "Amount (VAT)": "",
          "Total (Incl. VAT)": "",
        });
        currentTaxFlag = report.tax_flag_desc;
        groupTotal = {
          amountExcl: 0,
          amountVat: 0,
          amountIncl: 0,
        };
      }
      groupData.push({
        "Entity No": report.entity_no,
        "Entity Name": report.entity_name,
        "Transaction Description":
          "Task Description: " + report.transaction_desc,
        "Ledger Description": report.ledger_desc,
        "VAT Percentage": report.vat_perc,
        "VAT Description": report.vat_desc,
        "Amount (Excl.)": parseFloat(report.amount_excl).toFixed(2),
        "Amount (VAT)": parseFloat(report.vat_amount).toFixed(2),
        "Total (Incl. VAT)": parseFloat(report.amount_incl).toFixed(2),
      });
      groupTotal.amountExcl += parseFloat(report.amount_excl);
      groupTotal.amountVat += parseFloat(report.vat_amount);
      groupTotal.amountIncl += parseFloat(report.amount_incl);
    });
    if (currentTaxFlag !== "") {
      groupData.push({
        "Entity No": "",
        "Entity Name": "",
        "Transaction Description": "",
        "Ledger Description": "",
        "VAT Percentage": "",
        "VAT Description": "",
        "Amount (Excl.)": groupTotal.amountExcl.toFixed(2),
        "Amount (VAT)": groupTotal.amountVat.toFixed(2),
        "Total (Incl. VAT)": groupTotal.amountIncl.toFixed(2),
      });
    }

    dataSheets[0].data = groupData;
    const filename = generateReportFilename(selectedDate);
    if (initial) {
      const exportExcel = exportToBase64(dataSheets);
      saveDocument(exportExcel, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      return;
    }
    exportToExcel(dataSheets, filename);
  };
  const formatChartData = (data) => {
    const chartData = [];

    const breakdownData = data.find(
      (item) => item.breakdown_type === "Turnover"
    );
    const receiptingData = data.find(
      (item) => item.breakdown_type === "Receipting"
    );

    if (!breakdownData) {
      console.log("Turnover breakdown data not found");
      return chartData;
    }

    if (!receiptingData) {
      console.log("Receipting breakdown data not found");
    }

    const monthList = breakdownData.month_list;
    Object.keys(monthList).forEach((month) => {
      if (month !== "Total" && month !== "Average") {
        chartData.push({
          month: month,
          turnover: parseFloat(monthList[month] || 0),
          receipting: parseFloat(receiptingData?.month_list[month] || 0),
        });
      }
    });

    // Convert the 'Average' field to a number
    if (monthList["Average"]) {
      monthList["Average"] = parseFloat(monthList["Average"]);
    }

    return chartData;
  };

  const handleExportMonthlyPDF = (initial = false) => {
    const doc = new jsPDF();

    // Set Title
    doc.setFontSize(18);
    doc.text("Monthly Reports", 20, 20);

    let yOffset = 40;

    // Add Performance Table (Breakdown by Type)
    doc.setFontSize(14);
    doc.text("Performance Table", 20, yOffset);
    yOffset += 10;

    doc.setFontSize(12);
    const months = chartReport.months || [];
    doc.autoTable({
      head: [["Type", ...months]],
      body: reports.map((report) => [
        report.breakdown_type,
        ...months.map((month) => report.month_list[month]),
      ]),
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: "striped",
    });

    yOffset = doc.lastAutoTable.finalY + 10;

    // Add Age Analysis Table
    doc.text("Age Analysis", 20, yOffset);
    yOffset += 10;

    doc.autoTable({
      head: [
        [
          "Age Range",
          "Total",
          "Current",
          "30 Days",
          "60 Days",
          "90 Days",
          "120 Days",
          "150 Days",
          "180+ Days",
        ],
      ],
      body: [
        [
          "Total",
          ageAnalysis.total,
          ageAnalysis.current,
          ageAnalysis.days_30,
          ageAnalysis.days_60,
          ageAnalysis.days_90,
          ageAnalysis.days_120,
          ageAnalysis.days_150,
          ageAnalysis.days_180_plus,
        ],
      ],
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: "striped",
    });

    yOffset = doc.lastAutoTable.finalY + 10;

    // Add Age Analysis by Status Table
    doc.text("Age Analysis by Status", 20, yOffset);
    yOffset += 10;

    doc.autoTable({
      head: [
        [
          "Status",
          "Total",
          "Current",
          "30 Days",
          "60 Days",
          "90 Days",
          "120 Days",
          "150 Days",
          "180+ Days",
        ],
      ],
      body: ageAnalysisStatus.map((status) => [
        status.account_status,
        status.age_analysis.total,
        status.age_analysis.current,
        status.age_analysis.days_30,
        status.age_analysis.days_60,
        status.age_analysis.days_90,
        status.age_analysis.days_120,
        status.age_analysis.days_150,
        status.age_analysis.days_180_plus,
      ]),
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: "striped",
    });

    yOffset = doc.lastAutoTable.finalY + 10;

    // Add Ledger Table
    doc.text("Ledger", 20, yOffset);
    yOffset += 10;

    doc.autoTable({
      head: [
        ["Date", "Description", "Debit", "Credit", "Total", "Running Total"],
      ],
      body: ledger.map((entry) => [
        entry.date,
        entry.description,
        entry.debit,
        entry.credit,
        entry.total,
        entry.running_total,
      ]),
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: "striped",
    });

    yOffset = doc.lastAutoTable.finalY + 10;
    if (initial) {
      const pdfOutput = doc.output("arraybuffer");
      const uint8Array = new Uint8Array(pdfOutput);
      let binaryString = "";
      for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
      }
      const base64PdfData = btoa(binaryString);
      saveDocument(base64PdfData, "monthlyReport.pdf", "application/pdf");
      return;
    }
    doc.save(`Monthly_Report_${selectedDate}.pdf`);
  };
  const saveDocument = async (base64_string, fileName, contentType) => {
    const reportDoc = {
      FileName: fileName,
      ContentType: contentType,
      AttachmentData: base64_string,
    };
    const reportNumber =
      reportType === "Monthly" ? 5 : reportType === "VAT Report" ? 4 : "";
    try {
      await saveReport(selectedDate, reportNumber, reportDoc);
    } catch (e) {
      console.error("Could not save document");
    }
  };

  useEffect(() => {
    const fetchReports = async () => {
  
      if (!reportType || !serviceMap[reportType]) {
        setError("Invalid report type");
        return;
      }

      setLoading(true);
      setError("");
      try {
        const service = serviceMap[reportType];
        let response = null;

        if (reportType === "VAT Report") {
          // const responseVATReport = await service.fetchVatReport(year, month);
          const responseVATReport = {
            selected_end_date: "2024-09-30",
            vat_report_data: [
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR CREDIT NOTES",
                ledger_desc: "INC1: PROFESSIONAL FEES",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-160990.27",
                amount_excl: "-1073266.21",
                amount_incl: "-1234256.48",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR CREDIT NOTES",
                ledger_desc: "INC2: MATERIAL INCOME",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-47746.58",
                amount_excl: "-318310.33",
                amount_incl: "-366056.91",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR CREDIT NOTES",
                ledger_desc: "INC3: MEDICINE INCOME",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-160.75",
                amount_excl: "-1071.63",
                amount_incl: "-1232.38",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR INVOICES",
                ledger_desc: "INC1: PROFESSIONAL FEES",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "548451.76",
                amount_excl: "3656333.03",
                amount_incl: "4204784.79",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR INVOICES",
                ledger_desc: "INC2: MATERIAL INCOME",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "160914.49",
                amount_excl: "1072763.80",
                amount_incl: "1233678.29",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "A. Output Tax",
                transaction_desc: "DEBTOR INVOICES",
                ledger_desc: "INC3: MEDICINE INCOME",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "462.11",
                amount_excl: "3080.59",
                amount_incl: "3542.70",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "CREDITOR CREDIT NOTE",
                ledger_desc: "EXP5: ASSISTENCY",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "1916.15",
                amount_excl: "12774.33",
                amount_incl: "14690.48",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "CREDITOR INVOICE",
                ledger_desc: "EXP5: ASSISTENCY",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-5081.18",
                amount_excl: "-33874.59",
                amount_incl: "-38955.77",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "JOURNALS",
                ledger_desc: "EXP50: XP DISCOUNT",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-854.49",
                amount_excl: "-5696.52",
                amount_incl: "-6551.01",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "JOURNALS",
                ledger_desc: "EXP51: XP BAD DEBT",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "-16.90",
                amount_excl: "-112.70",
                amount_incl: "-129.60",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "JOURNALS",
                ledger_desc: "EXP55: XP UNCLAIMED OVER PAYMENTS",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "3098.32",
                amount_excl: "20655.26",
                amount_incl: "23753.58",
                month_end_date: "2024-09-30",
              },
              {
                entity_no: "254",
                entity_name: "DR J A KRUGER (026)",
                tax_flag_desc: "B. Input Tax",
                transaction_desc: "JOURNALS",
                ledger_desc: "EXP61: XP HANDED OVER TO DEBT COLLECTOR",
                vat_perc: "15.00",
                vat_desc: "1. Standard",
                vat_amount: "234.96",
                amount_excl: "1566.37",
                amount_incl: "1801.33",
                month_end_date: "2024-09-30",
              },
            ],
            status: 0,
            message: "Success",
            parm_extra: "None",
          };
          if (!responseVATReport) {
            throw new Error("Failed to fetch VAT report");
          }

          const uniqueReports = [];
          const seenDates = new Set();

          responseVATReport.vat_report_data.forEach((report) => {
            if (!seenDates.has(report.month_end_date)) {
              seenDates.add(report.month_end_date);
              uniqueReports.push(report);
            }
          });

          setReports(uniqueReports);
          setSelectedDate(responseVATReport.selected_end_date);
        } else if (reportType === "Monthly") {
          response = await service.fetchMonthlyReport(year, month);
          
          // response = {
          //   selected_end_date: "2024-09-30",
          //   selected_start_date: "2024-09-01",
          //   breakdown_by_month_by_type: [
          //     {
          //       breakdown_type: "Journals",
          //       month_list: {
          //         Average: "-14219.18",
          //         September: "18874.30",
          //         August: "-19104.10",
          //         July: "-18232.26",
          //         June: "-9423.42",
          //         May: "-3005.52",
          //         April: "-2034.85",
          //         March: "-3494.33",
          //         February: "-16719.81",
          //         January: "-3655.38",
          //         December: "-10768.46",
          //         November: "-9239.78",
          //         October: "-93826.53",
          //         Total: "-170630.14",
          //       },
          //     },
          //     {
          //       breakdown_type: "Turnover",
          //       month_list: {
          //         Average: "3573698.75",
          //         September: "3840460.01",
          //         August: "4129898.01",
          //         July: "5224895.93",
          //         June: "2447413.23",
          //         May: "4068018.67",
          //         April: "3361768.00",
          //         March: "2845715.56",
          //         February: "3705174.64",
          //         January: "3108253.36",
          //         December: "2155762.36",
          //         November: "4803979.03",
          //         October: "3193046.15",
          //         Total: "42884384.95",
          //       },
          //     },
          //     {
          //       breakdown_type: "Receipting",
          //       month_list: {
          //         Average: "-3493092.63",
          //         September: "-3317114.05",
          //         August: "-4745711.60",
          //         July: "-4109105.36",
          //         June: "-2654881.29",
          //         May: "-4952665.81",
          //         April: "-2714131.57",
          //         March: "-2570130.67",
          //         February: "-3989322.18",
          //         January: "-2519505.15",
          //         December: "-2818253.18",
          //         November: "-5306040.63",
          //         October: "-2220250.11",
          //         Total: "-41917111.60",
          //       },
          //     },
          //   ],
          //   age_analysis: {
          //     total: "12223240.21",
          //     current: "3676333.61",
          //     days_30: "638778.67",
          //     days_60: "323110.47",
          //     days_90: "383719.04",
          //     days_120: "408551.96",
          //     days_150: "162673.80",
          //     days_180_plus: "6630072.66",
          //   },
          //   age_analysis_status: [
          //     {
          //       account_status: "Deceased",
          //       age_analysis: {
          //         total: "0.00",
          //         current: "0.00",
          //         days_30: "0.00",
          //         days_60: "0.00",
          //         days_90: "0.00",
          //         days_120: "0.00",
          //         days_150: "0.00",
          //         days_180_plus: "0.00",
          //       },
          //     },
          //   ],
          //   ledger: [
          //     {
          //       date: "2024-09-30",
          //       description: "OPENING BALANCE",
          //       debit: "738383.47",
          //       credit: "0.00",
          //       total: "738383.47",
          //       running_total: "738383.47",
          //     },
          //     {
          //       date: "2024-09-30",
          //       description: "DEPOSITS  CARD RECEIPTS KAS2",
          //       debit: "710277.04",
          //       credit: "1321503.00",
          //       total: "-611225.96",
          //       running_total: "795326.24",
          //     },
          //     {
          //       date: "2024-09-30",
          //       description:
          //         "DEPOSIT CORRECTIONS  ELECTRONIC REMITTANCE ADVICES KAS4",
          //       debit: "3916.80",
          //       credit: "0.00",
          //       total: "3916.80",
          //       running_total: "799243.04",
          //     },
          //     {
          //       date: "2024-09-30",
          //       description: "DEPOSIT CORRECTIONS  CARD RECEIPTS KAS2",
          //       debit: "176363.13",
          //       credit: "556.07",
          //       total: "175807.06",
          //       running_total: "975050.10",
          //     },
          //   ],
          //   status: 0,
          //   message: "Success",
          //   parm_extra: "None",
          // };
          if (!response) throw new Error("Failed to fetch Monthly report");
          const monthlyData = response?.breakdown_by_month_by_type || [];
          const { months, turnover, receipting } = formatChartData(
            response.breakdown_by_month_by_type
          );
          setChartReports({ months, turnover, receipting });
          setReports(monthlyData);
          setSelectedDate(response.selected_end_date);
          setAgeAnalysis(response.age_analysis);
          setAgeAnalysisStatus(response.age_analysis_status);
          setLedger(response.ledger);
          setSelectedDate(response.selected_end_date);
        } else if (reportType === "QR Report") {
          // response = await service.fetchQrCodeReport();
          // if (!response || !response.qr_report_data) throw new Error("Failed to fetch QR Code report");
          // setQRReports(response.qr_report_data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [reportType, month, year]);

  useEffect(() => {
    if (chartReport && reports.length > 0 && ageAnalysis) {
      if (reportType === "Monthly") {
        handleExportMonthlyPDF(true); // Generate the PDF after state updates
      } else if (reportType === "VAT Report") {
        handleExport(true);
      }
    }
  }, [chartReport, reports, ageAnalysis]);

  const handleViewDetails = () => {
    navigate("/report-details-page", {
      state: {
        reports: reports || [],
        reportType,
        month,
        year,
        ageAnalysis: ageAnalysis || {},
        ageAnalysisStatus: ageAnalysisStatus || [],
        ledger: ledger || [],
      },
    });
  };
  return (
    <div>
      <Sidebar heading={<h1 className="heading">{reportType}</h1>}>
        <div className="container mt-4">
          <NavigateBack text={"Reports"} />
          <div className="row border-bottom border-black mb-3">
            <div className="col-md-4 text-start">
              <span>Report Name</span>
            </div>
            <div className="col-md-4 text-start">
              <span>Date</span>
            </div>
            <div className="col-md-4 d-flex justify-content-end "></div>
          </div>
          <div>
            {reports.length > 0 ? (
                <div
                  className={`row mb-3 `}
                >
                  <div className="col-md-4 text-start">
                    <span>{reportType} Report</span>
                  </div>
                  <div className="col-md-4 text-start">
                    <span>{selectedDate}</span>
                  </div>
            
                  <div className="col-md-4 d-flex justify-content-end ">
                    <GenericButton
                      func={
                        reportType === "VAT Report"
                          ? ()=>handleExport(false)
                          : ()=>handleExportMonthlyPDF(false)
                      }
                      text="Download"
                      bgColor={"bg-queryFilter"}
                      styling="p-2 col-4 rounded text-center "
                    />
                    {reportType !== "VAT Report" && (
                      <GenericButton
                        func={handleViewDetails}
                        text="View"
                        bgColor={"bg-queryFilter"}
                        styling="p-2 col-4 rounded text-center "
                      />
                    )}
                  </div>
                </div>
            
            ) : loading ? <Loader loading={loading}/> : (
              <div className="row mb-3">
              <div className="col-md-12 text-center">
                <span>No reports available</span>
              </div>
            </div>
            )}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ReportDetails;
