import React from 'react';
import "../../styles/components/FinancialDashboard.scss";

function GenericMonthly({ onClick, imagePath, heading, invoiced, visits }) {
  return (
    <div onClick={onClick} className='container w-90 justify-content-center align-items-center rounded monthly-custom'>
      <div className='d-flex justify-content-between align-items-center p-2'>
        <div className='d-flex justify-content-center align-items-center bg-white monthlyIcon'>
          <img src={imagePath} style={{ objectFit: "contain" }} width={60} height={60} />
        </div>
        <div className='d-flex flex-column align-items-start'>
          <span>{heading}</span>
          <h1 className='mt-2 text-red'>{visits}</h1>
          <span>Invoiced:</span>
          <h3>R {invoiced}</h3>
        </div>
      </div>
    </div>
  );
}

export default GenericMonthly;
