import React, { useState, useEffect, useCallback } from "react";
import trashIcon from "../../assets/icons/Bin Icon.png"; // Adjust the path as needed
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import NappiCodeService from "../../services/Billing/NappiCodeService";
import { useAuth } from "../../services/authentication/LoginService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useNappiCodeStore from "../state/NappiCodeStore";
import _ from "lodash";
const NappiCodeSelector = ({ sidebar }) => {
  const { token } = useAuth();
  const nappicodeService = NappiCodeService(token);
  const [searchTermNappiCode, setSearchTermNappiCode] = useState("");
  const [filteredNappiData, setFilteredNappiData] = useState([]);
  const [showPlusIcon, setShowPlusIcon] = useState(false);
  const [allNappiData, setAllNappiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedNappiCodes, setselectedNappiCodes } =
    useProcedureCodesStore();
    const {nappiCodeFlatListVisible, setNappiCodeFlatListVisible} = useNappiCodeStore()
  //---------------------------------------------- NAPPI CODES --------------------------------------------------- //
  useEffect(() => {
    const fetchNappiCodes = async () => {
      setLoading(true);
      try {
        const codeType = 3;
        const data = await nappicodeService.NappiCodeData(
          codeType,
          searchTermNappiCode
        );

        if (data && data.itemList) {
          const nappiCodeData = Object.entries(data.itemList).map(
            ([key, value]) => ({
              code: key,
              name: value,
            })
          );

          setAllNappiData(nappiCodeData);
          setFilteredNappiData(nappiCodeData);
        } else {
          setAllNappiData([]);
          setFilteredNappiData([]);
          // setErrorMessage("Failed to fetch data");
        }
      } catch (error) {
        // setErrorMessage("Failed to fetch Nappi codes");
      } finally {
        setLoading(false);
      }
    };
    fetchNappiCodes();
  }, []);
  const handleSelectNappiCodes = (item, isManual = false) => {
    if (isManual) {
      let newSelectedCodes;
      const newItem = {
        ...item,
        quantity: item[0],
        code: item[1],
        id: item[1],
      };
      const isCodeSelected = selectedNappiCodes.some(
        (code) => code.code === newItem.code
      );
      if (isCodeSelected) {
        setShowPlusIcon(false);
        return;
      }
      newSelectedCodes = [...selectedNappiCodes, newItem];
      setselectedNappiCodes(newSelectedCodes);
      // setFieldValue("selectedNappiCodes", newSelectedCodes);
      setNappiCodeFlatListVisible(false);

      setShowPlusIcon(false);
      // setHasSelectedNappiCode(hasNappiCode);
    } else {
      const isCodeSelected = selectedNappiCodes.some(
        (code) => code.code === item.code
      );

      let newSelectedCodes;
      if (isCodeSelected) {
        newSelectedCodes = selectedNappiCodes.filter(
          (code) => code.code !== item.code
        );
      } else {
        const newItem = { code: item.code, quantity: 1 };
        newSelectedCodes = [...selectedNappiCodes, newItem];
      }
      setselectedNappiCodes(newSelectedCodes);
      setNappiCodeFlatListVisible(false);
      setSearchTermNappiCode("");
    }
  };
  const handleSearchNappiCodeInputChange = (text) => {
    if (filteredNappiData.length === 0 && text.length > 0) {
      setShowPlusIcon(true);
    } else {
      setShowPlusIcon(false);
    }
    debouncedSearchNappiCode(text);
  };
  const handleSearchNappiCodeInput = (text) => {
    setSearchTermNappiCode(text);
    if (text === "") {
      setFilteredNappiData(allNappiData);
    } else {
      const filteredData = allNappiData.filter((item) =>
        item.code.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredNappiData(filteredData);
    }
    setNappiCodeFlatListVisible(true);
  };
  const debouncedSearchNappiCode = useCallback(
    _.debounce((text) => {
      handleSearchNappiCodeInput(text);
    }, 50),
    [allNappiData]
  );
  useEffect(() => {
    return () => {
      debouncedSearchNappiCode.cancel();
    };
  }, [debouncedSearchNappiCode]);
  const handleRemoveNappiCode = (index) => {
    const updatedNappiCodes = [...selectedNappiCodes];
    updatedNappiCodes.splice(index, 1);
    setselectedNappiCodes(updatedNappiCodes);
  };
  const handleNappiCodeQuantityChange = (text, index) => {
    const quantity = parseInt(text, 10);
    if (!isNaN(quantity) && quantity >= 0) {
      const updatedNappiCodes = [...selectedNappiCodes];
      updatedNappiCodes[index].quantity = quantity;
      setselectedNappiCodes(updatedNappiCodes);
    }
  };
  return (
    <div className="row align-details">
      <div className={`${!sidebar && "col-md-4"} text-font mt-1`}>
        <label htmlFor="nappiCode">
          Material/Consumables - Enter Nappi Code
        </label>
        <div style={{ position: "relative" }}>
          <input
            placeholder="Type Here to Search or Add"
            type="text"
            value={searchTermNappiCode}
            onChange={(e) => handleSearchNappiCodeInputChange(e.target.value)}
            className="form-control"
            id="nappiCode"
            onClick={(e)=> e.stopPropagation()}
          />
          <i className="fas fa-search searchIcon"></i>
          {showPlusIcon && (
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => {   
                let item = [1, searchTermNappiCode]
                handleSelectNappiCodes(item,true)
               }
              }
              className="plus-icon"
            />
          )}
          {nappiCodeFlatListVisible && filteredNappiData.length > 0 && (
            <div className="dropdown-list">
              <ul className="suggestions-list pointer">
                {filteredNappiData.map((item) => (
                  <li
                    key={item.code}
                    onClick={() => handleSelectNappiCodes(item)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="col-md-8">
        {selectedNappiCodes.map((code, index) => (
          <div key={index} className="mb-3">
            <div className="row">
              <div className="col-12 col-md-6 mb-2 mb-md-0">
                <label className="mb-1">Code:</label>
                <input
                  type="text"
                  disabled
                  value={code.code}
                  className="form-control bg-light"
                  id={`selectedProcedureCode-${index}`} // Unique ID for accessibility
                />
              </div>

              <div className="col-12 col-md-6 mb-2 mb-md-0">
                <label className="mb-1">Quantity:</label>
                <input
                  type="number"
                  value={code.quantity || 1}
                  onChange={(e) =>
                    handleNappiCodeQuantityChange(e.target.value, index)
                  }
                  className="form-control"
                />

                <div className="col-12 text-end">
                  <img
                    onClick={() => handleRemoveNappiCode(index)}
                    className="trashcan pointer"
                    style={{ objectFit: "contain" }}
                    src={trashIcon}
                    width={25}
                    alt="Remove"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NappiCodeSelector;
