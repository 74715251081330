import React from 'react'
import "../styles/components/Footer.scss"

function MainFooter() {
  return (
    <div className='bottomContainer'>
    <div className='bottom' >
        <span className='redColor'>value </span><span className='black'> . </span>
        <span className='black'>driven </span><span className='black'> . </span>   
        <span className='grayText'>solutions</span>
    </div>
    </div>
  )
}

export default MainFooter