import React, { useState } from 'react';
import '../../../styles/components/WeightHeightPickerModal.scss';
import { useFormikContext } from 'formik';

const HeightPickerModal = ({ isVisible, onSelect, onCancel, setFieldTouched }) => {
  const [selectedMeters, setSelectedMeters] = useState(0);
  const [selectedCentimeters, setSelectedCentimeters] = useState(0);

  const calculateHeight = (metersValue, centimetersValue) => {
    const totalHeightInMeters = metersValue + (centimetersValue / 100);
    onSelect(totalHeightInMeters.toFixed(2));
  };

  const handleMetersChange = (e) => {
    const metersValue = Number(e.target.value);
    setSelectedMeters(metersValue);
    calculateHeight(metersValue, selectedCentimeters); 
  };

  const handleCentimetersChange = (e) => {
    const centimetersValue = Number(e.target.value);
    setSelectedCentimeters(centimetersValue);
    calculateHeight(selectedMeters, centimetersValue); 
  };

  const { touched, errors } = useFormikContext();

  return (
    <div className="modal-container" onClick={(e) => e.stopPropagation()}>
      <h2 className="modal-title">Select Height<span className="error-text">*</span></h2>
      <div className="picker-container">
        <select
          className="picker"
          value={selectedMeters}
          onChange={handleMetersChange}
        >
          {[...Array(3).keys()].map((m) => (
            <option key={m} value={m}>{m} m</option>
          ))}
        </select>

        <select
          className="picker"
          value={selectedCentimeters}
          onChange={handleCentimetersChange}
        >
          {[...Array(100).keys()].map((cm) => (
            <option key={cm} value={cm}>{cm} cm</option>
          ))}
        </select>
      </div>
      {touched.height && errors.height && <div className='error-text'>{errors.height}</div>}
    </div>
  );
};

export default HeightPickerModal;
