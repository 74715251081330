import axios from "axios";
import PROD_API from "../baseEndpoint";


const monthlyService = (token) => {
  const fetchMonthlyReport = async (year, month) => { 
    try {

      const apiUrl = PROD_API + `OPS/reports/get_client_monthly_report?accessToken=${token}&year=${year}&month=${month}`;
      console.log(apiUrl,"VatReport")

      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {

        return response.data
      } else {
        console.error("Failed to retrieve details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  
  return { 
    fetchMonthlyReport
   };
};

export default monthlyService;
