import React from 'react';

const EntriesDropdown = ({ entriesPerPage, onChange }) => {
  return (
    <div className="d-flex align-items-center mb-3 mb-md-0 w-100">
      <span className="mr-2">Show Entries:</span>
      <select
        className="form-control w-25"
        value={entriesPerPage}
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {[1, 2, 5, 10, 20, 30, 40, 50, 'All'].map((entry) => (
          <option key={entry} value={entry === 'All' ? -1 : entry}>
            {entry === 'All' ? 'All' : entry}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EntriesDropdown;
