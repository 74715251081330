import axios from "axios";
import PROD_API from "../baseEndpoint";

const FavouriteDetailsService = (token) => {
  const fetchFavouriteDetails = async (billingID, codeType) => {
    try {
      const apiUrl = `${PROD_API}get_billing_template_codes?accessToken=${token}&billingTemplateId=${billingID}&codeType=${codeType}`;
      const response = await axios.post(apiUrl);
      if (response.data.status === 0) { 
        return {
          itemList: response.data.itemList || {},
          parm_extra: response.data.parm_extra || ""
        };
      } else {
        return { itemList: {}, parm_extra: response.data.message }; // Return error message
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      return { itemList: {}, parm_extra: "Error" };
    }
  };

  return { fetchFavouriteDetails };
};

export default FavouriteDetailsService;
