import React,{useEffect, useState} from 'react'
import AddFavouriteService from '../services/Favourites/AddFavouriteService';
import AddFavouritesSidebar from './Invoices/AddFavouritesSidebar';
import { useAuth } from '../services/authentication/LoginService';
import SideNavbar from './common/SideNavbar';
const BillingFavourites = ({selectedICD10Codes,selectedNappiCodes,selectedProcedureCodes,isSidebarOpenAddFavourites,setIsSidebarOpenAddFavourites}) => {
    const {token} = useAuth()
    // const [isSidebarOpenAddFavourites, setIsSidebarOpenAddFavourites] = useState(false);
    const addFavouriteService = AddFavouriteService(token);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedProdCodesCopy, setSelectedProdCodesCopy] = useState(selectedProcedureCodes)
    const [loading,setLoading] = useState(false)
    const [selectedICD10CodesCopy, setSelectedICD10CodesCopy] = useState(selectedICD10Codes)
    const [selectedNappiCodesCopy, setSelectedNappiCodesCopy] = useState(selectedNappiCodes)
    const [errorFavorite, setErrorFavorite] = useState(null);
    const [description, setDescription] = useState('');
    const handleRemoveProcedureCodeFavouriteModal = (index) => {
        const updatedProcedureCodes = [...selectedProdCodesCopy];
        updatedProcedureCodes.splice(index, 1);
        setSelectedProdCodesCopy(updatedProcedureCodes);
      };
    const handleRemoveICD10Code = (index) => {
        const updatedicd10Codes = [...selectedICD10CodesCopy];
        updatedicd10Codes.splice(index, 1);
        setSelectedICD10CodesCopy(updatedicd10Codes);
      };
      const handleRemoveNappiCodesFavouriteModal = (index) => {
        const updatedNappiCodes = [...selectedNappiCodesCopy];
        updatedNappiCodes.splice(index, 1);
        setSelectedNappiCodesCopy(updatedNappiCodes);
      };
      useEffect(()=>{
        if (isSidebarOpenAddFavourites){
            setSelectedICD10CodesCopy(selectedICD10Codes)
            setSelectedNappiCodesCopy(selectedNappiCodes)
            setSelectedProdCodesCopy(selectedProcedureCodes)
        }
      },[isSidebarOpenAddFavourites])
    const handleSaveToFavorites = async (item) => {
        setLoading(true)
        const icd10Codes = selectedICD10Codes.map((code) => code.icd10Code);
        const procedureCodes = selectedProcedureCodes.map((code) => code.code);
        const nappicodes = selectedNappiCodes.map((code) => code.id);
    
        const favouriteDetails = {
          billingDesc: description,
          diagnosisCodes: icd10Codes,
          billingCodes: procedureCodes,
          nappiCodes: nappicodes,
        };
        if (!description.trim()) {
          setErrorFavorite("Description is required");
          return;
        }
        try {
          const response = await addFavouriteService.handleAddFavourite(
            favouriteDetails
          );

        } catch (error) {
          console.error("Error adding to favorites:", error);
        } finally {
          setLoading(false)
          setIsSidebarOpenAddFavourites(false)
        }
        // setFavouriteModalVisible(false);
      };
      
  const closeSidebar = () => {
    setIsSidebarOpenAddFavourites(false);
    setIsSidebarOpen(false);
  };
  return (
   <>
          <SideNavbar
                    proceed={handleSaveToFavorites}
                    state={isSidebarOpenAddFavourites}
                    close={closeSidebar}
                    heading={"Add Code Bundle to Favourites"}
                    buttonText={loading ? <i className="fas fa-spinner fa-spin"></i> : "Save Favourite"}
                    headerContent={ <div className="p-3" >
                    <div className="mb-4">
                      <span className="d-flex">
                        Enter Name of the Code Bundle
                      </span>
                      <input
                        type="text"
                        id="bundleName"
                        className="description-input"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errorFavorite && (
                        <div className="attention">
                          <span>{errorFavorite}</span>
                        </div>
                      )}
                    </div>

                    <AddFavouritesSidebar
                      heading={"ICD10 Codes"}
                      codeMap={selectedICD10CodesCopy}
                      onClickFunc={handleRemoveICD10Code}
                      type={"ICD10"}
                    />
                    {/* Render procedure codes */}
                    <AddFavouritesSidebar
                      heading={"Procedure Codes"}
                      codeMap={selectedProdCodesCopy}
                      onClickFunc={handleRemoveProcedureCodeFavouriteModal}
                    />
                    {/* Render Nappi codes */}
                    <AddFavouritesSidebar
                      heading={"Nappi Codes"}
                      codeMap={selectedNappiCodesCopy}
                      onClickFunc={handleRemoveNappiCodesFavouriteModal}
                    />
                  </div>}
                  >
                   
                  </SideNavbar>
   </>
  )
}

export default BillingFavourites