import React from "react";
import CustomScroll from "../common/CustomScroll";

const ContentModal = ({
  children,
  header,
  visible,
  closeFunc,
  buttonText = "Continue",
}) => {
  return (
    <>
      {visible && (
        <div className="modal-overlay">
          <div className="modal-content" style={{maxWidth:"90%",maxHeight:"90%"}}>
            <div className="modal-header">
              <span className=" d-flex align-items-center justify-content-center bold w-100">{header}</span>
            </div>
            <CustomScroll smallerScroll={true}>
            {children}
            </CustomScroll>
           <div className="mt-3">
           <button
              type="button"
              onClick={closeFunc}
              className="align-items-end favourite-add"
            >
              {buttonText}
            </button>
           </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentModal;
