import React from 'react';
import '../../styles/components/login.scss';

const LoginHeader = () => {
  return (
    <div className="header">
      <img
        src={require('../../assets/xpedientlogo.png')} 
        alt="Xpedient Logo"
        className="headerImage"
      />
      <h1 className="loginText">Streamline Your Practice</h1>
    </div>
  );
};

export default LoginHeader;
