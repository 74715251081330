import React, { useState, useEffect } from "react";
import ICD10Service from "../../services/Billing/ICD10Service";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/authentication/LoginService";
import NavigateBack from "../NavigateBack";
function GroupPageFromChapters() {
  const location = useLocation();
  const chapterDesc = location?.state?.chapterDesc;
  const [allICD10Data, setAllICD10Data] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [filteredICD10DataGroups, setFilteredICD10DataGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [combinedFilteredData, setCombinedFilteredData] = useState([]);
  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const icd10Service = ICD10Service(token);
  const handleCategory = (groupDesc) => {
    navigate("/categoriesfromchapters", {
      state: {
        groupDesc,
      },
    });
  };

  const searchGroups = () => {
    const codeFilter = allICD10Data.filter((each) =>
      each.groupCode.includes(searchText)
    );
    const filteredDataGroups =
      codeFilter.length > 0
        ? codeFilter
        : allICD10Data.filter((item) =>
            item.groupDesc.toLowerCase().includes(searchText.toLowerCase())
          );

    setFilteredICD10DataGroups(filteredDataGroups);
  };
  const handleSearch = useCallback(
    debounce(() => {
      searchGroups();
    }, 10),
    [searchText]
  ); // Adjust the delay as neededq
  useEffect(() => {
    handleSearch();
  }, [searchText]);
  useEffect(() => {
    setCombinedFilteredData(filteredICD10DataGroups);
  }, [filteredICD10DataGroups]);
  useEffect(() => {
    const fetchICD10Codes = async () => {
      setLoading(true);
      try {
        const data = await icd10Service.ICD10Data();

        if (data) {
          setAllICD10Data(data);
          setFilteredICD10DataGroups(data);
        } else {
          setAllICD10Data([]);
          //   setFilteredICD10Data([]);
          setErrorMessage("Failed to fetch data");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch ICD-10 codes");
      } finally {
        setLoading(false);
      }
    };

    fetchICD10Codes();
  }, []);

  const filterUniqueGroups = (data) => {
    const uniqueGroups = [];
    const groupCodes = new Set();
    const chapterCode = Array.from(chapterDesc.split("(")[1])[0];

    data.forEach((item) => {
      const groupCode = item.groupCode.replace(/\([^)]*\)/, "");
      if (!groupCodes.has(groupCode)) {
        if (Array.from(item.groupCode.split("(")[1])[0] === chapterCode) {
          uniqueGroups.push(item);
        }
        groupCodes.add(groupCode);
      }
    });

    return uniqueGroups;
  };
  return (
    <Sidebar
      heading={<h1 className="heading">ICD10 & PROCEDURE CODE SEARCH</h1>}
    >
      <div className="container mt-4">
        <NavigateBack text={"GROUPS"} />
        {/* <h4>Groups</h4> */}

        <div className="mt-4">
          <div className="lightColor centered-items mt-2">
            <div className="position-relative">
              <input
                onChange={(event) => {
                  const text = event.target.value;
                  setSearchText(text);
                }}
                type="text"
                className="border border-light lightColor input-custom"
                placeholder="Search"
                aria-label="Username"
              />
              <i
                onClick={() => searchGroups()}
                //    onClick={() => searchPatientInfo(values.patientID)}
                className="fa fa-search absolute-pos pointer"
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          {filterUniqueGroups(combinedFilteredData).map((item, index) => {
            return (
              <div
                className={`d-flex justify-content-between align-left rounded col-12 pointer ${
                  index % 2 !== 0 ? "lightgray" : ""
                }`}
                key={index}
                onClick={() => handleCategory(item.groupDesc)}
              >
                <span className="p-3 col-4 ">
                  {item.groupCode.replace(/\([^)]*\)/, "")}
                </span>
                <span className="p-3 col-4 ">{item.groupDesc}</span>

                <div className="p-3 col-4 ">
                  {item.groupCode.match(/\([^)]*\)/) && (
                    <div>{item.groupCode.match(/\(([^)]*)\)/)[1]}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Sidebar>
  );
}

export default GroupPageFromChapters;
