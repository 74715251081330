// NewsItem.js
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
function NewsItem() {

  return (
    //bg-blue
    <div className='gray-color rounded mt-3'>
    <Carousel
    indicators={false}
    pause={true}
    slide={true}
    interval={null}
    controls={false}

    >
      <Carousel.Item>
        <ExampleCarouselImage/>
      </Carousel.Item>
      <Carousel.Item>
      <ExampleCarouselImage/>
      </Carousel.Item>
      <Carousel.Item>
      <ExampleCarouselImage/>
      </Carousel.Item>
    </Carousel>
    </div>

  );
}

export default NewsItem;
