import React from 'react';

const GenericModal = ({
    heading,
    description,
    close,
    proceed,
    conditional,
    closeText ="Cancel",
    proceedText="Delete"
}) => {
  return (
    <>
      {conditional && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{heading}</h3>
            <p>{description}</p>
            <div className="modal-actions">
              <button className="cancel-button" onClick={close}>{closeText}</button>
              <button className="delete-button" onClick={proceed}>{proceedText}</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenericModal;
