import React, { useEffect, useState, useCallback } from "react";
import PatientIDService from "../../services/PatientIDService.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ServiceCenter from "../Invoices/ServiceCenter.js";
import "../../styles/components/HomescreenStyle.scss";
import { useAuth } from "../../services/authentication/LoginService.js";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Sidebar from "../Sidebar/Sidebar.js";
import "../../styles/components/NavigationButton.scss";
import usePatientDetailsStore from "../state/PatientDetails.js";
import NavigationButton from "../NavigationButton.js";
import "../../styles/components/BatchBilling.scss";
import DatePicker from "react-datepicker";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore.js";
import useServiceDateStore from "../state/ServiceDateStore.js";
import BillingButtons from "../common/BillingButtons.js";
import PatientGroupListService from "../../services/BatchBillingService/PatientGroupListService.js";
import useTreatingDoctorStore from "../state/TreatingDoctorStore.js";
import ProcessBatchService from "../../services/BatchBillingService/ProcessBatchService.js";
import NavigateBack from "../NavigateBack.js";
import TreatingDoctor from "../Invoices/TreatingDoctor.js";
import ServiceDateField from "../Invoices/ServiceDate.js";

const BatchBilling = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [fetchPatientData, setFetchPatientData] = useState(null);
  const { token } = useAuth();
  const navigate = useNavigate();
  const {defaultTreatingDoc, defaultTreatingDocID } = useTreatingDoctorStore();
  const { selectedServCenterId } = useServiceCenterCodesStore(state => state);
  const { dateToDisplay } = useServiceDateStore();
  const [isVisible, setIsVisible] = useState(false);

  //------------------------------ patinet group --------------------------------//
  const patientGroupListService = PatientGroupListService(token);
  const [patientGroups, setPatientGroups] = useState([]);
  const [patientGroupMessage, setPatientGroupMessage] = useState([]);
  const [patientListMessage, setPatientListMessage] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [isLoadingPatientLists, setIsLoadingPatientLists] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [patientLists, setPatientLists] = useState([]);
  const [radioBtnSelected, setRadioBtnSelected] = useState(false);
  const [filteredPatientGroups, setFilteredPatientGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const processBatchService = ProcessBatchService(token);
  const [selectAll, setSelectAll] = useState(false);

  //------------------------------ PATIENT LISTS CONST ---------------------------------//
  const [filteredPatientLists, setFilteredPatientLists] = useState([]);
  const [selectedCheckboxCount, setSelectedCheckboxCount] = useState(0);

  const { resetPatientDetails } = usePatientDetailsStore((state) => ({
    resetPatientDetails: state.resetPatientDetails,
  }));

  useEffect(() => {
    resetPatientDetails();
  }, []);
  //------------------------INITIAL LOAD -------------------------------//
  useEffect(() => {
    if (token) {
      const service = PatientIDService(token);
      setFetchPatientData(() => service.fetchPatientData);
    }
  }, [token]);

  useEffect(() => {
    if (defaultTreatingDoc) {
      setSearchText(defaultTreatingDoc);
    }
  }, [defaultTreatingDoc]);

  useEffect(() => {}, [defaultTreatingDoc]);

  //---------------------------------------------------- PATIENT GROUP -----------------------------------------------/
  // Fetch patient groups from API
  const fetchPatientGroups = useCallback(
    async (selectedServCenterId) => {
      try {
        if (selectedServCenterId !== null) {
          const patientGroups = await patientGroupListService.PatientGroupData(
            selectedServCenterId
          );
          if (Array.isArray(patientGroups) && patientGroups.length > 0) {
            setPatientGroups(
              patientGroups.map((group) => ({
                name: group.GroupDesc,
                id: group.GroupID,
              }))
            );
            setPatientGroupMessage("");
          } else {
            console.error("Failed to fetch patient groups or empty response.");
            setPatientGroups([]);
            setPatientGroupMessage("No patient groups found.");
          }
        }
      } catch (error) {
        console.error("Failed to fetch patient groups:", error);
        setPatientGroups([]);
      }
    },
    [patientGroupListService]
  );
  const [isPatientGroupFocused, setIsPatientGroupFocused] = useState(false);

  const handleFocus = () => {
    setIsPatientGroupFocused(true);
    setIsVisible(true);
  };

  const handleBlur = (e) => {
    // Close the dropdown only if the focus is outside the input or dropdown
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsPatientGroupFocused(false);
    }
  };
  const handleGroupSelection = async (group, setFieldValue) => {
    setSelectedGroupId(group.id);
    setSelectedGroupName(group.name);
    setIsVisible(false);
    setIsPatientGroupFocused(false);
    setFieldValue("patientGroup", group.id);

    try {
      setIsLoadingPatientLists(true);

      const patientsData = await patientGroupListService.PatientListData(
        selectedServCenterId,
        group.id
      ); // Use serviceCenterId

      if (patientsData.length === 0) {
        setPatientLists([]);
        setPatientListMessage("No patients found.");
      } else {
        setPatientLists(patientsData);
        setPatientListMessage("");
      }
      setExpanded(true);
      setFieldValue("patientGroup", group.id);
    } catch (error) {
      console.error("Failed to fetch patient lists:", error);
      setPatientLists([]);
      setPatientListMessage("Error fetching patient lists.");
    } finally {
      setIsLoadingPatientLists(false);
    }
  };

  useEffect(() => {
    fetchPatientGroups();
  }, [selectedGroupId, selectedGroupName, radioBtnSelected]);

  //------------------------------------------- PATIENT LISTS ------------------------------//
  const handlePatientSearch = (text) => {
    setSearchQuery(text);
    const filteredPatients = patientLists.filter((patient) =>
      `${patient.PatientName} ${patient.PatientSurname}`
        .toLowerCase()
        .includes(text.toLowerCase())
    );

    setFilteredPatientLists(filteredPatients);
  };
  const handleCheckboxChange = (patient, newValue, setFieldValue) => {
    if (newValue) {
      setSelectedPatients((prev) => {
        const updatedList = [
          ...prev,
          { debtor_DbId: patient.DebtorID, patient_DbId: patient.PatientID },
        ];
        setFieldValue(
          "patients",
          updatedList.map((p) => p.patient_DbId)
        );
        setSelectedCheckboxCount(updatedList.length);
        return updatedList;
      });
    } else {
      setSelectedPatients((prev) => {
        const updatedList = prev.filter(
          (p) => p.patient_DbId !== patient.PatientID
        );
        setFieldValue(
          "patients",
          updatedList.map((p) => p.patient_DbId)
        );
        setSelectedCheckboxCount(updatedList.length);
        return updatedList;
      });
    }
  };

  const handleSelectAll = (checked, setFieldValue) => {
    setSelectAll(checked);
    if (checked) {
      // Select all patients
      patientsToRender.forEach((patient) => {
        if (
          !selectedPatients.some((p) => p.patient_DbId === patient.PatientID)
        ) {
          handleCheckboxChange(patient, true, setFieldValue);
        }
      });
    } else {
      // Deselect all patients
      patientsToRender.forEach((patient) => {
        handleCheckboxChange(patient, false, setFieldValue);
      });
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = async (values, actions) => {
    // Set loading to true when the submit action is initiated
    setIsLoading(true);

    const batchDetails = {
      treating_doctorId: defaultTreatingDocID,
      service_centerId: selectedServCenterId,
      service_date: dateToDisplay,
      patient_groupId: selectedGroupId,
      batch_patients: selectedPatients.map((patient) => ({
        debtor_DbId: patient.debtor_DbId,
        patient_DbId: patient.patient_DbId,
      })),
    };

    try {
      const response = await processBatchService.ProcessBatchData(batchDetails);
      // Handle response data
      const { parm_extra } = response;

      // Navigate to confirmation page with success message
      navigate("/batchBillingconfirmation", {
        state: {
          batchDetails,
          message: "Batch details submitted successfully!",
          parm_extra: parm_extra,
        },
      });
    } catch (error) {
      // Handle any errors that occur during the submission
      console.error("Failed to submit batch details:", error.message);
      alert("Failed to submit batch details. Please try again later.");
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  
  useEffect(() => {
    if (selectedServCenterId !== null) {
      fetchPatientGroups(selectedServCenterId);
    }
  }, [selectedServCenterId]); 
  
  useEffect(() => {
    if (selectedServCenterId !== null) {
    }
  }, [selectedServCenterId]);
  
  const patientsToRender =
    searchQuery.length > 0 ? filteredPatientLists : patientLists;

  //------------------------ SUBMISSION -------------------------------//

  return (
    <div>
      <Sidebar heading={<h1 className="heading">BATCH BILLING</h1>}>
        <div className="container mt-4">
          <NavigateBack text={"Batch Billing"} />
          <BillingButtons />
          <Formik
            initialValues={{
              patientID: "",
              serviceCenter: "",
              treatingDoctor: defaultTreatingDoc || "",
              servCenterName: "",
              servCenterType: "",
              serviceCenterId: "",
              patientGroup: "",
              patients: [],
            }}
            validationSchema={Yup.object({})}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, handleSubmit, values }) => (
              
              <Form onSubmit={handleSubmit} className="search-padding">
                <div className="row">
                  <TreatingDoctor treatingDoctor={defaultTreatingDoc} />
                  <div className="col-12 col-lg">
                    <ServiceCenter
                      serviceCenterType={values.servCenterType}
                      serviceCenterName={values.servCenterName}
                      serviceCenterDefault={values.serviceCenter}
                      hasAuth={false}                 
                      />
                  </div>
                  <div className="col-12 col-lg">
                    <label htmlFor="patientGroup">Patient Group<span className="error-text">*</span></label>
                    <div
            onBlur={handleBlur}
            tabIndex={-1} // Makes the div focusable to detect blur
            style={{ position: "relative" }}
          >
            <Field
              type="text"
              name="patientGroup"
              className="form-control"
              id="patientGroup"
              value={selectedGroupName.toUpperCase()}
              placeholder="Select a Patient Group"
              onFocus={handleFocus}
              readOnly // Prevent typing since selection happens via dropdown
              // onFocus={() => setIsVisible(true)} // Show dropdown on focus
    onBlur={() => setTimeout(() => setIsVisible(false), 150)}
            />
            {isPatientGroupFocused && selectedServCenterId !== null && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  width: "100%",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {patientGroups.map((group) => (
                    <li
                      key={group.id}
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc",
                      }}
                      onClick={() => handleGroupSelection(group, setFieldValue)}
                    >
                      {group.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg">
                    <label htmlFor="patientList">Patient List</label>
                    <div>
                      <Field
                        type="text"
                        name="patientSearch"
                        className="form-control"
                        id="patientGroup"
                        placeholder="Search Patients"
                        value={searchQuery}
                        onChange={(e) => handlePatientSearch(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg">
                    <p className="mt-5">
                      Patients Checked:
                      <span className="checkedcolor">
                        {" "}
                        {selectedPatients.length}
                      </span>
                    </p>
                  </div>

                  <ServiceDateField
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className="selectedpatientscontainer">
                  {/* Select All Checkbox */}
                  {patientsToRender.length > 0 && (
                    <div className="col selectall">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) =>
                          handleSelectAll(e.target.checked, setFieldValue)
                        }
                        className="checkstyle"
                      />
                      <span className="fw-bold">SELECT ALL</span>
                    </div>
                  )}

                  {/* First half of patients */}
                  <div style={{ display: "flex", padding: 10 }}>
                    {patientsToRender
                      .slice(0, Math.ceil(patientsToRender.length / 2))
                      .map((patient) => (
                        <div
                          className="col-4"
                          key={patient.PatientID}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedPatients.some(
                              (p) => p.patient_DbId === patient.PatientID
                            )}
                            onChange={(e) =>
                              handleCheckboxChange(
                                patient,
                                e.target.checked,
                                setFieldValue
                              )
                            }
                            className="checkstyle"
                          />
                          <span>
                            {patient.PatientName.toUpperCase()}{" "}
                            {patient.PatientSurname.toUpperCase()}
                          </span>
                        </div>
                      ))}
                  </div>

                  {/* Second half of patients */}
                  <div style={{ display: "flex", padding: 10 }}>
                    {patientsToRender
                      .slice(Math.ceil(patientsToRender.length / 2))
                      .map((patient) => (
                        <div
                          className="col-4"
                          key={patient.PatientID}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedPatients.some(
                              (p) => p.patient_DbId === patient.PatientID
                            )}
                            onChange={(e) =>
                              handleCheckboxChange(
                                patient,
                                e.target.checked,
                                setFieldValue
                              )
                            }
                            className="checkstyle"
                          />
                          <span>
                            {patient.PatientName.toUpperCase()}{" "}
                            {patient.PatientSurname.toUpperCase()}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-center">
                    <div className="w-25">
                      <NavigationButton
                        color="profileColor"
                        header={isLoading ? "Loading" : "Submit"}
                        handleClick={handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </div>
  );
};

export default BatchBilling;
