import React from "react";
import backBtnQuery from "../../assets/backBtnQuery.png";
import newQuery from "../../assets/newQuery.png";
import respondedtoXpedient from "../../assets/respondedtoXpedient.png";
import { useNavigate } from "react-router-dom";
import billing2 from '../../assets/billing2.png';
import resolvedQuery from "../../assets/icons/Green Tick Icon.png";
const BillingQueriesDropdownData = ({ subTasks, caseData }) => {
  const navigate = useNavigate();
  const handleNavigateToQueryDetail = (item, subTask) => {
    const { InvoiceType } = item; // Destructure InvoiceType from the item object
    navigate("/billingqueriesopen", {
      state: { item, invoiceType: InvoiceType, invoiceReference: subTask },
    }); // Pass invoiceType along with item
  };
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <tbody>
          {subTasks.map((item, index) => (
            <tr
              key={index}
              className={
                index % 2 === 0 ? "bg-white col-12" : "bg-light-gray col-12"
              }
            >
              <td className="align-middle col-1">
                {item.SubtaskStatusDesc === "New Query" && (
                  <img width={30} src={newQuery} alt="New Query" />
                )}
                {item.SubtaskStatusDesc === "Responded to Xpedient" && (
                  <img
                    width={30}
                    src={respondedtoXpedient}
                    alt="Responded to Xpedient"
                  />
                )}
                {item.SubtaskStatusDesc === "Response Required" && (
                  <img width={30} src={backBtnQuery} alt="Response Required" />
                )}
                {item.SubtaskStatusDesc === "Resolved" && (
                  <img width={30} src={resolvedQuery} alt="Resolved" />
                )}
                {item.SubtaskStatusDesc === "In Progress" && (
                  <img width={30} src={billing2} alt="In Progress" />
                )}
              </td>
              <td className="col-4">
                <span>Ticket Ref No | </span>
                <span className="fw-bold">{item.SubTaskID}</span>
              </td>
              <td className="col-4">
                <span
                  className={`me-3 ${
                    item.SubtaskStatusDesc === "New Query" ? "query-txt" : ""
                  } 
                                      ${
                                        item.SubtaskStatusDesc ===
                                        "Responded to Xpedient" || "Resolved"
                                          ? "query-text-green" 
                                          : ""
                                      } 
                                      ${
                                        item.SubtaskStatusDesc ===
                                        "In Progress" 
                                          ? "text-dark" 
                                          : ""
                                      } 
                                      ${
                                        item.SubtaskStatusDesc ===
                                        "Response Required"
                                          ? "query-text-orange"
                                          : ""
                                      }`}
                >
                  {item.SubtaskStatusDesc}
                </span>
              </td>
              <td className="align-middle col-2">{item.SubTaskCreatedDate}</td>
              <td className="align-middle col-1">
                <button
                  className="btn view-btn"
                  onClick={() => handleNavigateToQueryDetail(item, caseData)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingQueriesDropdownData;
