import axios from "axios";
import PROD_API from "../baseEndpoint";

const ClinicalNoteHistoryService = (token) => {
  const fetchClinicalHistory = async (patientId,startDate, endDate, searchparm = "") => {
    try {
      const apiUrl = PROD_API + `get_patient_clinical_notes?accessToken=${token}&patientSaId=${patientId}&startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.post(apiUrl);

  
      if (response.status === 200) {
        const { data, parm_extra, status, message } = response.data;
        return {
          patient_notes: response.data.patient_notes || [],
          data,
          status,
          parm_extra,
          message,
        };
      }else {
        console.error("Failed to retrieve invoice details. Status code:", response.status);
        return { patient_notes: [], parm_extra: "No data" };
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      return { patient_notes: [], parm_extra: "Error" }; 
    }
  };
  
  return { fetchClinicalHistory };
};

export default ClinicalNoteHistoryService;
