import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import ReportItem from "./ReportItem";
import SideNavbar from "../../common/SideNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import MonthlyReports from "./MonthlyReports";
import NavigateBack from "../../NavigateBack";
import SavedReportsService from "../../../services/Reports/getSavedReports";
import { useAuth } from "../../../services/authentication/LoginService";
import GenericButton from "../../GenericButton";
import Loader from "../../../utils/Loader";

const Reports = () => {
  const [generateReport, setGenerateReport] = useState(false);
  const { token } = useAuth()
  const [selectMonthly, setSelectMonthly] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const [savedReports,setSavedReports] = useState([])
  const {getSavedReports} = SavedReportsService(token)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); 

  const years = [];
  const startYear = 2018; // Set the starting year to 2018
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleReportTypeChange = (e) => setSelectedReportType(e.target.value);
  const handleMonthChange = (e) => setSelectedMonth(e.target.value);
  const handleYearChange = (e) => setSelectedYear(e.target.value);

  // Proceed button handler
  const handleProceed = () => {
    if (selectedReportType && selectedMonth && selectedYear) {
      navigate('/reportDetails', {
        state: {
          reportType: selectedReportType,
          month: selectedMonth,
          year: selectedYear,
        },
      });
      setGenerateReport(false); 
    } else {
      alert("Please select all fields.");
    }
  };
  const getReports = async (type,reportIndex) => {
   try {
    setLoading(true)
    const reports = await getSavedReports();
  
    navigate("/savedreports",{state:{
      reports,
      reportType:type,
      reportIndex //index of report in the result array
    }})
   }catch (e) {
    console.error("Failed to Fetch Reports")
   }finally{
    setLoading(false)
   }


  
    } 

  return (
    <Sidebar heading={<h1 className="heading">Reports</h1>}>
    <div className="container">
      <div className="backbtnContainer bold-txt my-2"></div>
      {selectMonthly ? (
        <>
        <div className="d-flex">
        <button
            type="button"
            className="btn "
            onClick={() => setSelectMonthly(false)}
          >
            <i
              style={{ color: "black" }}
              className="fa fa-arrow-left"
              aria-hidden="true"
            ></i>{" "}
            Back
          </button>
        </div>
          <MonthlyReports />
        </>
      ) : (
        <div>
            <span>Generate and access the latest report with up-to-date information</span>
          <div className="d-flex justify-content-center align-items-center">
          
            <GenericButton func={()=> setGenerateReport(true)} text="Generate Report" styling="col-11 formButton" />
          </div>
          <div className="row">
          <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"MONTHLY REPORTS"}
              img={require("../../../assets/ClientPortal/MonthlyReports.png")}
              bgColor={"profileColor"}
              click={()=>getReports("Monthly",2)}
            />
            </div>
            <Loader loading={loading}/>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"VAT REPORTS"}
              img={require("../../../assets/ClientPortal/VatReports.png")}
              click={()=>getReports("VAT Reports",5)}
            />
                   </div>
                   <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"QR REPORTS"}
              img={require("../../../assets/ClientPortal/qrReports.png")}
          
            />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"ASSISTANT REPORTS"}
              img={require("../../../assets/ClientPortal/assistantReports.png")}
         
            />
            </div>
            <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"ANALYSIS & RECOMMENDATIONS"}
              img={require("../../../assets/ClientPortal/analysisReccomendations.png")}
            />
                </div>
                <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
            <ReportItem
              reportName={"CUSTOM REPORTS"}
              img={require("../../../assets/ClientPortal/customReport.png")}
            />
          </div>
          </div>
        </div>
      )}
     {/* SideNavbar for selecting report details */}
     <SideNavbar
        state={generateReport}
        heading="Generate Report"
        close={() => setGenerateReport(false)}
        proceed={handleProceed} 
      >
        <div className="d-flex flex-column paddingBox">
          <label>Type of Report</label>
          <select className="selectOption" value={selectedReportType} onChange={handleReportTypeChange}>
            <option value="">Select Report</option>
            <option value="Monthly">Monthly</option>
            <option value="VAT Report">VAT Report</option>
            <option value="QR Report">QR Report</option>
            <option value="Assistant Report">Assistant Report</option>
          </select>

          <label>Select Month</label>
          <select className="selectOption" value={selectedMonth} onChange={handleMonthChange}>
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={index}>{month}</option>
            ))}
          </select>

          <label>Select Year</label>
          <select className="selectOption" value={selectedYear} onChange={handleYearChange}>
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
      </SideNavbar>
    </div>
    </Sidebar>
  );
};

export default Reports;
