import { create } from 'zustand';



const useActiveTab = create((set) => ({
    storeActiveTab: 'clientportal',
    expandedTab:false,
    currentActiveButton:"Client Portal",
    isBilling:false,
    setActiveTab: (storeActiveTab) => set({ storeActiveTab }),
    setIsBilling: (isBilling) => set({ isBilling }),
    setExpandedTab: (expanded) => set({expanded}),
    setCurrentActiveButton:(currentActiveButton)=>set({currentActiveButton})
      
  }));
  


export default useActiveTab