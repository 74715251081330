import axios from "axios";
import PROD_API from "../baseEndpoint";
 
const ServCenterService = (token) => {
  const ServiceCenterData = async () => {
    if (token) {
      try {
        const apiUrl = PROD_API + `GoodX/get_service_centers?accessToken=${token}`;
        const response = await axios.post(apiUrl);
 
        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const centerData = response.data.data.map(item => ({
            id: item.id,
            name: item.description, 
          }));
          return centerData;
 
        } else {
          console.error(
            "Failed to fetch data. Unexpected status code:",
            response.status
          );
          return [];
        }
      } catch (error) {
        console.error("Failed to fetch serice center data. An error occurred:", error);
        return [];
      }
    } else {
      console.error("Token is not valid or missing.");
      return [];
    }
  };
 
  return {
    ServiceCenterData,
  };
};
 
export default ServCenterService;
