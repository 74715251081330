import React, { useEffect, useState } from "react";
//import { useAuth } from '../../services/Authentication/AuthServices';
import Sidebar from "../Sidebar/Sidebar.js"
import '../../styles/components/login.scss';
import NavigationButton from '../NavigationButton.js';
import { useLocation } from 'react-router-dom';
const BatchBillingConfirmation = () => {
    //const navigation = useNavigation();
    //const { token } = useAuth();
    const location = useLocation();
    const parm_extra = location?.state?.parm_extra || "";
 
    return (
        <Sidebar>
            <div className="container">
                <div className="margintopsuccess">
                    <div className="text-white d-flex align-items-center justify-content-center">
                        <img
                            width={100}
                            height={100}
                            style={{ objectFit: "contain" }}
                            src={require("../../assets/success-green-check-mark-icon.png")}
                        />
                    </div>

                    <div className="mt-4 mb-5 text-white d-flex align-items-center justify-content-center">
                        <p style={{ fontWeight: "bold", color: 'black' }}>{parm_extra}</p>  
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="addadjustmentnote"
                        >
                            <div className="text-white d-flex align-items-center justify-content-center">
                                <img
                                    width={22}
                                    height={22}
                                    style={{ objectFit: "contain", filter: "invert(1)" }}
                                    src={require("../../assets/icons/Add Clinical Note to Invoice Icon.png")}
                                />
                                <span className='padleftt'>Submit an Invoice Adjustment Note</span>
                            </div>
                        </button>
                    </div>

                    <div className="mt-5 text-white d-flex align-items-center justify-content-center">
                        <p style={{ color: 'black' }}>
                            Submit Another Invoice
                        </p>
                    </div>

                    <div className="responsive-flex">
                        <NavigationButton header={"Same Patient"} color={"white"} img={require("../../assets/icons/New Patient Icon.png")} route={"patientsearch"} />
                        <NavigationButton header={"New Patient"} color={"white"} img={require("../../assets/icons/New Patient Icon-1.png")} route={"patientsearch"} />
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};

export default BatchBillingConfirmation;

