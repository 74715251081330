import React, { useState } from 'react'
import generatePDF from 'react-to-pdf'
const DownloadInvoiceModal = ({reference,visible,toggleFunc}) => {
    const [name,setName] = useState("InvoiceDocument")
    const generatePdf = () => {
        generatePDF(reference,{filename:`${name}.pdf`})  
        toggleFunc(false)
    }
  return (
    visible && (
        <div className='backdrop'>
        <div className="card">
        <div className='p-3 d-flex align-items-center'>
    <label className='mr-3' htmlFor='fileName'>File Name:</label>
    <input 
        id='fileName'
        onChange={(e) => setName(e.target.value)} 
        type='text' 
        className='form-control mr-2' 
        placeholder='ex. InvoiceDocument' 
        style={{ width: '250px' }} 
    />
    <button 
        onClick={generatePdf} 
        className='btn btn-success text-white'>
        Download
    </button>
    <div 
        onClick={() => toggleFunc(false)} 
        className='position-absolute' 
        style={{ right: 7, top: 0 }}>
        <i className='fa fa-times'></i>
    </div>
</div>
        </div>
    </div>
    )
  )
}

export default DownloadInvoiceModal