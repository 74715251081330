import React from 'react'
import CustomScroll from '../../common/CustomScroll'
import InfiniteScroll from 'react-infinite-scroll-component'

const ChaptersCodeList = ({ filterUniqueChapters, filteredICD10DataChapters, handleFieldClick }) => {
  return (
    <div className="mt-2">
      {/* <CustomScroll maxHeight="50vh" smallerScroll={true}> */}
      <InfiniteScroll
          height={"50vh"}
          dataLength={filteredICD10DataChapters.length}
          loader={<i className="fas fa-spinner fa-spin"></i>}
        >
        {filterUniqueChapters(filteredICD10DataChapters).length > 0 ? (
          filterUniqueChapters(filteredICD10DataChapters).map((item, index) => (
            <div
              className={`d-flex  align-items-center rounded pointer row ${
                index % 2 !== 0 ? "lightgray" : ""
              }`}
              key={index}
              onClick={() => handleFieldClick(item.chapterDesc)}
            >
              <div className="col-1 p-2 text-center">
                <span>{item.chapterNo.replace("CHAPTER ", "")}</span>
              </div>
              <div className="col-4 p-2 text-center">
                <span>{item.chapterDesc.match(/\(([^)]*)\)/)?.[1] || ""}</span>
              </div>
              <div className="col-7 p-2 text-end">
                <span>{item.chapterDesc.replace(/\([^)]*\)/, "")}</span>
              </div>
             
            </div>
          ))
        ) : (
          <span>No chapters available</span>
        )}
      </InfiniteScroll>
    </div>
  )
}

export default ChaptersCodeList
