import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const CustomDropDownItem = ({nav,iconName,iconColor,name,borderless = false}) => {
  return (
    <>
<div onClick={nav} className={`d-flex flex-start py-2 ${!borderless ? 'border-bottom' : ''}`}>

        <div>
        <FontAwesomeIcon color={iconColor} icon={iconName} />
        <small className='px-2'>{name}</small>
        </div>
      </div>
    </>
  )
}

export default CustomDropDownItem