import React from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import "../../styles/components/CodeSearch.scss"
import NavigateBack from '../NavigateBack'
function MoreCategories() {
    const location = useLocation()
    const categoryData = location?.state?.item
    const code = categoryData[1]
    const icdcodename =  categoryData[0]
    const icdchapter = categoryData[2]
    const icdgroup = categoryData[3]
        return (
      <Sidebar heading={<h1 className="heading">ICD10 & PROCEDURE CODE SEARCH</h1>}>
      <div className='container mt-4'>
                <NavigateBack
                text={"Codes"}
                />
        <div className='d-flex flex-column text-start'>
          <div className='headingContainer'>
            <span className='error-text py-1 px-2'>Code: {code}</span>
            <div className='py-1 px-2'>
              <span>{icdcodename}</span>
            </div>
          </div>
          <div className='mt-3'>
            <span className='text-secondary'>Chapter</span>
            <div>
              <span>{icdchapter}</span>
            </div>
          </div>
          <div className='mt-3'>
            <span  className='text-secondary' >Group</span>
            <div>
              <span>{icdgroup}</span>
            </div>
          </div>
          <div className='mt-3'>
            <span  className='text-secondary'>Category</span>
            <div>
              <span>{icdcodename}</span>
            </div>
          </div>
        </div>
      </div>

      </Sidebar>
        )
      }

export default MoreCategories