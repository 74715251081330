import axios from "axios";
import PROD_API from "../baseEndpoint";


const SavedReport = (token) => {
  const getSavedReport = async (directory, report_type) => { 
    try {

      const apiUrl = PROD_API + `OPS/reports/get_saved_report?accessToken=${token}&dicrectory_name=${directory}&file_name=${report_type}`;
      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {
       
       

        return response.data
      } else {
        console.error("Failed to retrieve details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  
  return { 
    getSavedReport
   };
};

export default SavedReport;
