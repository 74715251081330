import axios from "axios";
import PROD_API from "../baseEndpoint";


const AgeAnalysisService = (token) => {
  const fetchAgeAnalysis = async (selectedDate) => { 
    try {

      const apiUrl = PROD_API + `/OPS/reports/get_age_analysis?accessToken=${token}&end_date=${selectedDate}`;

      const response = await axios.post(apiUrl);
      if (response.status === 200 && response.data) {
        return response.data
      } else {
        console.error("Failed to retrieve invoice details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };
  
  return { 
    fetchAgeAnalysis
   };
};

export default AgeAnalysisService;
