import { useFormikContext } from 'formik';
import React from 'react'
import DatePickerMultiple from "react-multi-date-picker";
const MultipleDatePicker = () => {
    const {values} = useFormikContext()
  return (
    <div className="row align-details">
    <div className="mb-3 text-font">
      <label htmlFor="mobileNumber">
        Repeat Treatment for Multiple Dates
      </label>
      <div className="position-relative">
        <input
          className="form-control bg-white"
          disabled
        ></input>

        <DatePickerMultiple
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            left: 10,
            width: "90%",
            height: "55%",
            cursor: "pointer",
          }}
          multiple
          calendarPosition="top-center"
          value={values.serviceDates}
          format="YYYY-MM-DD"
        />
        <img
          alt=""
          src={require("../../assets/icons/Calendar Icon.png")}
          style={{
            objectFit: "contain",
            position: "absolute",
            right: 10,
            top: 7,
          }}
          height={25}
        />
      </div>
    </div>
  </div>
  )
}

export default MultipleDatePicker