import React from "react";

const SearchGuidance = () => {
  return (
    <div className="profileColor p-4">
      <h3>HOW CAN WE HELP YOU TODAY?</h3>
      <input className="rounded p-2 text-center" placeholder="Search" />
    </div>
  );
};

export default SearchGuidance;
