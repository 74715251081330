import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import { ErrorMessage } from "formik";
import MedicalSchemeOption from "../../../services/Billing/MedicalSchemeOption";
import { useAuth } from "../../../services/authentication/LoginService";
import debounce from "lodash.debounce";
import { useCallback } from "react";
import { useRef } from "react";
const MedicalAidFields = () => {
  const { setFieldValue, handleChange } = useFormikContext();
  const { token } = useAuth();
  const medicalSchemeOption = MedicalSchemeOption(token);
  const [searchScheme, setSearchScheme] = useState("");
  const [isSchemeFlatListVisible, setIsSchemeFlatListVisible] = useState(false);
  const [flatListSelectPlan, setflatListSelectPlan] = useState(false);
  const [medicalSchemeData, setMedicalSchemeData] = useState([]);

  const [copiedPlans, setCopiedPlans] = useState([]);
  const optionsRef = useRef([]);
  const [error, setError] = useState(null);
  const [selectedScheme, setselectedScheme] = useState([]);
  const debouncedSetSchemes = useCallback(
    debounce(async (text, setFieldValue) => {
      try {
        const schemeData = await medicalSchemeOption.fetchSchemes();
        if (Array.isArray(schemeData)) {
          const filteredSchemes = schemeData.filter((item) =>
            item.name.toLowerCase().includes(text.toLowerCase())
          );

          filteredSchemes.sort((a, b) => a.name.length - b.name.length);
          setMedicalSchemeData(filteredSchemes);
        }
      } catch (error) {
        setError(error.message);
      }
    }, 500),
    []
  );
  const fetchPlans = async (code) => {
    try {
      const data = await medicalSchemeOption.fetchOptions(code);
      optionsRef.current = [];
      optionsRef.current.push(data);
      setCopiedPlans(data);
      setflatListSelectPlan(true);
    } catch (err) {
      setError(err.message);
    }
  };
  const handleSearchSchemes = (text, setFieldValue) => {
    setSearchScheme(text);
    if (text.length >= 2) {
      debouncedSetSchemes(text);
      setIsSchemeFlatListVisible(true);
      setFieldValue("selectPlan", "");
      setflatListSelectPlan(false);
    } else {
      setMedicalSchemeData([]);
      setIsSchemeFlatListVisible(false);
    }
  };
  const handleSelectScheme = (item, setFieldValue) => {
    if (selectedScheme.length > 0 && selectedScheme.id === item.id) {
      setselectedScheme(null);
      setSearchScheme("");
    } else {
      setFieldValue("selectPlan", "");
      setFieldValue("medicalAidSelectFromList", item.name);

      setselectedScheme(item);

      setSearchScheme(item.name);
      setIsSchemeFlatListVisible(false);
      fetchPlans(item.code);
    }
  };
  const handleSelectPlan = (item, setFieldValue) => {
    setFieldValue("selectPlan", item.option_name);
    setflatListSelectPlan(false);
  };
  
  return (
    <div className="row">
      <div style={{ position: "relative" }} className="'col-12 col-lg">
        <label htmlFor="Medical Aid Option">Medical Aid Select From List</label>
        <Field
          type="text"
          name="medicalAidSelectFromList"
          className="form-control py-4"
          id="medicalAidSelectFromList"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue("medicalAidSelectFromList", value);
            handleSearchSchemes(value, setFieldValue);
          }}
        />
        <ErrorMessage
          name="medicalAidSelectFromList"
          component="div"
          className="error-text d-flex"
        />
        {medicalSchemeData &&
          isSchemeFlatListVisible &&
          medicalSchemeData.length > 0 && (
            <div className="dropdown-list">
              {/* {isLoading && <div>Loading...</div>} */}
              <ul>
                {medicalSchemeData.map((item) => (
                  <li
                    key={item.code}
                    onClick={() => handleSelectScheme(item, setFieldValue)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}   
      </div>
      <div style={{ position: "relative" }} className="'col-12 col-lg">
        <label htmlFor="Medical Aid Plan">Select Plan</label>
        <Field
          type="text"
          name="selectPlan"
          className="form-control py-4"
          id="selectPlan"
          onChange={(text) => {
            handleChange("selectPlan")(text);
          }}
        />
        <ErrorMessage
          name="selectPlan"
          component="div"
          className="error-text d-flex"
        />
        {flatListSelectPlan && (
          <div className="dropdown-list">
            <ul>
              {copiedPlans.map((item, index) => (
                <li
                  onClick={() => handleSelectPlan(item, setFieldValue)}
                  className="pointer"
                  key={index}
                >
                  {item.option_name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="'col-12 col-lg">
        <label htmlFor="Medical Aid Number">Medical Aid Number</label>
        <Field
          type="text"
          name="medicalAidNumber"
          className="form-control py-4"
          id="medicalAidNumber"
          onChange={(text) => {
            handleChange("medicalAidNumber")(text);
          }}
        />
        <ErrorMessage
          name="medicalAidNumber"
          component="div"
          className="error-text d-flex"
        />
      </div>
    </div>
  );
};

export default MedicalAidFields;
