import React from 'react'
import CustomScroll from '../../common/CustomScroll';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from "lodash";
import GenericSearch from '../../GenericSearch';
import ModifiersService from '../../../services/Billing/ModifiersService';
const ModifiersCodeList = () => {
  const modifierService = ModifiersService();
  const [loading,setLoading] = useState(false)
  const [filteredModifierData, setFilteredMofifierData] = useState([]);
  const [modifierData, setModifierDate] = useState([]);
  const [errorMessage,setErrorMessage] = useState("")
  const [searchText,setSearchText] = useState("")
  const navigate = useNavigate()

  const handleSearch = useCallback(
    debounce(() => {
      if (searchText.length === 0) return;
          searchModifiers();
    }, 10),
    [searchText]
  ); // Adjust the delay as needed
  useEffect(() => {
    if (searchText.length === 0) {
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    const fetchModifiers = async () => {
      setLoading(true);
      try {
        const data = await modifierService.ModifiersData();
        if (data) {
          setFilteredMofifierData(data);
          setModifierDate(data);
        } else {
          setModifierDate([]);
          setErrorMessage("Failed to fetch procedure codes");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch procedure codes");
      } finally {
        setLoading(false);
      }
    };
    fetchModifiers();
  }, []);


  const navigateMore = (desc,code) => {
    navigate("/moreinfocode",{state:{
      paragraph:desc,
      code,
      heading:"Modifiers",
      navigateBackText:"Modifiers"
      
    }})
  }

    const searchModifiers = () => {
    const codeFilter = modifierData.filter((each) =>
      each.modifierCode.includes(searchText)
    );

    // If nothing is found with code, then filter by description
    const filter =
      codeFilter.length > 0
        ? codeFilter
        : modifierData.filter((each) =>
            each.modifierDescription
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );

    setFilteredMofifierData(filter);
  };
  return (
    <>
        <GenericSearch
          handleChange={(event) => setSearchText(event.target.value)}
          values={searchText}
          placeholder='Search Modifers'
        />
  <CustomScroll smallerScroll={true} maxHeight={"50vh"}>
              {filteredModifierData.map((item, index) => {
                return (
                  <div
                  onClick={()=>navigateMore(item.modifierDescription,item.modifierCode)}
                    className={`d-flex justify-content-between align-left rounded pointer col-12 ${
                      index % 2 !== 0 ? "lightgray" : ""
                    }`}
                    key={index}
                  >

                    <span className="p-3 col-2">{item.modifierCode}</span>
                    <span className="text-left p-3 col-10">
                      {item.modifierDescription.substring(0, 250) + "..."}
                    </span>
                  </div>
                );
              })}
            </CustomScroll>
    </>
  
  )
}

export default ModifiersCodeList