import axios from 'axios';
import PROD_API from "../baseEndpoint";

const DeleteFavouriteService = (token) => {
    const handleDeleteFavourite = async (templateID) => {
        const apiUrl = `${PROD_API}delete_billing_template?accessToken=${token}&billingTemplateId=${templateID}`;

        const headers = {
            'Content-Type': 'application/json',
        };

        try {

            const response = await axios.post(apiUrl, {}, { headers });

            if (response.status === 200) {
                const { data, parm_extra, status, message } = response.data;
                return {
                    data,
                    status,
                    parm_extra,
                    message,
                };
            } else {
                const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error('Error during API call:', error);
            throw error;
        }
    };
    const handleDeleteCodeFavourite = async (templateID, codeSelectedId, codeType) => {
        const apiUrl = `${PROD_API}delete_billing_template_code?accessToken=${token}&billingTemplateId=${templateID}&codeId=${codeSelectedId}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        try {

            const response = await axios.post(apiUrl, {}, { headers });

            if (response.status === 200) {
                const { data, parm_extra, status, message } = response.data;
                return {
                    data,
                    status,
                    parm_extra,
                    message,
                };
            } else {
                const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error('Error during API call:', error);
            throw error;
        }
    };

    return { handleDeleteFavourite, handleDeleteCodeFavourite };
};

export default DeleteFavouriteService;
