import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/components/CustomScroll.scss";

function CustomScroll({ children, maxHeight='', smallerScroll=false }) {
  const containerStyle = {
    maxHeight: maxHeight,
  };

  const containerClass = smallerScroll 
    ? 'custom-smaller-scroll-container' 
    : 'custom-scroll-container';

  return (
    <div className={containerClass} style={containerStyle}>
      {children}
    </div>
  );
}


export default CustomScroll;
