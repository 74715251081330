import React from 'react';
import { Spinner } from 'react-bootstrap';
import "../styles/components/Loader.scss"
const Loader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="loading-overlay">
    <div className="loading-content">
      <img src={require("../assets/xpedient_logo(white).png")} alt="Xpedient Logo" width={150} />
      <h5 className="mt-3">Loading Please Wait</h5>
      <div className="dots">
        <Spinner animation="grow" className='redDot' size="md" />
        <Spinner animation="grow" className='grayDot' size="md" />
        <Spinner animation="grow" className='blackDot' size="md" />
      </div>
    </div>
  </div>
  );
};

export default Loader;