import React from 'react';

const ConfirmationPageNewPatient = () => {
  return (
    <div className='d-flex justify-content-center mt-2'>
      <img width={50} height={50} src={require("../../assets/success-green-check-mark-icon.png")} alt="Success check mark" />
      <span>Success</span>
    </div>
  );
}

export default ConfirmationPageNewPatient;
