import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import MainHeader from '../common/MainHeader';
import debounce from 'lodash/debounce';
import TreatingDocService from '../../services/Billing/TreatingDocService';
import ServCenterService from '../../services/Billing/ServiceCenterService';
import ReferringDocService from '../../services/Billing/ReferringDocService';
import { useAuth } from "../../services/authentication/LoginService";
import '../../styles/components/invoice.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function InvoiceStepOne() {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate('/invoicesteptwo', {
      refDocNameSurname: surnameAndName || '',
      //name: values.referringDoctor || "",
    });
  };

  const { token } = useAuth();
  //   const { id } = useParams(); 
  //   const routeParams = route.params || {};
  const [searchText, setSearchText] = useState('');
  const [filteredTDoc, setFilteredTDoc] = useState([]);
  const [tDocData, settDocData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  //------------------------TREATING DOCTOR CONST -------------------------------//
  const treatingDoctor = TreatingDocService(token);
  const [isTreatDocFlatListVisible, setIsTreatDocFlatListVisible] = useState(false);
  const [selectedTreatingDoc, setSelectedTreatingDoc] = useState(null);
  const [defaultTreatingDoc, setDefaultTreatingDoc] = useState('');
  const [defaultTreatingDocID, setDefaultTreatingDocID] = useState('');
  const [treatingDoctorSelected, setTreatingDoctorSelected] = useState(false);

  //----------------------- REFERRING DOCTOR CONST -----------------------------//
  const referringDocService = ReferringDocService(token);
  const [isRefDocFlatListVisible, setIsRefDocFlatListVisible] = useState(false);
  const [searchTextRefDoc, setSearchTextRefDoc] = useState("");
  const [selectedRefDoc, setSelectedRefDoc] = useState([]);
  const [referringDoctorSelected, setReferringDoctorSelected] = useState(false);
  const [showRefDocAdIcon, setShowRefDocAdIcon] = useState(false);
  const [isRefDocAdIconClicked, setIsRefDocAdIconClicked] = useState(false);
  const [allRefDocData, setAllRefDocData] = useState([])
  const [surnameAndName, setSurnameAndName] = useState("");

  //------------------------SERVICE CENTER ------------------------------------//
  const serviceCenter = ServCenterService(token);
  const [isServCenterFlatListVisible, setIsServCenterFlatListVisible] = useState(false);
  const [serviceCenterSelected, setServiceCenterSelected] = useState(false);
  const serviceCenterOptions = ["Facility", "Non-Facility", "Day Clinic"];
  const [isServCAdIconClicked, setisServCAdIconClicked] = useState(false);
  const [showServCenterAdIcon, setShowServCenterAdIcon] = useState(false);
  const [searchTextServCenter, setSearchServCenter] = useState("");
  const [specialityCode, setSpecialityCode] = useState("");
  const [practiceNumber, setPracticeNumber] = useState("");
  const [servicecenterName, setservicecenterName] = useState("");
  const [servicecenterType, setservicecenterType] = useState("");
  const [isEqual, setIsEqual] = useState(false)
  const [selectedDates, setSelectedDates] = useState({});

  const [modal, setModal] = useState(false);
  const [refDocData, setRefDocData] = useState([]);
  const [serviceCenterData, setServiceCenterData] = useState([]);
  const [searchTermDisType, setSearchTermDisType] = useState("")
  const [allServiceCenterData, setAllServiceCenterData] = useState([])
  const [isDisciplineTypeFlatlist, setisDisciplineTypeFlatlist] = useState(false)
  const [allServCenterData, setAllservCenterData] = useState([])
  const [selectedServCenter, setSelectedServCenter] = useState([]);
  const [authorizationNumber, setAuthorizationNumber] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().toISOString().split("T")[0]);



  const [serviceDate, setServiceDate] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const dropdownButtonRef = useRef(null);
  //   const [filteredSpecialties,setfilteredSpecialties] = useState(specialties.specialty);
  const [selectedItem, setSelectedItem] = useState(null);

  //------------------------SPECIALITIES ------------------------------------//
  const specialties =
  {
    "specialty": [
      "(015) Specialist Family Medicine / GP",
      "(016) Obstetrician and Gynaecologist",
      "(046) Urologist",
      "(018) Physician (012) Rheumatology",
      "(018) Physician (011) Medical Oncology",
      "(018) Physician",
      "(010) Anaesthetist",
      "(018) Physician (010) Gastroenterology",
      "(042) General Surgeon",
      "(032) Paediatrician",
      "(018) Physician (004) Endocrinology",
      "(014) General Medical Practice / GP",
      "(054) General Dental Practice",
      "(086 001) Clinical Psychologist",
      "(072) Physiotherapist",
      "(087) Orthotist and Prosthetist",
      "(044) Cardio Thoracic Surgeon",
      "(030) Otorhinolaryngologist / ENT",
      "(114) Paediatric Surgeon",
      "(090) Clinical Services (001) Oxygen Supplier",
      "(024) Neurosurgeon",
      "(022) Psychiatrist",
      "(028) Orthopaedic Surgeon",
      "(039 001) Diagnostic Radiographer",
      "(062) Maxillo-Facial and Oral Surgeon",
      "(075) Clinical Technologist (003) Nephrology",
      "(042) General Surgeon (003) Gastroenterology",
      "(018) Physician (005) Pulmonology",
      "(075) Clinical Technologist (008) Cardiology",
      "(032) Paediatrician (013) Cardiology",
      "(018) Physician (001) Clinical Haematology",
      "(018) Physician (009) Infectious Diseases",
      "(018) Physician (002) Nephrology",
      "(018) Physician (006) Critical Care",
      "(020) Neurologist",
      "(018) Physician (003) Cardiology",
      "(069) Medical Scientist (001) Genetic Councillor",
      "(066) Occupational Therapist",
      "(086 003) Educational Psychologist",
      "(088) Registered Nurse",
      "(075) Clinical Technologist (001) Cardio-Vascular",
      "(025) Nuclear Medicine",
      "(084) Dietician",
      "Gynaecologist",
      "Ophthalmologist",
      "Clinical",
      "(042) General Surgeon (003) Gastroenterology",
      "(018) Physician",
      "(026) Ophthalmologist",
      "(018) Physician (005) Pulmonology",
      "(026) Ophthalmologist",
      "(026) Ophthalmologist",
      "(030) Otorhinolaryngologist / ENT",
      "(014) General Medical Practice / GP",
      "(042) General Surgeon",
      "(075) Clinical Technologist (008) Cardiology",
      "(026) Ophthalmologist",
      "(032) Paediatrician (013) Cardiology",
      "(042) General Surgeon",
      "(018) Physician",
      "(026) Ophthalmologist",
      "(036) Plastic and Reconstructive Surgeon",
      "(026) Ophthalmologist",
      "(018) Physician (003) Cardiology",
      "(026) Ophthalmologist",
      "(086) Psychologist",
      "(036) Plastic and Reconstructive Surgeon",
      "(069) Medical Scientist (001) Genetic Councillor",
      "(086) Psychologist",
      "(086) Psychologist",
      "(040) Radiation Oncologist",
      "Psychiatry",
      "(018) Physician (012) Rheumatology",
      "(024) Neurosurgeon",
      "(028) Orthopaedic Surgeon",
      "(042) General Surgeon",
      "(022) Psychiatrist",
      "(022) Psychiatrist",
      "(018) Physician (001) Clinical Haematology",
      "(018) Physician (009) Infectious Diseases",
      "(022) Psychiatrist",
      "(018) Physician (005) Pulmonology",
      "(018) Physician (002) Nephrology",
      "(018) Physician",
      "(018) Physician (001) Clinical Haematology",
      "(028) Orthopaedic Surgeon",
      "(032) Paediatrician",
      "(018) Physician (006) Critical Care",
      "(026) Ophthalmologist",
      "(018) Physician (001) Clinical Haematology",
      "(026) Ophthalmologist",
      "(018) Physician (003) Cardiology",
      "(014) General Medical Practice / GP",
      "(028) Orthopaedic Surgeon",
      "(018) Physician (003) Cardiology",
      "(026) Ophthalmologist",
      "(018) Physician (009) Infectious Diseases",
      "(086) Psychologist",
      "(018) Physician",
      "(036) Plastic and Reconstructive Surgeon",
      "(026) Ophthalmologist",
      "(069) Medical Scientist (001) Genetic Councillor",
      "(086) Psychologist",
      "(086 003) Educational Psychologist"


    ]
  }
  const [filteredSpecialties, setfilteredSpecialties] = useState(specialties.specialty);
  useEffect(() => {

    const filteredData = specialties.specialty.filter((item) => {
      const lowerCasedSearchTerm = searchTermDisType.toLowerCase();
      return item.toLowerCase().includes(lowerCasedSearchTerm);
    }).slice(0, 20);

    setfilteredSpecialties(filteredData);
  }, [searchTermDisType]);
  const [parentScrollEnabled, setParentScrollEnabled] = useState(true); 
  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsVisible(false);
  };
  //------------------------------------- COMMON CALLS ----------------------------------//
  useEffect(() => {
    if (!token) {
      console.log("Token is not available.");
      setError("Token is not available.");
      return;
    }
  }, [token]);


  //---------------TREATING DOCTOR ----------------------------------------------------//
  const debouncedTreatingDocSearch = useCallback(
    debounce(async (searchText) => {
      try {
        setIsLoading(true);
        const treatingDocData = await treatingDoctor.TreatingDoc();

        if (Array.isArray(treatingDocData)) {
          settDocData(treatingDocData);

          // Update default doctor only if exactly one match is found
          if (treatingDocData.length === 1) {
            setDefaultTreatingDoc(treatingDocData[0].name);
            setDefaultTreatingDocID(treatingDocData[0].id);
            setSearchText(treatingDocData[0].name);
            // Optionally set the Formik field value here if needed
          } else {
            setDefaultTreatingDoc('');
            setDefaultTreatingDocID('');
          }

          // Check for exact match
          const checkDirectlyEqual = treatingDocData.some(value => value.name.toUpperCase() === searchText.toUpperCase());
          if (checkDirectlyEqual) {
            setIsTreatDocFlatListVisible(false);
            return;
          }

          // Filter data based on search text
          const filteredData = treatingDocData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );

          setFilteredTDoc(filteredData);
          setIsTreatDocFlatListVisible(filteredData.length > 0);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );

  const handleSelectTreatingDoc = (item) => {
    if (selectedTreatingDoc && selectedTreatingDoc.id === item.id) {
      setSelectedTreatingDoc(null);
      setSearchText("");
      setTreatingDoctorSelected(false);
    } else {
      setSelectedTreatingDoc(item);
      setSearchText(item.name);
      navigate({ treatingDoctor: item.name });
      setIsTreatDocFlatListVisible(false);
      setTreatingDoctorSelected(true);
    }
  };

  useEffect(() => {
    debouncedTreatingDocSearch(searchText);
  }, [searchText, debouncedTreatingDocSearch]);

  useEffect(() => {
    if (defaultTreatingDoc) {
      setSearchText(defaultTreatingDoc);
    }
  }, [defaultTreatingDoc]);


  useEffect(() => {
  }, [defaultTreatingDoc]);
  //---------------------------REFERRING DOCTOR ------------------------------------------------------//
  const debouncedSearch = useCallback(
    debounce(async (searchText, token) => {
      try {
        setIsLoading(true);
        const referringDocData = await referringDocService.ReferringDoc();
        if (Array.isArray(referringDocData)) {
          const filteredData = referringDocData.filter(item =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );
          setRefDocData(filteredData);
        }
      } catch (error) {
        setError("Error fetching referring doctor data.");
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );


  const handleSearchInputRefDoc = (text) => {
    setSearchTextRefDoc(text);
    if (text.length >= 2) {
      debouncedSearch(text);
      setIsRefDocFlatListVisible(true);
    } else {
      setRefDocData([]);
      setIsRefDocFlatListVisible(false);
    }
  };

  const handleSelectRefDoc = (item, setFieldValue) => {
    if (selectedRefDoc && selectedRefDoc.id === item.id) {
      setSelectedRefDoc(null);
      //setsearchTextRefDoc("");
      setReferringDoctorSelected(false);
    } else {
      setSelectedRefDoc(item);
      //setsearchTextRefDoc(item.name);
      setFieldValue("referringDoctor", item.name);
      setIsRefDocFlatListVisible(false);
      setReferringDoctorSelected(true);
      setSurnameAndName(item.surnameAndName || "");
      setPracticeNumber(item.practiceNumber || "");
      setSpecialityCode(item.specialityCode || "");
      setServiceDate(item.serviceDate || "");
    }
  };

  useEffect(() => {
    const selected =
      filteredSpecialties.some(option => option.toUpperCase() === searchTermDisType.toUpperCase());

    if (selected) {
      setisDisciplineTypeFlatlist(false)
    }
  }, [searchTermDisType])

  useEffect(() => {
    if (searchText.length >= 2) {
      debouncedSearch(searchText);
      setIsRefDocFlatListVisible(true);
    } else {
      setRefDocData([]);
      setIsRefDocFlatListVisible(false);
    }
  }, [searchText, debouncedSearch]);

  const handlePlusIconClick = () => {
    setIsRefDocAdIconClicked(true)
    //setFieldValue("referringDoctor", "")
    setParentScrollEnabled(true)
    //setFieldValue("referringDoc", "")
    setSearchTextRefDoc("")
  };


  const handleSearchInput = (text) => {
    setSearchText(text);
    if (text.length >= 2) {
      debouncedTreatingDocSearch(text);
      setIsTreatDocFlatListVisible(true);
    } else {
      settDocData([]);
      setIsTreatDocFlatListVisible(false);
    }
  };

  const checkEqualRef = (text, setFieldValue) => {
    const value = allRefDocData.some(value => value.name.toUpperCase() === text.toUpperCase())

    if (value) {
      setFieldValue("nameandsurname", "");
      setSurnameAndName("")
      setFieldValue("referringDoctor", text)
      setSearchTextRefDoc(text)
      setFieldValue("practiceNumber", "")
      setPracticeNumber("")
      setFieldValue('specCode', "")
      setSpecialityCode("")
    }
  }
  //------------------------------SERVICE CENTER --------------------------------------------------------//
  const debouncedServiceCenterSearch = useCallback(
    debounce(async (searchText, token) => {
      try {
        setIsLoading(true);
        const serviceCenterData = await serviceCenter.ServiceCenterData();
        if (Array.isArray(serviceCenterData)) {
          const filteredData = serviceCenterData.filter(item =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );
          setServiceCenterData(filteredData);
        }
      } catch (error) {
        setError("Error fetching service center data.");
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );

  const handlePlusIconClickServiceCenter = () => {
    setisServCAdIconClicked(true)
    setParentScrollEnabled(true)
    setSearchServCenter("")
  };

  const handleSearchInputServiceCenter = (text) => {
    setSearchServCenter(text);
    if (text.length >= 2) {
      debouncedServiceCenterSearch(text, token);
      setIsServCenterFlatListVisible(true);
    } else {
      setServiceCenterData([]);
      setIsServCenterFlatListVisible(false);
    }
  };

  const handleSelectServiceCenter = (item, setFieldValue) => {
    if (setFieldValue) {
      setSearchServCenter(item.name);
      setisServCAdIconClicked(false)
      setFieldValue("serviceCenter", item.name);
      setIsServCenterFlatListVisible(false);
    } else {
      console.error("setFieldValue is not defined");
    }
  };

  useEffect(() => {
    if (searchTextServCenter.length >= 5) {
      debouncedServiceCenterSearch(searchTextServCenter);
    }
  }, [searchTextServCenter, debouncedServiceCenterSearch, token]);

  const checkEqualServiceCenter = (text, setFieldValue) => {
    const value = allServCenterData.some(value => value.name.toUpperCase() === text.toUpperCase())
    //value found,close flat,set ref doc to value entered
    if (value) {

      setFieldValue("servicecenterName", "");
      setservicecenterName("")
      setFieldValue("serviceCenter", text)
      setSearchServCenter(text)
      setSelectedItem("")

    }
  }

  //----------------------------VALIDATION SCHEMA-------------------------------------------------------//

  const validationSchema = Yup.object().shape({
   
    serviceCenter: !isServCAdIconClicked
      && Yup.string()
        .test('is-valid-plan', 'Invalid Service Center Selected', value =>
          serviceCenterData.some(option => option.name.toUpperCase() === value.toUpperCase())
        )
        .required("Service Center is required")
        .matches(/\S/, "One non-whitespace required"),

    servCenterName: isServCAdIconClicked && isServCenterFlatListVisible
      ? Yup.string().required("Service Center Name is required").matches(/\S/, "One non-whitespace required")
      : Yup.string().notRequired(),

    servCenterType: isServCAdIconClicked && isServCenterFlatListVisible
      ? Yup.string().required("Service Center Type is required")
      : Yup.string().notRequired(),

    nameandsurname: isRefDocFlatListVisible && isRefDocAdIconClicked &&
      Yup.string()
        //.matches(/^[a-zA-Z]+\s+[a-zA-Z]+$/, 'Please enter both a First name and a Last name separated by a space')
        .required('Full name is required'),

    pracNumber:
      isRefDocAdIconClicked && isRefDocAdIconClicked &&
      Yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed')
        .required('Field is required'),

    specCode: isRefDocAdIconClicked && isRefDocAdIconClicked &&

      Yup.string()
        .test('is-valid-plan', 'Invalid Service Center Selected', value =>
          filteredSpecialties.some(option => option.toUpperCase() === value.toUpperCase())
        )
        .required("Service Center is required")
        .matches(/\S/, "One non-whitespace required"),

    servCenterName:
      isServCAdIconClicked &&
      Yup.string().required('Field is required').matches(/\S/, "One non-whitespace required"),

    serviceDate: Yup.string().required("Service Date is required"),

    servCenterType: isServCAdIconClicked &&
      Yup.string().required('Field is required'),

  });
  const handleDisciplineType = (text, setFieldValue) => {

    setSearchTermDisType(text);
    if (text === "") {
      setFieldValue('specCode', "")
      setSearchTermDisType("");
      setisDisciplineTypeFlatlist(false)
    } else {
      setisDisciplineTypeFlatlist(true);

      debouncedDiscipline(text);
      setFieldValue('specCode', text)
    }
  };
  const debouncedDiscipline = useCallback(
    debounce((text) => {
      setSearchTermDisType(text);
    }, 250),
    []
  );
  useEffect(() => {
    return () => {
      debouncedDiscipline.cancel();
    };
  }, [debouncedDiscipline]);

  return (
    <div className="full-width-header">
      <MainHeader props={"home"} />
      <div className="container">
        <div className="backbtnContainer my-2">
          <button className="btn" onClick={() => navigate(-1)}>
            <i style={{ color: 'black' }} className="fa fa-arrow-left" aria-hidden="true"></i>
            {" "}INVOICE DETAILS
          </button>
        </div>
        <div className="col-12">
          <div className="p-3 mb-2 text-black">
            <span className="font-bold">Step 1 of 2</span>
          </div>
          <label htmlFor="password" className="form-label">PATIENT NAME</label>
          <Formik
            initialValues={{
              referringDoctor: "",
              nameandsurname: "",
              serviceDate: "",
              pracNumber: "",
              specCode: "",
              servCenterName: "",
              servCenterType: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm, setFieldError }) => {
              navigate('/invoicesteptwo', {
                state: {
                  refDocNameSurname: surnameAndName || '',
                  dates: values.serviceDate
                  //name: values.referringDoctor || "",
                }
              });

            }}
          >
            {({ values, isSubmitting, setFieldValue, errors }) => (
              <Form>
                <div className="mb-3">
                <label htmlFor="treatingDoctor">Treating Doctor<span className="error-text">*</span></label>
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className="form-control"
                    id="treatingDoctor"
                  />
                  {isTreatDocFlatListVisible && (
                    <ul className="list-group">
                      {filteredTDoc.map(doc => (
                        <li
                          key={doc.id}
                          className="list-group-item"
                          onClick={() => handleSelectTreatingDoc(doc, setFieldValue)}
                        >
                          {doc.name}
                        </li>
                      ))}
                    </ul>
                  )}
                  {error && <div className="error-text">{error}</div>}
                </div>

                <div className='mb-3'>
                  <label>Referring Doctor</label>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      name="referringDoctor"
                      value={searchTextRefDoc}
                      onChange={(e) => handleSearchInputRefDoc(e.target.value)}
                      className="form-control"
                      id="referringDoctor"
                    />
                    {searchTextRefDoc.length >= 2 && refDocData.length === 0 && (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="plus-icon"
                        onClick={() => handlePlusIconClick()}
                      />
                    )}

                    {isRefDocFlatListVisible && refDocData.length > 0 && (
                      <div className="dropdown-list">
                        {isLoading && <div>Loading...</div>}
                        <ul>
                          {refDocData.map(item => (
                            <li key={item.id} onClick={() => handleSelectRefDoc(item, setFieldValue)}>
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                {isRefDocAdIconClicked && (
                  <>
                    <div className='mb-3'>
                      <label htmlFor="nameSurname">Name and Surname *</label>
                      <input
                        type="text"
                        name="nameandsurname"
                        value={values.nameandsurname}
                        onChange={(e) => {
                          const text = e.target.value
                          setFieldValue("nameandsurname", text);
                          setSurnameAndName(text)
                          checkEqualRef(text, setFieldValue)
                        }}
                        className="form-control"
                        id="nameandsurname"
                      />
                      <ErrorMessage name="nameandsurname" component="div" className="error-text d-flex" />
                    </div>
                    <div className='mb-3'>
                      <label htmlFor="pracNumber">Practice Number / Council Number *</label>
                      <input
                        type="text"
                        name="pracNumber"
                        value={values.pracNumber}
                        onChange={(e) => {
                          const text = e.target.value
                          setFieldValue("pracNumber", text);
                          setSurnameAndName(text)
                          checkEqualRef(text, setFieldValue)
                        }}
                        className="form-control"
                        id="pracNumber"
                      />
                      <ErrorMessage name="pracNumber" component="div" className="error-text d-flex" />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor="specCode">Speciality Code / Medical Discipline / Activity *</label>
                      <input
                        type="text"
                        name="specCode"
                        value={searchTermDisType}
                        onChange={(e) => {
                          const text = e.target.value;
                          handleDisciplineType(text, setFieldValue)
                        }}
                        className="form-control"
                        id="specCode"
                      />
                      <ErrorMessage name="specCode" component="div" className="error-text d-flex" />
                      {isDisciplineTypeFlatlist && (
                        <ul className="list-group">
                          {filteredSpecialties.map((item, index) => (
                            <li
                              key={index}
                              className="list-group-item pointer"
                              onClick={() => {
                                setisDisciplineTypeFlatlist(false)
                                setSearchTermDisType(item)
                                setSpecialityCode(item)
                                setFieldValue('specCode', item)

                              }}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                  </>
                )}

                <div className='mb-3'>
                  <label htmlFor="serviceDate">Service Date<span className="error-text">*</span></label>
                  <Field
                    type="date"
                    name="serviceDate"
                    className="form-control"
                    id="serviceDate"
                  />
                  <ErrorMessage name="serviceDate" component="div" className="error-text d-flex" />
                </div>

                <div className='mb-3'>
                  <label htmlFor="serviceCenter">Service Center:*</label>
                  <div style={{ position: 'relative' }}>
                    <Field
                      type="text"
                      name="serviceCenter"
                      value={searchTextServCenter}
                      onChange={(e) => handleSearchInputServiceCenter(e.target.value)}
                      className="form-control"
                      id="serviceCenter"
                    />

                    {searchTextServCenter.length >= 2 && serviceCenterData.length === 0 && (
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="plus-icon"
                        onClick={() => handlePlusIconClickServiceCenter()}
                      />
                    )}

                    {isServCenterFlatListVisible && serviceCenterData.length > 0 && (
                      <div className="dropdown-list">
                        {isLoading && <div>Loading...</div>}
                        <ul>
                          {serviceCenterData.map(item => (
                            <li
                              key={item.id}
                              onClick={() => handleSelectServiceCenter(item, setFieldValue)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <ErrorMessage name="serviceCenter" component="div" className="error-text d-flex" />
                </div>

                {isServCAdIconClicked && (
                  <>
                    <div className='mb-3'>
                      <label htmlFor="serviceDate">Service Center Name *</label>
                      <Field
                        type="text"
                        name="servCenterName"
                        value={values.servCenterName}
                        onChange={(e) => {
                          const text = e.target.value;
                          setFieldValue("servCenterName", text);
                          setservicecenterName(text)
                          checkEqualServiceCenter(text, setFieldValue)
                        }}
                        className="form-control"
                        id="servCenterName"
                      />
                      <ErrorMessage name="servCenterName" component="div" className="error-text d-flex" />
                    </div>

                    <div className='mb-3'>
                      <label htmlFor="servCenterType">Service Center Type:</label>
                      <select
                        name="servCenterType"
                        value={values.servCenterType}
                        className="form-control"
                        id="servCenterType"
                        onChange={(e) => {
                          setFieldValue("servCenterType", e.target.value);
                          handleSelectItem(e.target.value)
                        }}
                      >
                        <option value="Non-Facility">Non-Facility</option>
                        <option value="Facility">Facility</option>
                        <option value="Day Clinic">Day Clinic</option>
                      </select>
                      <ErrorMessage name="servCenterType" component="div" className="error-text d-flex" />
                    </div>

                  </>
                )}
                <div className='mb-3'>
                  <label htmlFor="authorizationNumber">Authorization Number</label>
                  <Field
                    type="text"
                    name="authorizationNumber"
                    className="form-control"
                    id="authorizationNumber"
                  />
                </div>
                <div className='mb-3'>
                  <button
                    type="submit"
                    className="w-100 formButton"

                  >
                    <div className="text-white d-flex align-items-center justify-content-center">
                      <span>Continue</span>
                    </div>
                  </button>
                </div>
                <ErrorMessage name="auth" component="div" className="error-text" />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default InvoiceStepOne;
