import React from 'react'
import { Formik } from 'formik'
import GenericInput from '../GenericInput'
import ContentModal from './ContentModal'
const ContactDetailsModal = ({closeModal,visibleValue}) => {
  return (
    <ContentModal
    visible={visibleValue}
    header={"Confirm Contact Details"}
    closeFunc={closeModal}
  >
    <Formik>
      <div>
        <GenericInput description={"First Name"} />
        <GenericInput description={"Last Name"} />
        <GenericInput description={"Email Name"} />
        <GenericInput description={"Contact Number Name"} />
      </div>
    </Formik>
  </ContentModal>
  )
}

export default ContactDetailsModal