import "../../styles/components/InvoiceHistory.scss";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useAuth } from "../../services/authentication/LoginService";
import InvoiceHistoryService from "../../services/Invoice/InvoiceHistoryService.js";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Sidebar from "../../screens/Sidebar/Sidebar";
import BillingButtons from "../../screens/common/BillingButtons";
import InvoiceHistoryTable from "./InvoiceHistoryTable.js";
import FromToDate from "../FromToDate.js";
import NavigateBack from "../NavigateBack.js";
const InvoiceHistory = ({ route }) => {
  const { token } = useAuth();
  const [patientData, setPatientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchPatientData, setSearchPatientData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [datesValid, setDatesValid] = useState(true);
  const [filteredPatientData, setFilteredPatientData] = useState([]);
  const [isClearPressed, setIsClearPressed] = useState(false);
  const [patientNameFilter, setPatientNameFilter] = useState(
    route?.params.patientNameFiltered || ""
  );
  const [searchCaseID, setSearchCaseID] = useState("");
  const [expandedItem, setExpandedItem] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("A");
  const location = useLocation();

  // Dates
  const formatDate = (date) => {
    return date
      ? date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
      : "";
  };

  const validateDates = (fromDate, toDate) => {
    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      if (toDateObj < fromDateObj) {
        setValidationMessage(
          "The to date cannot be less than from date. Please select an appropriate date."
        );
        setDatesValid(false);
      } else {
        setValidationMessage("");
        setDatesValid(true);
      }
    }
  };
  useEffect(() => {
    validateDates(selectedFromDate, selectedToDate);
  }, [selectedFromDate, selectedToDate]);

  useEffect(() => {
    filterData(new Date(""), new Date(""), "All");
  }, []);

  useEffect(() => {
    setSearchText(patientNameFilter);
  }, [patientNameFilter]);

  useEffect(() => {
    if (patientNameFilter && !isClearPressed && searchText) {
      searchForRecord();
    } else if (isClearPressed) {
      setFilteredPatientData(patientData);
    } else if (searchCaseID) {
      searchForRecord();
    }
  }, [searchText, searchPatientData, patientNameFilter]);

  const handleSearchTextChange = (text) => {
    setSearchText(text);
    if (!isNaN(text)) {
      setSearchCaseID(text);
      // searchForRecord()
    } else {
      setPatientNameFilter(text);
    }

    if (isClearPressed) {
      setIsClearPressed(false);
    }
  };
  // Get patient info
  useEffect(() => {
    searchPatientInvoiceHistory();
  }, []);
  //searchText
  const searchPatientInvoiceHistory = async () => {
    try {
      setLoading(true);
      const name = location?.state?.name ?? null;
      const searchName = name || searchText;
      const result = await InvoiceHistoryService(token).fetchInvoiceHistory(
        searchName
      );
      const sortedData = sortPatientDataByDate(result.invoices);
      setPatientData(sortedData);
      setSearchPatientData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error("Error searching patient invoice history:", error);
    } finally {
      setLoading(false);
    }
  };
  const searchForRecord = () => {
    const filterName =
      location?.state?.patientNameFiltered || patientNameFilter || "";
    if (!searchPatientData || searchPatientData.length === 0) {
      setFilteredData([]);
      return;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    const filterNameLowerCase = filterName.toLowerCase();
    const newFilteredData = searchPatientData.filter((item) => {
      const itemNameLowerCase = item.PatientName.toLowerCase();
      const itemInvoiceReferenceLowerCase = item.InvoiceReference.toLowerCase();
      const matchesReference =
        itemInvoiceReferenceLowerCase.includes(searchTextLowerCase);
      const matchesName = itemNameLowerCase.includes(searchTextLowerCase);
      const matchesCaseID =
        !isNaN(searchCaseID) && item.CaseID === searchCaseID;
      const matchesFilterName = filterNameLowerCase
        ? itemNameLowerCase.includes(filterNameLowerCase)
        : true;

      return (
        (matchesReference || matchesName || matchesCaseID) && matchesFilterName
      );
    });

    setFilteredData(newFilteredData);
  };

  const filterData = (fromDate, toDate, option) => {
    if (!patientData) return;

    let filteredData = [...patientData];
    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      filteredData = filteredData.filter((item) => {
        const caseDate = new Date(item.InvoiceDate);
        return caseDate >= fromDateObj && caseDate <= toDateObj;
      });
    } else if (fromDate) {
      const fromDateObj = new Date(fromDate);
      const currentDate = new Date();
      filteredData = filteredData.filter((item) => {
        const caseDate = new Date(item.InvoiceDate);
        return caseDate >= fromDateObj && caseDate <= currentDate;
      });
    }

    if (option === "All") {
      setFilteredData(filteredData);
    } else if (option === "P") {
      setFilteredData(
        filteredData.filter((item) => item && item.InvoiceStatus === "Pending")
      );
    } else if (option === "C") {
      setFilteredData(
        filteredData.filter(
          (item) => item && item.InvoiceStatus === "Completed"
        )
      );
    } else {
      setFilteredData(filteredData);
    }

    setSearchPatientData(filteredData);
  };

  const sortPatientDataByDate = (data) => {
    const sortedData = [...data];
    return sortedData.sort((a, b) => {
      return new Date(b.InvoiceDate) - new Date(a.InvoiceDate);
    });
  };
  const filterFromDate = (e, setFieldValue) => {
    const value = e.target.value;
    setFromDate(value);
    setSelectedFromDate(value);
    setFieldValue("fromDate", value);
    filterDates(value);
  };
  const filterToDate = (e, setFieldValue, values) => {
    const value = e.target.value;
    setToDate(value);
    setSelectedToDate(value);
    setFieldValue("toDate", value);
    filterCombine();
  };
  const handleExpandItem = (item) => {
    setExpandedItem(expandedItem === item ? null : item);
  };

  const groupDataByCaseID = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.CaseID]) {
        acc[item.CaseID] = [];
      }
      acc[item.CaseID].push(item);
      return acc;
    }, {});
  };

  const dataGroupedByCaseID = groupDataByCaseID(
    sortPatientDataByDate(filteredData)
  );
  const filterDates = (date) => {
    filterData(date, "", selectedOption);
  };
  const filterCombine = (option) => {
    if (!option) {
      option = "All";
    }
    if (selectedFromDate && selectedToDate) {
      filterData(selectedFromDate, selectedToDate, option);
    } else if (selectedFromDate) {
      filterData(selectedFromDate, "", option);
    } else if (selectedToDate) {
      filterData("", selectedToDate, option);
    } else {
      filterData("", "", option);
    }
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    filterCombine(option);
  };
  return (
    <div>
      <Sidebar heading={<h1 className="heading">INVOICE HISTORY</h1>}>
        <Formik
          initialValues={{ fromDate: "", toDate: "" }}
          validationSchema={Yup.object({})}
        >
          {({ values, setFieldValue }) => (
            <div className="container ">
              <NavigateBack text={"INVOICE HISTORY"} />
              <div className="mt-4">
                <BillingButtons />
              </div>
              <div className="d-flex flex-column flex-md-row mt-4">
                <div className="search-container pointer mb-3 mb-md-0 col-12 col-md-6">
                  <small className="bold ">
                    Enter: Ref No / Patient Name & Surname / Acc No
                  </small>
                  <div className="position-relative">
                    <input
                      onChange={(event) => {
                        const text = event.target.value;
                        handleSearchTextChange(text);
                      }}
                      type="text"
                      value={searchText}
                      className=" custom-input search-input pointer"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <div className="search-icon">
                      <i className="fa fa-search" />
                    </div>
                  </div>
                </div>
              
                <FromToDate
                  changeDateFrom={(e) => filterFromDate(e, setFieldValue)}
                  changeDateTo={(e) => filterToDate(e, setFieldValue, values)}
                  values={values}
                />
              </div>

              <InvoiceHistoryTable
                data={filteredData}
                loading={loading}
                dataGroupedByCaseID={dataGroupedByCaseID}
                onExpand={handleExpandItem}
                filterCombine={handleOptionChange}
                patientsearch={true}
                selectedOption={selectedOption}
              />
            </div>
          )}
        </Formik>
      </Sidebar>
    </div>
  );
};

export default InvoiceHistory;
