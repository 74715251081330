import React from 'react';

const ValueChange = ({ value }) => {
  const isPositive = value >= 0;
  const valueText = `${Math.abs(value)}%`; 
  const valueStyle = isPositive ? { color: '#0c8800', fontWeight: 'bold' } : { color: '#a5002f', fontWeight: 'bold' };
  const arrowIcon = isPositive ? (
    <i className="fa fa-arrow-up" style={{ marginRight: '5px' }}></i>
  ) : (
    <i className="fa fa-arrow-down" style={{ marginRight: '5px' }}></i>
  );

  return (
    <span style={valueStyle}>
      {arrowIcon} {valueText}
    </span>
  );
};

export default ValueChange;
