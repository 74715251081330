import axios from "axios";
import PROD_API from "../baseEndpoint";
 
const AssistantService = (token) => {
  const AssistantData = async () => {
    if (token) {
      try {
        const referingDoc = 3;
        const apiUrl = PROD_API + `GoodX/get_doctors?accessToken=${token}&docType=${referingDoc.toString()}`;
        // console.log(apiUrl)
        const response = await axios.post(apiUrl);
 
        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          return response.data.data;
 
        } else {
          console.error(
            "Failed to fetch data. Unexpected status code:",
            response.status
          );
          return [];
        }
      } catch (error) {
        console.error("Failed to fetch assistant data. An error occurred:", error);
        return [];
      }
    } else {
      console.error("Token is not valid or missing.");
      return [];
    }
  };
 
  return {
    AssistantData,
  };
};
 
export default AssistantService;