import React, { useState, createContext, useContext, useCallback, useEffect } from "react";
import axios from "axios";
import JWT from "expo-jwt";
import PROD_API from "../baseEndpoint";
import { SHA256 } from 'crypto-js';
import Cookie from "universal-cookie";
import { UserContext } from "./UserContext";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const cookies = new Cookie();
  const [token, setToken] = useState(() => cookies.get('jwt_authentication') || null); 
  const [error, setError] = useState(null);
  const [subTaskCount, setSubTaskCount] = useState(0); 
  const { setUsername, setEntityDoctorName, setDoctorSpeciality, setCustomerAccessControl, setEntityNumber} = useContext(UserContext);


  useEffect(() => {
    const initializeAuth = async () => {
      const savedToken = cookies.get('jwt_authentication');
      if (savedToken) {
        try {
          const authResult = await authenticate(savedToken);
          if (authResult && authResult.status === 0) {
            // Only restore other data if token is valid
            const savedUsername = cookies.get('UserName');
            const savedDoctorName = cookies.get('DoctorName');
            const savedEntityNumber = cookies.get('EntityNumber');
            const savedCustomerAccess = cookies.get('CustomerAccess');

            setToken(savedToken);
            if (savedUsername) setUsername(savedUsername);
            if (savedDoctorName) setEntityDoctorName(savedDoctorName);
            if (savedEntityNumber) setEntityNumber(savedEntityNumber);
            if (savedCustomerAccess) setCustomerAccessControl(savedCustomerAccess);
          } else {
            // If token is invalid, clear everything
            handleLogout();
          }
        } catch (error) {
          handleLogout();
        }
      }
    };

    initializeAuth();
  }, []);

  // Set cookies with longer expiration and more secure options
  const setCookieWithOptions = useCallback((name, value) => {
    cookies.set(name, value, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60, // 7 days instead of 24 hours
      secure: true,
      sameSite: 'strict',
      httpOnly: true // Additional security
    });
  }, [cookies]);

  // Update cookies whenever auth state changes
  useEffect(() => {
    if (token) {
      setCookieWithOptions('jwt_authentication', token);
    }
  }, [token, setCookieWithOptions]);

  const generateToken = useCallback(async (username, password) => {
    const userCredentials = { username, password: SHA256(password).toString() };
    const secretKey = "xpedient_web_data4_secret_key_2023!@?";


    const apiReqType = 2;

    try {
      const newToken = await JWT.encode(userCredentials, secretKey, "HS256", apiReqType);
      setToken(newToken);
      return newToken;
    } catch (error) {
      handleError(error);
      return null;
    }
  }, []);

  const authenticate = useCallback(async (token) => {
    try {
      const response = await axios.post(`${PROD_API}authenticate?accessToken=${token}`);
      if (response.status === 200 && response.data && response.data.status >= 0) {
        return response.data; 
      } else {
        return {
          status: response.data.status || -1,
          message: response.data.message || "Authentication failed.",
          parm_extra: response.data.parm_extra || null
        };
      }
    } catch (error) {
      return {
        status: -1,
        message: "Network error or request failed.",
        parm_extra: null
      };
    }
  }, []);
  const generateTokenWithEntity = async (username, password, entity) => {
    const userCredentials = { username, password: SHA256(password).toString(), entity };
    const secretKey = "xpedient_web_data4_secret_key_2023!@?";
    const apiReqType = 2;

    try {
      const newToken = await JWT.encode(userCredentials, secretKey, "HS256", apiReqType);
      setToken(newToken);
      return newToken;
    } catch (error) {
      if (error.response) {
        setError(`Server responded with status code: ${error.response.status}`);
      } else if (error.request) {
        setError("No response was received from the server.");
      } else {
        setError("Error in generating token: " + error.message);
      }
      console.error(error);
      return null;
    }
  };
   const handleSignIn = async (username, password, entityDoctorNumber) => {
    setError(null); // Clear previous errors
    const trimmedUsername = username.trim();
    const lowerCaseUsername = trimmedUsername.toLowerCase();

    // Conditionally generate token with or without the entity parameter
    let newToken;
    if (entityDoctorNumber) {
        // If entityDoctorNumber is defined, pass it to generateToken
        newToken = await generateTokenWithEntity(lowerCaseUsername, password, entityDoctorNumber);
    } else {
        // If entityDoctorNumber is undefined, omit it when calling generateToken
        newToken = await generateToken(lowerCaseUsername, password);
    }

    // registerIndieID(lowerCaseUsername, 20779, 'Z0HUbKQQg8uuDPo8doLgSQ');

    if (!newToken) {
        setError("Could not parse authentication token. Please try signing in again");
        return { success: false, error: "Could not parse authentication token." };
    }

    const isAuthenticated = await authenticate(newToken);
    console.log("AUHTHENTICATE", isAuthenticated)

    if (isAuthenticated && isAuthenticated.status === 0) {
        const parmUsername = isAuthenticated.parm_extra;
        const upperCaseUsername = parmUsername.toUpperCase();
        const doctorSpeciality = isAuthenticated.SpecialityDesc
        setUsername(upperCaseUsername); 
        // setDoctorSpeciality(doctorSpeciality)
        if (isAuthenticated.entityDoctorName) {
            setEntityDoctorName(isAuthenticated.entityDoctorName);
            setCustomerAccessControl(isAuthenticated.customerAccessControl)
            setEntityNumber(isAuthenticated.entityNo)
        }
        setCookieWithOptions('UserName', upperCaseUsername);
        if (isAuthenticated.entityDoctorName) {
          setCookieWithOptions('DoctorName', isAuthenticated.entityDoctorName);
          setCookieWithOptions('EntityNumber', isAuthenticated.entityNo);
          setCookieWithOptions('CustomerAccess', isAuthenticated.customerAccessControl);
        }

        setToken(newToken); 
        setSubTaskCount(isAuthenticated.SubTaskCnt);
        return {
            success: true,
            token: newToken,
            message: isAuthenticated.message,
            parm_extra: isAuthenticated.parm_extra,
            client_name: isAuthenticated.clientName,
            tempPw: isAuthenticated.tempPw,
            subTaskCount: isAuthenticated.SubTaskCnt,
        };
    } else {
        // Handle authentication failure
        const errorMessage = isAuthenticated?.parm_extra || "Authentication failed";
        setError(errorMessage);
        return { success: false, error: errorMessage };
    }
};

  const handleLogout = useCallback(() => {
    setToken(null);
    setUsername(null);
    setSubTaskCount(0); // Reset subTaskCount on logout
    cookies.remove("jwt_authentication", { path: '/' });
    cookies.remove("UserName", { path: '/' });
    cookies.remove("DoctorName", { path: '/' });
  }, [setUsername]);

  const handleError = (error) => {
    if (error.response) {
      setError(`${error.response.data?.message || "Error"}: ${error.response.data?.parm_extra || ""}`);
    } else if (error.request) {
      setError("No response was received from the server.");
    } else {
      setError(`Error: ${error.message}`);
    }
  };

  // Include subTaskCount in the auth context value
  const authContextValue = { token, setToken, error, handleSignIn, handleLogout, subTaskCount, };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
