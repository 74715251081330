export const generateReportFilename = (selectedDate) => {
    const selectedDateObj = new Date(selectedDate);
    const year = selectedDateObj.getFullYear(); 
    const month = selectedDateObj.getMonth(); 
  
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
  
    const formattedMonth = String(month + 1).padStart(2, '0'); 
    const monthName = monthNames[month]; 
  
    return `VAT_Report_${year}_${formattedMonth}_${monthName}.xlsx`;
  };
  