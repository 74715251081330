import React, { useState, useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../services/authentication/LoginService";
import { useAuth } from "../../services/authentication/LoginService.js";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/components/login.scss";
import "../../styles/variables/_mixins.scss";
import LoginHeader from "../common/LoginHeader";
import MainFooter from "../common/MainFooter";
import { Link } from "react-router-dom";
import vectorImage from "../../assets/Vector.png";
import vectorFullBGImage from "../../assets/Vectorfull.png";
import LoginConfirmation from "../ConfirmationPage/LoginConfirmation";
import SignUpService from "../../services/authentication/SignUpService";
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { handleSignIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { email, tempPw } = location.state || {};
  const { token } = useAuth();

  const handleFormSubmit = async (values, actions) => {
    setLoading(true);

    try {
      const resetPasswordResponse = await SignUpService(
        token
      ).handleResetPassword(email, values.password, tempPw);

      if (resetPasswordResponse.success) {
        setLoading(false);
        navigate("/patientsearch");
      } else {
        setLoading(false);
        actions.setErrors({ auth: resetPasswordResponse.error });
        navigate("/signup");
      }
    } catch (error) {
      console.error("Error during password reset:", error);
      setLoading(false);
      actions.setErrors({ auth: "Password reset failed" });
    }
  };

  return (
    <div className="login-page-wrapper page-bg-img">
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorBig"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImage"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImageLeft"
      />
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorImageRight"
      />
      <div className="row">
        <div className="col-md-6 col-12">
          {" "}
          {/* White Container */}
          <div className="container shadow bg-white rounded px-4 authContainer">
            <div className="row">
              <div className="col-12">
                <LoginHeader />
              </div>
              <div className="col-12">
                <div className="mt-4">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-text"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Choose Password
                          </label>
                          <Field
                            type="password"
                            name="password"
                            className="form-control form-control-outlined"
                            id="password"
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-text"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="confirmPassword"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <Field
                            type="password"
                            name="confirmPassword"
                            className="form-control form-control-outlined"
                            id="confirmPassword"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="error-text"
                          />
                        </div>
                        <div className="mb-3">
                          <button
                            type="submit"
                            className="w-100 formButton"
                            disabled={isSubmitting || loading}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              {loading && (
                                <i className="login-text fas fa-spinner fa-spin me-2"></i>
                              )}
                              <span className="login-text">
                                {loading ? "" : "Login"}
                              </span>
                            </div>
                          </button>
                        </div>
                        <ErrorMessage
                          name="auth"
                          component="div"
                          className="error-text mt-3"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
                <MainFooter />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-0"></div>
      </div>
    </div>
  );
};

export default ResetPassword;
