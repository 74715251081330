// useProcedureCodesStore.js
import {create} from 'zustand';

const useServiceCenterCodesStore = create((set) => ({
    serviceCenterData:[],
    selectedServCenter:[],
    servicecenterType:"",
    authorizationNumber:"",
    isServCAdIconClicked:false,
    setisServCAdIconClicked:(isServCAdIconClicked)=>set({isServCAdIconClicked}),
    setAuthorizationNumber:(authorizationNumber)=>set({authorizationNumber}),
    isServCenterFlatListVisible:false,
    setIsServCenterFlatListVisible:(isServCenterFlatListVisible)=>set({isServCenterFlatListVisible}),
    setservicecenterType:(servicecenterType)=>set({servicecenterType}),
    setSelectedServCenter:(selectedServCenter)=>set({selectedServCenter}),
    setServiceCenterData:(serviceCenterData)=>set({serviceCenterData}),
    setSelectedServCenterId: (selectedServCenterId) => set({ selectedServCenterId }), 
    clearServiceCenter: () => set({
        serviceCenterData:[],
        selectedServCenter:[],
        servicecenterType:"",
        authorizationNumber:"",
        isServCAdIconClicked:false,
        isServCenterFlatListVisible:false,
    })
}));

export default useServiceCenterCodesStore;
