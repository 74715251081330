import React from 'react'
import CustomScroll from '../common/CustomScroll'
import "../../styles/components/invoice.scss"
function AddFavouritesSidebar({
    heading,
    codeMap,
    type,
    onClickFunc
}) 

{
    return (
    <div className="favourites-spacing ">
    <h5 className='favourites-heading'>{heading}</h5>
    {codeMap.length > 0 && (
      <CustomScroll maxHeight={"14vh"} smallerScroll={true}>
        <div>
          {codeMap.map((code, index) => (
            <div
              key={index}
              className='d-flex justify-content-between align-items-center py-2 w-100'
            >
              <div className='d-flex flex-grow-1 justify-content-start'>
                {type === "ICD10" ? (
                        <h6 className="code-labels">Code: {code.icd10Code}</h6>
                ):(
                    <h6 className="code-labels">Code: {code.code}</h6>  
                )}
              </div>          
              <div className='d-flex justify-content-start align-items-center pointer' onClick={() => onClickFunc(index)}>
                <img
                  style={{ objectFit: 'contain' }}
                  src={require("../../assets/trash.png")}
                  width={20}
                  height={20}
                  className='trashicon'
                  alt="Delete"
                />
              </div>
            </div>
          ))}
        </div>
      </CustomScroll>
    )}
  </div>
  )
}

export default AddFavouritesSidebar