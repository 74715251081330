import leadingZerosProcedure from '../../assets/Codes/procedureleadingZeros.json';

const cacheKey = 'leadingZerosProcedure';
const cacheVersion = 1; // Initial version number

const leadingZeroService = () => {
  const loadFromCache = () => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (parsedData.version === cacheVersion) {
          console.log('Loaded data from cache');
          return parsedData.data; // Return cached data if version matches
        } else {
          console.log('Cache version mismatch, clearing cache');
          clearCache(); // Trigger cache invalidation if version differs
        }
      }
    } catch (error) {
      console.error('Error loading from cache:', error);
    }
    return null;
  };

  const saveToCache = (data) => {
    try {
      const dataToCache = { version: cacheVersion, data }; // Include version number in cached data
      localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      console.log('Data cached for procedure leading zeros');
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  };

  const clearCache = () => {
    try {
      localStorage.removeItem(cacheKey);
      console.log('Cache cleared');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  };

  const ProcedureCodeData = async () => {
    try {
      const cachedData = loadFromCache();
      if (cachedData) {
        return cachedData;
      }

      if (leadingZerosProcedure && leadingZerosProcedure.length > 0) {
        const newData = leadingZerosProcedure.map((item) => ({
          code: item["procedureCode"],
          description: item["description"],
          modelType: item["modelType"],
        }));

        saveToCache(newData);

        return newData;
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch procedure leading zeros data. An error occurred:', error);
      throw error;
    }
  };

  return {
    ProcedureCodeData,
    clearCache
  };
};

export default leadingZeroService;
