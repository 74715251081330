import { utils, writeFile, write } from 'xlsx';

export const exportToExcel = (dataSheets, filename) => {
  const wb = utils.book_new();  

  dataSheets.forEach((sheetData) => {
    const { data, sheetName } = sheetData;
    const sheet = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, sheet, sheetName);
  });

  writeFile(wb, filename);
};


export const exportToBase64 = (dataSheets) => {
  const wb = utils.book_new();

  dataSheets.forEach((sheetData) => {
    const { data, sheetName } = sheetData;
    const sheet = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, sheet, sheetName);
  });
  
  // Generate the Excel file in binary string format
  const binaryExcel = write(wb, { bookType: 'xlsx', type: 'binary' });
  
  // Convert binary string to a Base64 string
  const base64Excel = btoa(binaryExcel);
  
  return base64Excel; // Return the Base64 string
};



