
import { useState } from "react";

export function useFavouritesService() {
    const [errorFavorite, setErrorFavorite] = useState(null);
    const [favouriteData, setFavouriteData] = useState([]);
    const [favouriteModalVisible, setFavouriteModalVisible] = useState(false);
    const [description, setDescription] = useState('');
    const [favouriteUpdatesLinesItems, setFavouriteUpdatesLinesItems] = useState([]);
    const [isSidebarOpenAddFavourites, setIsSidebarOpenAddFavourites] = useState(false);
    const [searchTermProc, setSearchTermProc] = useState("");
    return {
        errorFavorite,
        setErrorFavorite,
        favouriteData,
        setFavouriteData,
        favouriteModalVisible,
        setFavouriteModalVisible,
        description,
        setDescription,
        favouriteUpdatesLinesItems,
        setFavouriteUpdatesLinesItems,
        isSidebarOpenAddFavourites,
        setIsSidebarOpenAddFavourites,
        searchTermProc,
        setSearchTermProc
    };
}
