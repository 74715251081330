import React from 'react';
import '../../styles/components/login.scss';
import '../../styles/components/mainFooter.scss';

const MainFooter = () => {
  return (
    <footer className="col-12 col-md-12 col-lg-12 footer mt-2">
      <div className="footer-text">
        <p className="footer-bold">
          <span className="text-value">value</span>
          <span className="textTwo">.</span>
          <span className="text-driven">driven</span>
          <span className="textTwo">.</span>
          <span className="text-solutions">solutions</span>
        </p>
      </div>
    </footer>
  );
};

export default MainFooter;
