import React, { useState, useCallback, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import ServCenterService from "../../services/Billing/ServiceCenterService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import AuthorizationNumber from "./AuthorizationNumber";
import { useAuth } from "../../services/authentication/LoginService";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore";
const ServiceCenter = React.memo(
  ({
    serviceCenterType,
    serviceCenterName,
    serviceCenterDefault,
    hasAuth = true,
    isBatchRow = false,
    selectedServCenterId
  }) => {
    const { setFieldValue } = useFormikContext();
    const { token } = useAuth();
    const [searchTextServCenter, setSearchServCenter] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const {
      serviceCenterData,
      setServiceCenterData,
      setSelectedServCenter,
      setSelectedServCenterId,
      setservicecenterType,
      isServCenterFlatListVisible,
      setIsServCenterFlatListVisible,
      setisServCAdIconClicked,
      isServCAdIconClicked,
    } = useServiceCenterCodesStore();
    const [allServCenterData, setAllservCenterData] = useState([]);
    const [error, setError] = useState("");

    const serviceCenter = ServCenterService(token);
    const handleSelectServCenter = (item, setFieldValue) => {
        setSelectedServCenter(item);
        setSearchServCenter(item.name);
        setFieldValue("serviceCenter", item.name);
        setFieldValue("serviceCenterId", item.id);
        setSelectedServCenterId(item.id); 
        console.log("Selected Service Center ID:", item.id);
        setIsServCenterFlatListVisible(false);
        setisServCAdIconClicked(false);
        setservicecenterType("");
    };
    const handleSelectItem = (item) => {
      setIsVisible(false);
    };

    const handlePlusIconClickServiceCenter = () => {
      setisServCAdIconClicked(true);
      setSearchServCenter("");
    };
    const [isLoading, setIsLoading] = useState(false);
    //------------------------------SERVICE CENTER --------------------------------------------------------//
    const debouncedServiceCenterSearch = useCallback(
      debounce(async (searchText) => {
        try {
          setIsLoading(true);
          const serviceCenterData = await serviceCenter.ServiceCenterData();
          if (Array.isArray(serviceCenterData)) {
            const filteredData = serviceCenterData.filter((item) =>
              item.name.toLowerCase().includes(searchText.toLowerCase())
            );
            setServiceCenterData(filteredData);
          }
        } catch (error) {
          setError("Error fetching service center data.");
        } finally {
          setIsLoading(false);
        }
      }, 500),
      []
    );

    useEffect(() => {
      const fetchServiceCenters = async () => {
        try {
          setIsLoading(true);
          const serviceCenterData = await serviceCenter.ServiceCenterData();
          if (Array.isArray(serviceCenterData)) {
            setServiceCenterData(serviceCenterData);
          }
        } catch (error) {
          setError("Error fetching service center data.");
        } finally {
          setIsLoading(false);
        }
      };
      fetchServiceCenters();
    }, []);
    const handleSearchInputServiceCenter = (text, setFieldValue) => {
      setFieldValue("serviceCenter", text);
      setSearchServCenter(text);
      debouncedServiceCenterSearch(text, token);
      setIsServCenterFlatListVisible(true);
    };
    useEffect(() => {
      if (searchTextServCenter.length >= 5) {
        debouncedServiceCenterSearch(searchTextServCenter);
      }
    }, [searchTextServCenter, debouncedServiceCenterSearch, token]);
    const checkEqualServiceCenter = (text, setFieldValue) => {
      const value = allServCenterData.some(
        (value) => value.name.toUpperCase() === text.toUpperCase()
      );
      if (value) {
        setFieldValue("servicecenterName", "");
        setFieldValue("serviceCenter", text);
        setSearchServCenter(text);
      }
    };
    return (
      <div className={isBatchRow ? "row align-details" : ""}>
        <div className="col-12 col-lg text-font">
          <label htmlFor="serviceCenter">
            Service Center<span className="error-text">*</span>
          </label>
          <div className="position-relative">
            <Field
              type="text"
              placeholder="Type Here to Search"
              name="serviceCenter"
              value={serviceCenterDefault}
              onChange={(e) =>
                handleSearchInputServiceCenter(e.target.value, setFieldValue)
              }            
              className="form-control"
              id="serviceCenter"
              onClick={(e) => { e.stopPropagation();setIsServCenterFlatListVisible(true)}}
            />
            <i className="fas fa-search searchIcon"></i>
            {searchTextServCenter.length >= 2 &&
              serviceCenterData.length === 0 && (
                <FontAwesomeIcon
                  icon={faPlus}
                  className="plus-icon"
                  onClick={() => handlePlusIconClickServiceCenter()}
                />
              )}

            {isServCenterFlatListVisible && serviceCenterData.length > 0 && (
              <div className="dropdown-list">
                {isLoading && <div>Loading...</div>}
                <ul>
                  {serviceCenterData.map((item) => (
                    <li
                      key={item.id}
                      onClick={() =>
                        handleSelectServCenter(item, setFieldValue)
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <ErrorMessage
            name="serviceCenter"
            component="div"
            className="error-text d-flex"
          />
        </div>

        {isServCAdIconClicked && (
          <>
            <div className="mb-3 text-font col-12 col-lg">
              <label htmlFor="servCenterName">Service Center Name *</label>
              <Field
                type="text"
                name="servCenterName"
                value={serviceCenterName}
                onChange={(e) => {
                  const text = e.target.value;
                  setFieldValue("servCenterName", text);
                  checkEqualServiceCenter(text, setFieldValue);
                }}
                className="form-control"
                id="servCenterName"
              />

              <ErrorMessage
                name="servCenterName"
                component="div"
                className="error-text d-flex"
              />
            </div>

            <div className="mb-3 text-font col-12 col-lg">
              <label htmlFor="servCenterType">Service Center Type:</label>
              <select
                name="servCenterType"
                value={serviceCenterType}
                className="form-control"
                id="servCenterType"
                onChange={(e) => {
                  setFieldValue("servCenterType", e.target.value);
                  handleSelectItem(e.target.value);
                }}
              >
                <option value="Non-Facility">Non-Facility</option>
                <option value="Facility">Facility</option>
                <option value="Day Clinic">Day Clinic</option>
              </select>
              <ErrorMessage
                name="servCenterType"
                component="div"
                className="error-text d-flex"
              />
            </div>
          </>
        )}
        {hasAuth && <AuthorizationNumber />}

        {/* for correct col padding */}
        <div className="mb-3 col"></div>
      </div>
    );
  }
);

export default ServiceCenter;
