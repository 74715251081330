import React from 'react';
import { useAuth } from '../../services/authentication/LoginService';

const BoxItem = ({ heading, image, footer, bgColor, stars, clickFunc = () => {}, queries }) => {
  const { subTaskCount } = useAuth();
  
  return (
    <div className={`${bgColor} h-100 rounded p-4 d-flex flex-column justify-content-between pointer`} onClick={clickFunc}>
      
      {/* Header Section */}
      <div className='header text-white mb-2'>
        {heading}
      </div>
      
      {/* Body Section */}
      <div className=' d-flex boxContainter justify-content-center align-items-center'>
        {stars ? (
          <div className='practiceScore'>
            {[...Array(5)].map((_, index) => (
              <img key={index} alt='' width={30} src={image} />
            ))}
          </div>
        ) : (
          <>
            {queries ? (
              <div className='position-relative d-flex justify-content-center align-items-center'>
                <img alt='' width={80} src={image} />
                <span 
                  style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} 
                  className='position-absolute text-white'>
                  {subTaskCount}
                </span>
              </div>
            ) : (
              <img alt='' width={80} src={image} />
            )}
          </>
        )}
      </div>
      
      {/* Footer Section */}
      <div className='footer text-white mt-2'>
        <span>
        {footer}
        </span>

      </div>
      
    </div>
  );
}

export default BoxItem;
