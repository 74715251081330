import React, { useState } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ContactUsService from '../../services/Queries/ContactUsService';
import { useAuth } from '../../services/authentication/LoginService';
import { Formik } from 'formik';
import * as Yup from "yup";
import Sidebar from '../../screens/Sidebar/Sidebar';

import NavigateBack from '../NavigateBack.js';

const ContactUs = () => {
   
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [subjectError, setSubjectError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [fileError, setFileError] = useState('');
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
  
    const contactUsService = ContactUsService(token);
    const navigate = useNavigate();
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };
  
    const validateForm = () => {
      let isValid = true;
  
      if (!subject.trim()) {
        setSubjectError('Subject is required');
        isValid = false;
      } else {
        setSubjectError('');
      }
  
      if (!message.trim()) {
        setMessageError('Message is required');
        isValid = false;
      } else {
        setMessageError('');
      }
  
      return isValid;
    };
  
    const sendEmail = async () => {
      if (!validateForm()) {
        return;
      }
  
      setLoading(true);
  
      try {
        const emailBody = `\nSubject: ${subject}\n\nMessage: ${message}`;
        let attachments = [];
        if (selectedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = async () => {
            attachments = [{
              attachmentData: reader.result.split(',')[1],
              contentType: selectedFile.type || 'application/octet-stream',
              fileName: selectedFile.name || 'Untitled',
            }];
  
            const emailData = { Email: { Body: emailBody }, Attachments: attachments };
            const response = await contactUsService.handleContactUsDetails(emailData, 1);
            setLoading(false);
            navigate('/contact-us-success');
          };
        } else {
          const emailData = { Email: { Body: emailBody }, Attachments: [] };
          const response = await contactUsService.handleContactUsDetails(emailData, 1);
          setLoading(false);
          navigate('/contact-us-success');
        }
      } catch (error) {
        console.error('Error sending email:', error);
        setLoading(false);
        alert('Failed to send email. Please try again later.');
      }
    };

    
      return (
        <div>
          <Sidebar heading={<h1 className="heading">CONTACT US</h1>}>
    
            <Formik
              initialValues={{ fromDate: "", toDate: "" }}
              validationSchema={Yup.object({})}
            >
              {({
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                handleChange,
                setFieldValue
              }) => (
                <div className="container ">
                  <div className="container">
                    <h2 className="my-4"></h2>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Subject<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          isInvalid={!!subjectError}
                        />
                        <Form.Control.Feedback type="invalid">{subjectError}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Message<span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          isInvalid={!!messageError}
                        />
                        <Form.Control.Feedback type="invalid">{messageError}</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Attach a File</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                        {selectedFile && (
                          <div className="mt-2">
                            <Button variant="danger" onClick={() => setSelectedFile(null)}>Remove File</Button>
                          </div>
                        )}
                      </Form.Group>

                      <Button 
                        style={{ backgroundColor: '#292234', borderColor: '#292234' }} 
                        onClick={sendEmail} 
                        disabled={loading}
                      >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Send'}
                      </Button>

                    </Form>
                  </div>
                  
                </div>
              )}
            </Formik>
          </Sidebar>
        </div>
      );
    }
    
    

export default ContactUs;
