import React from 'react'
import "../../styles/components/Sidebar.scss"
import { useNavigate } from 'react-router-dom'
function SidebarButtons({description,img,active,expanded}) {
  return (
    <div
    data-toggle="tooltip" data-placement="right" title={description}
    className={
      expanded ? (active ? 'active-sidebar mt-2' : 'sidebar-btn') 
               : (active ? 'active-sidebar mt-2' : 'smallpadding')
  }
  >

      
        <img alt='' className={expanded ? 'sidebar-img' : 'sidebar-img-expanded'} src={img} width={50} height={50} />
        {expanded && (
       <span className={expanded && 'bold'}>{description}</span>
        ) }

    </div>
  )
}

export default SidebarButtons
