import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faClose } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, useFormikContext } from "formik";
import ReferringDocService from "../../services/Billing/ReferringDocService";
import { useAuth } from "../../services/authentication/LoginService";
import debounce from "lodash/debounce";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import useReferringDoctorStore from "../state/ReferringDoctorStore";
import specialties from "../../assets/Codes/specialties.json";
import CustomScroll from "../common/CustomScroll";

const ReferringDoctor = () => {
  const { token } = useAuth();
  const { values, setFieldValue } = useFormikContext();
  const referringDocService = ReferringDocService(token);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredSpecialties, setfilteredSpecialties] = useState(
    specialties.specialty
  );
  const [isDisciplineTypeFlatlist, setisDisciplineTypeFlatlist] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const { setSurnameAndName, setSpecialityCode, setPracticeNumber,surnameAndName,specialityCode,practiceNumber,isRefDocFlatListVisible, setIsRefDocFlatListVisible } =
    useReferringDoctorStore();
  const [allRefDocData, setAllRefDocData] = useState([]);
  const { selectedRefDoc, setSelectedRefDoc } = useProcedureCodesStore();
  const [refDocData, setRefDocData] = useState([]);
  const [searchTextRefDoc, setSearchTextRefDoc] = useState("");
  const { isRefDocAdIconClicked, setIsRefDocAdIconClicked } =
    useReferringDoctorStore();
  const handlePlusIconClick = (binary) => {
    setIsRefDocAdIconClicked(binary);
  };
  useEffect(() => {
    const filteredData = specialties.specialty
      .filter((item) => {
        const lowerCasedSearchTerm = specialityCode.toLowerCase();
        return item.toLowerCase().includes(lowerCasedSearchTerm);
      })
      .slice(0, 20);

    setfilteredSpecialties(filteredData);
  }, [specialityCode]);

  useEffect(() => {
    const selected = filteredSpecialties.some(
      (option) => option.toUpperCase() === specialityCode.toUpperCase()
    );

    if (selected) {
      setisDisciplineTypeFlatlist(false);
    }
  }, [specialityCode]);

  const handleDisciplineType = (text, setFieldValue) => {
    setSpecialityCode(text);
    if (text === "") {
      setFieldValue("specCode", "");
      setSpecialityCode("");
      setisDisciplineTypeFlatlist(false);
    } else {
      setisDisciplineTypeFlatlist(true);
      debouncedDiscipline(text);
      setFieldValue("specCode", text);
    }
  };
  const debouncedDiscipline = useCallback(
    debounce((text) => {
      setSpecialityCode(text);
    }, 250),
    []
  );

  const checkEqualRef = (text, setFieldValue) => {
    const value = allRefDocData.some(
      (value) => value.name.toUpperCase() === text.toUpperCase()
    );
    if (value) {
      handlePlusIconClick(false);
      setFieldValue("nameAndsurname", "");
      setFieldValue("referringDoctor", text);
      setSearchTextRefDoc(text);
      setFieldValue("practiceNumber", "");
      setFieldValue("specCode", "");
    }
  };
  const handleSelectRefDoc = (item, setFieldValue) => {
    if (selectedRefDoc && selectedRefDoc.id === item.id) {
      setSelectedRefDoc(null);
    } else {
      setSelectedRefDoc(item);
      setSearchTextRefDoc(item.name);
      setFieldValue("referringDoctor", item.name);
      setIsRefDocFlatListVisible(false);
      setIsRefDocAdIconClicked(false);
      setSurnameAndName(item.surnameAndName || "");
      setPracticeNumber(item.practiceNumber || "");
      setSpecialityCode(item.specialityCode || "");
    }
  };

  useEffect(() => {
    const fetchRefData = async () => {
      try {
        setIsLoading(true);
        const referringDocData = await referringDocService.ReferringDoc();
        if (Array.isArray(referringDocData)) {
          setAllRefDocData(referringDocData);

          const filteredData = referringDocData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );
          setRefDocData(filteredData);
        }
      } catch (error) {
        setError("Error fetching referring doctor data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchRefData();
  }, []);
  const debouncedSearch = useCallback(
    debounce(async (searchText, token) => {
      try {
        setIsLoading(true);
        const referringDocData = await referringDocService.ReferringDoc();
        if (Array.isArray(referringDocData)) {
          setAllRefDocData(referringDocData);

          const filteredData = referringDocData.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
          );
          setRefDocData(filteredData);
        }
      } catch (error) {
        setError("Error fetching referring doctor data.");
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );
  const handleSearchInputRefDoc = (text, setFieldValue) => {
    setFieldValue("referringDoctor", text);
    setSearchTextRefDoc(text);
    debouncedSearch(text);
    setIsRefDocFlatListVisible(true);
  };
  useEffect(() => {
    if (searchText.length >= 2) {
      debouncedSearch(searchText);
      setIsRefDocFlatListVisible(true);
    } else {
      setRefDocData([]);
      setIsRefDocFlatListVisible(false);
    }
  }, [searchText, debouncedSearch]);
  return (
    <div className="col-12 col-lg text-font">
      <label>Referring Doctor</label>
      <div style={{ position: "relative" }}>
        <input
         placeholder="Type Here to Search"
         onClick={(e) => {
          e.stopPropagation();  // Stop the click event from propagating
          setIsRefDocFlatListVisible(true) // Update the state to make the service center flat list visible
        }}
          type="text"
          name="referringDoctor"
          value={values.referringDoctor}

          onChange={(e) =>
            handleSearchInputRefDoc(e.target.value, setFieldValue)
          }
          className="form-control"
          id="referringDoctor"
        />
         <i className="fas fa-search searchIcon"></i>
        {searchTextRefDoc.length >= 2 && refDocData.length === 0 && (
          <FontAwesomeIcon
            icon={faPlus}
            className="plus-icon"
            onClick={() => {
              handlePlusIconClick(true);
              setSearchTextRefDoc("");
            }}
          />
        )}

        {isRefDocFlatListVisible && refDocData.length > 0 && (
          <div className="dropdown-list">
            {isLoading && <div>Loading...</div>}
            <ul>
              {refDocData.map((item) => (
                <li
                  key={item.id}
                  onClick={() => handleSelectRefDoc(item, setFieldValue)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {isRefDocAdIconClicked && (
        <>
          <div className="mb-3">
            <label htmlFor="nameAndsurname">Name and Surname *</label>
            <input
              type="text"
              name="nameAndsurname"
              value={surnameAndName}
              onChange={(e) => {
                const text = e.target.value;
                setFieldValue("nameAndsurname", text);
                setSurnameAndName(text);
                checkEqualRef(text, setFieldValue);
              }}
              className="form-control"
              id="nameAndsurname"
            />
            <ErrorMessage
              name="nameAndsurname"
              component="div"
              className="error-text d-flex"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pracNumber">
              Practice Number / Council Number *
            </label>
            <input
              type="text"
              name="pracNumber"
              value={practiceNumber}
              onChange={(e) => {
                const text = e.target.value;
                setFieldValue("pracNumber", text);
                setPracticeNumber(text)
                checkEqualRef(text, setFieldValue);
              }}
              className="form-control"
              id="pracNumber"
            />
            <ErrorMessage
              name="pracNumber"
              component="div"
              className="error-text d-flex"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="specCode">
              Speciality Code / Medical Discipline / Activity *
            </label>
            <input
              type="text"
              name="specCode"
              value={specialityCode}
              onChange={(e) => {
                const text = e.target.value;
                handleDisciplineType(text, setFieldValue);
              }}
              className="form-control"
              id="specCode"
            />
            <ErrorMessage
              name="specCode"
              component="div"
              className="error-text d-flex"
            />
            <CustomScroll maxHeight="30vh" smallerScroll={true}>
              {isDisciplineTypeFlatlist && (
                <ul className="list-group">
                  {filteredSpecialties.map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item pointer"
                      onClick={() => {
                        setisDisciplineTypeFlatlist(false);
                        setSpecialityCode(item);
                        setSpecialityCode(item);
                        setFieldValue("specCode", item);
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </CustomScroll>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferringDoctor;
