import React from 'react';

function GenericButton({
    styling='formButton col-12 col-md-4', //Custom Styling for Button
    bgColor, //Just change the background Color
    src,
    text ='Generic Button ',
    func =()=>{},
    img,
    imgStyle,
    imgClass,
    textStyles='text-white',
    disabled = false

})
{
    return (
        <div onClick={ disabled ? ()=> {} : func} className={`${styling} ${bgColor ? bgColor : "profileColor"} pointer`}>
            {src ? (
                <i className={src}></i>
            ) : img ? (
                <img style={imgStyle} className={imgClass} src={img} alt={text} />
            ) : null}
            <span className={textStyles}>{text}</span>
        </div>

    );
}


export default GenericButton;
