import React from 'react';
const InvoiceHistoryViewTable = ({
    historyviewData,
    invoiceDetails,
    reference,
    mergedData
    
}) => {
    return (
        <div>
            <div ref={reference} className='border rounded border-dark row p-4 mt-4'>
                <div className='col-12 col-md-4 d-flex flex-column justify-content-start'>
                    <span className='text-start mt-1'>Case ID: <span className='bold'>{historyviewData?.CaseID}</span></span>
                    <span className='text-start mt-1'>Reference Number: <span className='bold'>{historyviewData?.InvoiceReference}</span></span>
                    <span className='text-start mt-1'>Treating Doctor: <span className='bold'>{mergedData?.invoiceDoctors?.DoctorName}</span></span>
                    <span className='text-start mt-1'>Service Center: <span className='bold'>{mergedData?.invoiceCenters?.ServiceCenterName}</span></span>
                </div>
                <div className='col-12 col-md-4 d-flex flex-column justify-content-start'>
                    <span className='text-start mt-1'>Patient Name & Surname: <span className='bold'>{historyviewData?.PatientName}</span></span>
                    <span className='text-start mt-1'>Service Date: <span className='bold'>{historyviewData?.InvoiceDate}</span></span>
                    <span className='text-start mt-1'>Submission Date: <span className='bold'>{mergedData?.invoiceHeader?.CreateDate}</span></span>
                </div>
                <div className='col-12 col-md-4 d-flex justify-content-center align-items-center'>
                    <img src={require("../../assets/xpedientlogo.png")} style={{ objectFit: "contain" }} width={200} alt="Logo" />
                </div>
                <div className='col-12'>
                    <div className='table-responsive'>
                        <table className='table w-100'>
                            <thead className='thead-light'>
                                <tr>
                                    <th className='p-3'>Item</th>
                                    <th className='p-3'>ICD-10 Code</th>
                                    <th className='p-3'>Procedure Code</th>
                                    <th className='p-3'>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceDetails.map((invoiceDetail, index) => (
                                    <tr className='light-gray mt-4' key={index}>
                                        <td className='p-3'>{invoiceDetail.LineNo}.</td>
                                        <td className='p-3'>{invoiceDetail.Diagnosis.join(', ')}</td>
                                        <td className='p-3'>{invoiceDetail.Code}</td>
                                        <td className='p-3'>{invoiceDetail.Quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceHistoryViewTable;
