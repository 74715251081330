import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form} from 'formik';
import '../../styles/components/login.scss';
import '../../styles/variables/_mixins.scss';
import LoginHeader from '../common/LoginHeader';
import MainFooter from '../common/MainFooter';

const PasswordConfirmationPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLoginRedirect = () => {
    navigate('/');
  };
  return (
    <div className="login-page-wrapper page-bg-img">
      <div className='row'>
        <div className='col-md-6 col-12'>      <div className="container shadow bg-white rounded px-4 authContainer">
        <div className="row">
          <div className="col-12">
            <LoginHeader />
          </div>
          <div className="col-12">
            <div className="mt-4">
              <Formik
              >
                {({ isSubmitting}) => (
                  <Form>
                    <div className="col-12">
                    <div className='d-flex flex-column align-items-center mt-2'>
                        <img width={80} height={80} src={require("../../assets/success-green-check-mark-icon.png")} alt="Success check mark" />
                        <span className="passwordText mt-2">
                            Success
                        </span>
                    </div>

                    </div>
                    <div className="col-12">
                      <button
                        type="button"
                        className="w-100 formButton"
                        disabled={isSubmitting || loading}
                        onClick={handleLoginRedirect}
                      >
                        <div className="text-white d-flex align-items-center justify-content-center">
                          <span>Login</span>
                        </div>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <MainFooter />
        </div>
      </div>
      </div>
        <div className='col-md-6 col-0'></div>
      </div>

    </div>
  );
};

export default PasswordConfirmationPage;
